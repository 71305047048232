import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ScreenSizeService } from 'libs/ng-ui/src/lib/_services/screen-size.service';
import { CtxRoutes } from '../../_models/base-routes';
import { RouterEventHooksService } from '../../_services/router-event-hooks.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CtxAppBarComponent } from '../app-bar/app-bar.component';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { GUID_REGEX } from 'utils';
import { SecondaryNavRailLinksComponent } from '../secondary-nav-rail-links/secondary-nav-rail-links.component';
import { BrnAccordionContentComponent } from '@spartan-ng/ui-accordion-brain';
import {
  HlmAccordionContentDirective,
  HlmAccordionDirective,
  HlmAccordionIconDirective,
  HlmAccordionItemDirective,
  HlmAccordionTriggerDirective,
} from '../accordion';
import { HlmIconComponent } from '../icon';
import { AuthnService } from '../../_services/authn.service';
import { CtxAppBarActionsComponent } from '../app-bar-actions/app-bar-actions.component';

/**
 * WARNING: This is not to be used in Route.component property.
 * This component is only a way to re-use the navigation bars
 * across the dashboard.
 *
 * Does not contain a router-outler.
 *
 * Currently, it is reused by CtxPageLayout and CtxErrorPage.
 *
 */
@Component({
  selector: 'ctx-layout',
  templateUrl: './layout.component.html',
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinner,
    CtxAppBarComponent,
    CtxAppBarActionsComponent,
    MatSidenavModule,
    SecondaryNavRailLinksComponent,
    BrnAccordionContentComponent,
    HlmAccordionContentDirective,
    HlmAccordionDirective,
    HlmAccordionIconDirective,
    HlmAccordionItemDirective,
    HlmAccordionTriggerDirective,
    HlmIconComponent,
  ],
  standalone: true,
})
export class CtxLayoutComponent {
  @Input() routes: CtxRoutes<any, any>;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  @Input() trialExpiredComponent: any;
  constructor(
    public breakpoints: ScreenSizeService,
    public routerEvents: RouterEventHooksService,
    private router: Router,
    public authn: AuthnService
  ) {}

  isCurrentRoute(item: any) {
    return item.path === this.currentUrl[1];
  }

  get currentUrl() {
    return this.router.url.split(/[/]/);
  }

  /**
   * The opened property works in conjunction with MatSideNav's `opened` property.
   * The property is two-way binded to be used across components.
   */
  get opened() {
    // this.setNavigationLinks()
    // console.log(this.linksForCurrentRoute.length, this.currentUrl )
    return !GUID_REGEX.test(this.currentUrl[this.currentUrl.length - 1]);
  }
}
