import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxDynamicForm } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';

import {
  CtxValidators,
  ResourceCreateFormData,
  ResourceUpdateDynamicFormData,
} from 'utils';
import { ProductService } from 'admin-portal/pages/products/product.service';
import { DataCacheService } from 'ng-ui';
import { CtxButtonComponent } from 'ng-ui';
import { CtxMetadataFormComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent as CtxStepperComponent_1 } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-product-form',
  templateUrl: './product-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    CtxStepperComponent_1,
    CtxStepComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    CtxMetadataFormComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class ProductFormComponent extends CtxDynamicForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateDynamicFormData,
    public dialogRef: MatDialogRef<ProductFormComponent>,
    fb: FormBuilder,
    public productService: ProductService,
    private dataCacheService: DataCacheService
  ) {
    super(fb);
  }

  @ViewChild(CtxStepperComponent) ctxTab: CtxStepperComponent;

  /**
   * automatedEmails are cached in DataCacheService using a resolver.
   */
  automatedEmails = this.dataCacheService.getCachedValues('automated-email');

  /**
   * licenseTemplates are cached in DataCacheService using a resolver.
   */
  licenseTemplates = this.dataCacheService.getCachedValues('license-template');

  /**
   * trialPolicies are cached in DataCacheService using a resolver.
   */
  trialPolicies = this.dataCacheService.getCachedValues('trial-policy');

  /**
   * Controls in the Details tab.
   */
  detailsTab: FormControl;

  /**
   * FormArray that handles validation on the Metadata tab.
   */
  metadataTab: FormArray;

  METADATA_HINT_TEXT = `Product metadata allows you to have miscellaneous data associated to your product. Use the 'Add Metadata' button to add a metadata field.`;

  ngOnInit() {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      displayName: [
        this.getInitialControlValue('displayName', this.dialogData),
        Validators.maxLength(256),
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData),
        Validators.maxLength(256),
      ],
      licensePolicyId: [
        this.getInitialControlValue('licensePolicy.id', this.dialogData),
        Validators.required,
      ],
      trialPolicyId: [
        this.getInitialControlValue('trialPolicy.id', this.dialogData),
      ],
      automatedEmails: [
        this.getInitialControlValue('automatedEmails', this.dialogData),
      ],
      metadata: this.fb.array(
        this.getInitialFormArrayValue('metadata', this.dialogData),
        [CtxValidators.uniqueKeys('key')]
      ),
    });

    /**
     * Create a separate group to validate tabs.
     */
    this.detailsTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('displayName'),
      this.formGroup.get('description'),
      this.formGroup.get('licensePolicyId'),
      this.formGroup.get('trialPolicyId'),
      this.formGroup.get('automatedEmails'),
    ]);

    /**
     * Assign `metadata` control from the formGroup to metadataFields property
     */
    this.metadataTab = this.formGroup.get('metadata') as FormArray;
  }
}
