import { ProviderToken, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ResourceService } from '../_services/resource.service';
import {
  CachedResourceName,
  DataCacheService,
} from '../_services/data-cache.service';
import { AuthnService } from '../_services/authn.service';

/**
 * Factory function for ResolveFn for entities in CachedResourceName
 * @param _route ResolveFn param
 * @param _state ResolveFn param
 * @param provider ProviderToken for service
 * @param resourceName CachedResourceName
 * @returns Resource array and caches the same in DataCacheService
 */
export const resolveEntitiesToCache = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  provider: ProviderToken<ResourceService>,
  resourceName: CachedResourceName
): Promise<any> => {
  const dataCacheService = inject(DataCacheService);
  const authn = inject(AuthnService);
  if (dataCacheService.getCachedValues(resourceName)) {
    return Promise.resolve(dataCacheService.getCachedValues(resourceName));
  } else {
    const _resourceService = inject(provider);
    return _resourceService
      .list(1, 100, {})
      .then((response: { body: any }) => {
        dataCacheService.setCache(resourceName, response.body);
        return response.body;
      })
      .catch((error: any) => {
        if (error?.status === 401) {
          authn.logout();
          return;
        }
        // TODO

        // eslint-disable-next-line no-console
        console.error(`Error while resolving ${resourceName}(s)`, error);
      });
  }
};

/**
 * Returns a single resource from the Cryptlex Web API using an ResourceTableService.
 * Uses the :id Param from the ActivatedRoute to get the id of the resource.
 *
 * If ID does not exist, it will send a GET request for an empty string.
 */
export const resolveResource = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  provider: ProviderToken<ResourceService>
) => {
  /** Inject Router, GlobalNotification for error cases. */
  /** Inject ResourceService */
  const _resourceService = inject(provider);
  const id = _route.paramMap.get('id');
  return _resourceService
    .read(id ? id : '')
    .then((response: { body: any }) => {
      _resourceService.errorMessage = null;
      return response.body;
    })
    .catch(async (error: any) => {
      if (error.error) {
        _resourceService.errorMessage = error.error?.message;
      }
      // eslint-disable-next-line no-console
      console.error(error);
    });
};
