import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  CtxStepperComponent,
  CustomValidityInputComponent,
  DataCacheService,
  ProfileService,
  UNLIMITED_VALUE_MINUS_ONE,
  UNLIMITED_VALUE_ZERO_AND_MINUS_ONE,
  ValidityInputType,
  computeValidityControlHint,
} from 'ng-ui';
import {
  ALL_COUNTRIES,
  CtxValidators,
  nextDayDate,
  originalOrder,
} from 'utils';
import { CtxForm } from 'ng-ui';

import { daysToSeconds, secondsToDays } from 'utils';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { KeyValuePipe } from '@angular/common';
import { CtxButtonComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent as CtxStepperComponent_1 } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'ctx-license-template-form',
  templateUrl: './license-template-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    CtxStepperComponent_1,
    CtxStepComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    KeyValuePipe,
    CustomValidityInputComponent,
    MatRadioModule,
  ],
})
export class LicenseTemplateFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<LicenseTemplateFormComponent>,
    private fb: FormBuilder,
    public dataCacheService: DataCacheService,
    private profileService: ProfileService
  ) {
    super();
  }

  ALL_COUNTRIES = ALL_COUNTRIES;

  LICENSE_TEMPLATE_EDIT_MESSAGE =
    'Any updates to the license template will not affect licenses created using the template.';
  UNLIMITED_VALUE_MINUS_ONE = UNLIMITED_VALUE_MINUS_ONE;
  UNLIMITED_VALUE_ZERO_AND_MINUS_ONE = UNLIMITED_VALUE_ZERO_AND_MINUS_ONE;
  /** Controls for tab validation */
  detailsTab: FormControl;
  advancedTab: FormControl;

  @ViewChild(CtxStepperComponent) ctxTab: CtxStepperComponent;
  originalOrder = originalOrder;
  computeValidityControlHint = computeValidityControlHint;
  validityMethods: ValidityInputType = 'lifetime';

  tommorow: Date;
  ngOnInit() {
    this.tommorow = nextDayDate(new Date().toISOString());

    this.formGroup = this.fb.group(
      {
        name: [
          this.getInitialControlValue('name', this.dialogData),
          [Validators.required],
        ],
        validity: [
          secondsToDays(
            this.getInitialControlValue('validity', this.dialogData)
          ),
          [Validators.required, CtxValidators.maxDays, Validators.min(0)],
        ],
        type: [
          this.getInitialControlValue('type', this.dialogData) || 'node-locked',
          [Validators.required],
        ],
        expirationStrategy:
          this.getInitialControlValue('expirationStrategy', this.dialogData) ||
          'immediate',
        requiredMetadataKeys:
          this.getInitialArrayControlValue(
            'requiredMetadataKeys',
            this.dialogData
          ) || [],
        requiredMeterAttributes:
          this.getInitialArrayControlValue(
            'requiredMeterAttributes',
            this.dialogData
          ) || [],
        fingerprintMatchingStrategy:
          this.getInitialControlValue(
            'fingerprintMatchingStrategy',
            this.dialogData
          ) || 'fuzzy',
        leasingStrategy:
          this.getInitialControlValue('leasingStrategy', this.dialogData) ||
          'per-machine',
        allowedActivations: [
          this.getInitialControlValue('allowedActivations', this.dialogData),
          [
            Validators.required,
            Validators.min(-1),
            CtxValidators.int32,
            CtxValidators.onlyIntegers,
          ],
        ],
        allowedFloatingClients: [
          this.getInitialControlValue(
            'allowedFloatingClients',
            this.dialogData
          ),
          [Validators.min(0), CtxValidators.int32, CtxValidators.onlyIntegers],
        ],
        leaseDuration: [
          this.getInitialControlValue('leaseDuration', this.dialogData) || 3630,
        ],
        allowedDeactivations: new FormControl(
          {
            value: this.getInitialControlValue(
              'allowedDeactivations',
              this.dialogData
            ),
            disabled:
              this.getInitialControlValue('type', this.dialogData) ===
              'hosted-floating',
          },
          [
            Validators.required,
            Validators.min(-1),
            CtxValidators.int32,
            CtxValidators.onlyIntegers,
          ]
        ),
        // SSGP must always be greater than SSI
        serverSyncGracePeriod: [
          secondsToDays(
            this.getInitialControlValue(
              'serverSyncGracePeriod',
              this.dialogData
            ) === null
              ? 30 * 86400
              : this.getInitialControlValue(
                  'serverSyncGracePeriod',
                  this.dialogData
                )
          ),
          [Validators.required, Validators.min(-1)],
        ],
        serverSyncInterval: [
          this.getInitialControlValue('serverSyncInterval', this.dialogData) ||
            3600,
          [
            Validators.required,
            Validators.min(180),
            CtxValidators.int32,
            CtxValidators.serverSyncInterval(this.profileService.currentPlan),
            CtxValidators.onlyIntegers,
          ],
        ],
        allowedClockOffset: [
          this.getInitialControlValue('allowedClockOffset', this.dialogData) ||
            60,
          [
            Validators.required,
            Validators.min(-1),
            CtxValidators.int32,
            CtxValidators.onlyIntegers,
          ],
        ],
        expiringSoonEventOffset: [
          secondsToDays(
            this.getInitialControlValue(
              'expiringSoonEventOffset',
              this.dialogData
            ) || 3 * 86400
          ),
          [Validators.min(1)],
        ],
        allowVmActivation: [
          this.getInitialControlValue(
            'allowVmActivation',
            this.dialogData,
            true
          ),
          Validators.required,
        ],
        allowContainerActivation: [
          this.getInitialControlValue(
            'allowContainerActivation',
            this.dialogData,
            true
          ),
          Validators.required,
        ],
        allowClientLeaseDuration: [
          this.getInitialControlValue(
            'allowClientLeaseDuration',
            this.dialogData,
            false
          ),
          Validators.required,
        ],
        allowServerLeaseDuration: [
          this.getInitialControlValue(
            'allowServerLeaseDuration',
            this.dialogData,
            false
          ),
          Validators.required,
        ],
        requireAuthentication: [
          this.getInitialControlValue(
            'requireAuthentication',
            this.dialogData,
            false
          ),
          Validators.required,
        ],
        disableGeoLocation: [
          this.getInitialControlValue(
            'disableGeoLocation',
            this.dialogData,
            false
          ),
          Validators.required,
        ],
        userLocked: [
          this.getInitialControlValue('userLocked', this.dialogData, false),
          Validators.required,
        ],
        keyPattern: [
          this.getInitialControlValue('keyPattern', this.dialogData) ||
            '^([A-F0-9]{6}-){5}[A-F0-9]{6}$',
          Validators.required,
        ],
        allowedCountries: new FormControl(
          {
            value: this.getInitialControlValue(
              'allowedCountries',
              this.dialogData
            ),
            disabled: this.getInitialControlValue(
              'disableGeoLocation',
              this.dialogData
            ),
          },
          Validators.maxLength(512)
        ),
        disallowedCountries: new FormControl(
          {
            value: this.getInitialControlValue(
              'disallowedCountries',
              this.dialogData
            ),
            disabled: this.getInitialControlValue(
              'disableGeoLocation',
              this.dialogData
            ),
          },
          Validators.maxLength(512)
        ),
        allowedIpRanges: [
          this.getInitialControlValue('allowedIpRanges', this.dialogData),
          Validators.maxLength(512),
        ],
        allowedIpAddresses: [
          this.getInitialControlValue('allowedIpAddresses', this.dialogData),
          Validators.maxLength(512),
        ],
        disallowedIpAddresses: [
          this.getInitialControlValue('disallowedIpAddresses', this.dialogData),
          Validators.maxLength(512),
        ],
      },
      { validators: [CtxValidators.license] }
    );

    /**
     * Create a separate group to validate details tab.
     */
    this.detailsTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('type'),
      this.formGroup.get('allowedFloatingClients'),
      this.formGroup.get('leasingStrategy'),
      this.formGroup.get('leaseDuration'),
      this.formGroup.get('allowClientLeaseDuration'),
      this.formGroup.get('allowServerLeaseDuration'),
      this.formGroup.get('validity'),
      this.formGroup.get('allowedActivations'),
      this.formGroup.get('allowedDeactivations'),
      this.formGroup.get('requiredMeterAttributes'),
      this.formGroup.get('requiredMetadataKeys'),
    ]);
    /** */
    this.advancedTab = this.fb.control([
      this.formGroup.get('fingerprintMatchingStrategy'),
      this.formGroup.get('expirationStrategy'),
      this.formGroup.get('expiringSoonEventOffset'),
      this.formGroup.get('keyPattern'),
      this.formGroup.get('serverSyncInterval'),
      this.formGroup.get('serverSyncGracePeriod'),
      this.formGroup.get('disableGeoLocation'),
      this.formGroup.get('allowVmActivation'),
      this.formGroup.get('allowContainerActivation'),
      this.formGroup.get('allowedClockOffset'),
      this.formGroup.get('userLocked'),
    ]);

    // In hosted floating licenses, server sync interval must be less than lease duration for auto-renwal to work.
    this.formGroup.get('leaseDuration')?.valueChanges.subscribe((value) => {
      if (this.selectedLicenseType === 'hosted-floating' && value >= 200) {
        this.formGroup.get('serverSyncInterval')?.setValue(value - 20);
      }
    });

    // In on-premise floating licenses, VM and Container activations should not be allowed.
    this.formGroup.get('type')?.valueChanges.subscribe(() => {
      if (this.selectedLicenseType === 'on-premise-floating') {
        this.formGroup.get('allowVmActivation')?.setValue(false);
        this.formGroup.get('allowContainerActivation')?.setValue(false);
      }
      if (this.selectedLicenseType === 'hosted-floating') {
        this.formGroup.get('allowedDeactivations')?.setValue(-1);
      } else {
        this.formGroup.get('allowedDeactivations')?.setValue(1);
      }
    });

    if (this.formGroup.get('validity')?.value > 0) {
      this.validityMethods = 'days';
    }
  }

  /**
   * Since updation disables the `type` field, the getRawValue() method is used to get it's value.
   */
  get selectedLicenseType() {
    return this.formGroup.getRawValue().type;
  }
  /**
   * Hint for allowedClockOffset
   */
  get hintAllowedClockOffset() {
    if (this.selectedLicenseType === 'node-locked') {
      return UNLIMITED_VALUE_MINUS_ONE + ' (Only when validity is set to 0)';
    }
    return null;
  }
  get formValue() {
    const value = Object.assign({}, this.formGroup.value);
    value.allowedIpRanges = this.csvToArray(value.allowedIpRanges);
    value.disallowedIpAddresses = this.csvToArray(value.disallowedIpAddresses);
    value.allowedIpAddresses = this.csvToArray(value.allowedIpAddresses);
    value.requiredMetadataKeys = this.csvToArray(value.requiredMetadataKeys);
    value.requiredMeterAttributes = this.csvToArray(
      value.requiredMeterAttributes
    );

    // Convert values that are displayed in days to seconds for the Web API request
    value.serverSyncGracePeriod = Math.floor(
      daysToSeconds(value.serverSyncGracePeriod)
    );
    value.expiringSoonEventOffset = Math.floor(
      daysToSeconds(value.expiringSoonEventOffset)
    );
    value.validity = Math.floor(daysToSeconds(value.validity));

    return value;
  }

  /** Disables the Allowed Countries and Disallowed Countries fields if Disable Geolocation is enabled.  */
  handleDisableGeolocationChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.formGroup.get('allowedCountries')?.disable();
      this.formGroup.get('disallowedCountries')?.disable();
    } else {
      this.formGroup.get('allowedCountries')?.enable();
      this.formGroup.get('disallowedCountries')?.enable();
    }
  }

  handleValidityMethodChange(event: MatRadioChange) {
    this.validityMethods = event.value;
    if (this.validityMethods === 'lifetime') {
      this.formGroup.patchValue({
        validity: 0,
      });
    } else {
      this.formGroup.patchValue({
        validity: 1,
      });
    }
    this.formGroup.get('validity')?.updateValueAndValidity();
  }
}
