<h2 mat-dialog-title>Save Segment</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, _data, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <section>
        <p>Filters</p>
        <ul>
          @for (filter of dialogData.filters; track filter) {
          <li>
            {{ getLabelForFilter(filter, dataCache) }}
          </li>
          }
        </ul>
      </section>
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
