import { HttpResponse } from '@angular/common/http';
import { Filter } from './filter.types';
import { getDateNDaysAgo } from '../lib/time';

export type Segment = {
  /**
   * Identifier as defined in API
   */
  id: string;
  /**
   * The name set for the segment
   */
  name: string;
  /**
   * List of filters for the segment
   */
  filters: Filter[];

  /**
   * For predefined segments
   */
  immutable?: true;

  /** Resource name */
  resource: SegmentResourceName;

  /** Date from Web API */
  createdAt?: string;
  /** Date from Web API */
  updatedAt?: string;
};

export type SegmentStore = Record<SegmentResourceName, Segment[]>;

export const SEGMENT_RESOURCE_NAMES = [
  'license',
  'trial-activation',
  'activation',
  'user',
  'organization',
  'release',
  'release-file',
  'activation-log',
] as const;
export type SegmentResourceName = (typeof SEGMENT_RESOURCE_NAMES)[number];

export const DEFAULT_LICENSE_SEGMENTS: Segment[] = [
  {
    id: 'license-001',
    resource: 'license',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'license-002',
    resource: 'license',
    filters: [
      { property: 'revoked', operator: 'eq', value: 'false' },
      { property: 'suspended', operator: 'eq', value: 'false' },
      { property: 'expired', operator: 'eq', value: 'false' },
    ],
    name: 'Active',
    immutable: true,
  },
  {
    id: 'license-003',
    resource: 'license',
    filters: [{ property: 'expired', operator: 'eq', value: 'true' }],
    name: 'Expired',
    immutable: true,
  },
  {
    id: 'license-004',
    resource: 'license',
    filters: [{ property: 'expiringSoon', operator: 'eq', value: 'true' }],
    name: 'Expiring Soon',
    immutable: true,
  },
  {
    id: 'license-005',
    resource: 'license',
    filters: [{ property: 'revoked', operator: 'eq', value: 'true' }],
    name: 'Revoked',
    immutable: true,
  },
  {
    id: 'license-006',
    resource: 'license',
    filters: [{ property: 'suspended', operator: 'eq', value: 'true' }],
    name: 'Suspended',
    immutable: true,
  },
];

export const DEFAULT_ACTIVATION_SEGMENTS: Segment[] = [
  {
    id: 'activation-001',
    resource: 'activation',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'activation-002',
    resource: 'activation',
    filters: [
      {
        property: 'lastSyncedAt',
        operator: 'gt',
        value: getDateNDaysAgo(30).toISOString(),
      },
    ],
    name: 'Active',
    immutable: true,
  },
  {
    id: 'activation-003',
    resource: 'activation',
    filters: [
      {
        property: 'createdAt',
        operator: 'gt',
        value: getDateNDaysAgo(1).toISOString(),
      },
    ],
    name: 'New',
    immutable: true,
  },
];

export const DEFAULT_USER_SEGMENTS: Segment[] = [
  {
    id: 'user-001',
    resource: 'user',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'user-002',
    resource: 'user',
    filters: [
      {
        property: 'lastSeenAt',
        operator: 'gt',
        value: getDateNDaysAgo(30).toISOString(),
      },
    ],
    name: 'Active',
    immutable: true,
  },
  {
    id: 'user-003',
    resource: 'user',
    filters: [
      {
        property: 'createdAt',
        operator: 'gt',
        value: getDateNDaysAgo(1).toISOString(),
      },
    ],
    name: 'New',
    immutable: true,
  },
  {
    id: 'user-004',
    resource: 'user',
    filters: [
      {
        property: 'lastSeenAt',
        operator: 'lt',
        value: getDateNDaysAgo(30).toISOString(),
      },
    ],
    name: 'Inactive',
    immutable: true,
  },
];

export const DEFAULT_ORGANIZATION_SEGMENTS: Segment[] = [
  {
    id: 'org-001',
    resource: 'organization',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'org-002',
    resource: 'organization',
    filters: [
      {
        property: 'lastSeenAt',
        operator: 'gt',
        value: getDateNDaysAgo(30).toISOString(),
      },
    ],
    name: 'Active',
    immutable: true,
  },
  {
    id: 'org-003',
    resource: 'organization',
    filters: [
      {
        property: 'createdAt',
        operator: 'gt',
        value: getDateNDaysAgo(1).toISOString(),
      },
    ],
    name: 'New',
    immutable: true,
  },
];

export const DEFAULT_ACTIVATION_LOG_SEGMENTS: Segment[] = [
  {
    id: 'activation-log-001',
    resource: 'activation-log',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'activation-log-002',
    resource: 'activation-log',
    filters: [{ property: 'action', operator: 'eq', value: 'activation' }],
    name: 'Activations',
    immutable: true,
  },
  {
    id: 'activation-log-003',
    resource: 'activation-log',
    filters: [{ property: 'action', operator: 'eq', value: 'deactivation' }],
    name: 'Deactivations',
    immutable: true,
  },
];

export const DEFAULT_FILE_SEGMENTS: Segment[] = [
  {
    id: 'file-001',
    resource: 'release-file',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'file-002',
    resource: 'release-file',
    filters: [{ property: 'published', operator: 'eq', value: 'true' }],
    name: 'Published',
    immutable: true,
  },
  {
    id: 'file-003',
    resource: 'release-file',
    filters: [{ property: 'published', operator: 'eq', value: 'false' }],
    name: 'Unpublished',
    immutable: true,
  },
];

export const DEFAULT_RELEASE_SEGMENTS: Segment[] = [
  {
    id: 'release-001',
    resource: 'release',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'release-002',
    resource: 'release',
    filters: [{ property: 'published', operator: 'eq', value: 'true' }],
    name: 'Published',
    immutable: true,
  },
  {
    id: 'release-003',
    resource: 'release',
    filters: [{ property: 'published', operator: 'eq', value: 'false' }],
    name: 'Unpublished',
    immutable: true,
  },
  {
    id: 'release-004',
    resource: 'release',
    filters: [{ property: 'private', operator: 'eq', value: 'true' }],
    name: 'Private',
    immutable: true,
  },
];

export const DEFAULT_TRIAL_SEGMENTS: Segment[] = [
  {
    id: 'trial-001',
    resource: 'trial-activation',
    filters: [],
    name: 'All',
    immutable: true,
  },
  {
    id: 'trial-002',
    resource: 'trial-activation',
    filters: [
      {
        property: 'createdAt',
        operator: 'gt',
        value: getDateNDaysAgo(1).toISOString(),
      },
    ],
    name: 'New',
    immutable: true,
  },
  {
    id: 'trial-003',
    resource: 'trial-activation',
    filters: [{ property: 'expired', operator: 'eq', value: 'true' }],
    name: 'Expired',
    immutable: true,
  },
];

export type SegmentCreationModel = Omit<Segment, 'id'>;
export type SegmentCreationFormData = {
  create: (body: SegmentCreationModel) => Promise<HttpResponse<Segment>>;
  resource: SegmentResourceName;
  filters: Filter[];
};
