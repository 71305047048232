export const ALL_OS: { [key: string]: string } = {
  windows: 'Windows',
  macos: 'macOS',
  linux: 'Linux',
  ios: 'iOS',
  android: 'Android',
};
/**
 * @param os
 * @returns formated name for os
 */
export const displayOSName = (os: string) => {
  const _os = os.toLowerCase();
  if (_os in ALL_OS) {
    return ALL_OS[_os];
  } else {
    return os;
  }
};
