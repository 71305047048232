import { Injectable } from '@angular/core';
import {
  CtxFormMode,
  reloadAfterSuccess,
  ResourceCreateFormData,
  ResourceName,
  ResourceUpdateFormData,
} from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceService } from 'ng-ui';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddressFormComponent } from './address-form/address-form.component';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends ResourceService {
  override resourceName: ResourceName = 'address';
  override resourceApiPath = '/v3/billing/addresses';

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    permissionsService: PermissionsService,
    private dialog: MatDialog,
    public router: Router
  ) {
    super(apiService, dataCacheService, permissionsService);
  }
  /** Dialog that allows adding billing address */
  async launchAddressAddDialog() {
    const _dialog = this.dialog.open<any, ResourceCreateFormData>(
      AddressFormComponent,
      {
        data: {
          mode: CtxFormMode.Create,
          create: this.create.bind(this),
        },
      }
    );
    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }
  /** Dialog that allows updating billing address */
  async launchAddressUpdateDialog() {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      AddressFormComponent,
      {
        data: {
          resource: this.dataCacheService.getCachedValues('address')?.[0],
          mode: CtxFormMode.Update,
          update: this.update.bind(this),
        },
      }
    );
    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }
  override update(_id: string, body: any): Promise<HttpResponse<any>> {
    return super.update(
      this.dataCacheService.getCachedValues('account').id,
      body
    );
  }
}
