<!-- Action Icon -->
@if (actionIcon && !navIcon) {
<hlm-icon [name]="actionIcon" class="transition-all" inline aria-hidden="true">
</hlm-icon>
}

<!-- Text -->
<span class="ctx-button__text empty:hidden">
  <ng-content></ng-content>
</span>

<!-- Loading -->
@if (loading && !navIcon && !animationService.prefersReducedMotion) {
<mat-spinner
  mode="indeterminate"
  [color]="color"
  [diameter]="spinnerSize"
></mat-spinner>
}

<!-- Nav Icon / Supplementary Icon -->
@if (!actionIcon && navIcon) {
<hlm-icon [name]="navIcon" inline class="transition-all"></hlm-icon>
}
