<h2 mat-dialog-title>{{ dialogData.mode }} Trial Policy</h2>
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formValue, dialogData, dialogRef, tab)"
>
  @if (showApiMessage) {
  <ctx-alert [alertLevel]="apiMessage.alertLevel">{{
    apiMessage.text
  }}</ctx-alert>
  }
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Details" icon="DETAILS" [stepControl]="detailsTab">
        <section class="flex flex-col gap-4">
          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
            />
            <mat-hint>Name of the policy.</mat-hint>
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
          </mat-form-field>

          <!-- Trial Length -->
          <mat-form-field>
            <mat-label>Trial Length</mat-label>
            <input
              autocomplete="off"
              type="number"
              matInput
              formControlName="trialLength"
              required
            />
            <span matSuffix>days</span>
            <mat-hint>The duration after which the trial will expire.</mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'trialLength')
            }}</mat-error>
          </mat-form-field>

          <!-- Fingerprint Matching Strategy -->
          <mat-form-field>
            <mat-label>Fingerprint Matching Strategy</mat-label>
            <mat-select formControlName="fingerprintMatchingStrategy" required>
              <mat-option value="fuzzy"> Fuzzy </mat-option>
              <mat-option value="exact"> Exact </mat-option>
              <mat-option value="loose"> Loose </mat-option>
            </mat-select>
            <mat-hint>Strategy for matching machine fingerprint.</mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'fingerprintMatchingStrategy')
            }}</mat-error>
          </mat-form-field>
        </section>
      </ctx-tab>
      <ctx-tab
        label="Advanced"
        icon="ADVANCED_SETTINGS"
        [stepControl]="advancedTab"
      >
        <section class="flex flex-col gap-4">
          <h2>Geolocation</h2>
          <!-- Disable Geolocation -->
          <mat-checkbox formControlName="disableGeoLocation"
            >Disable Geolocation</mat-checkbox
          >

          <!-- Allowed Countries -->
          <mat-form-field>
            <mat-label>Allowed Countries</mat-label>
            <mat-select formControlName="allowedCountries" multiple>
              @for (country of ALL_COUNTRIES | keyvalue : originalOrder; track
              country) {
              <mat-option [value]="country.key">
                {{ country.value }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'allowedCountries')
            }}</mat-error>
          </mat-form-field>

          <!-- Disallowed Countries -->
          <mat-form-field>
            <mat-label>Disallowed Countries</mat-label>
            <mat-select formControlName="disallowedCountries" multiple>
              @for (country of ALL_COUNTRIES | keyvalue : originalOrder; track
              country) {
              <mat-option [value]="country.key">
                {{ country.value }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'disallowedCountries')
            }}</mat-error>
          </mat-form-field>
          <hr />

          <h2>IP Addresses</h2>
          <!-- Allowed IP Ranges -->
          <mat-form-field>
            <mat-label>Allowed IP Ranges</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              formControlName="allowedIpRanges"
            />
            <mat-hint
              >Comma separated IP ranges in CIDR notation e.g.
              192.168.100.14/24</mat-hint
            >
            <mat-error>{{
              getControlError(formGroup, 'allowedIpRanges')
            }}</mat-error>
          </mat-form-field>

          <!-- Allowed IP Address -->
          <mat-form-field>
            <mat-label>Allowed IP Address</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              formControlName="allowedIpAddresses"
            />
            <mat-hint>Comma separated IP addresses</mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'allowedIpAddresses')
            }}</mat-error>
          </mat-form-field>

          <!-- Disallowed IP Address -->
          <mat-form-field>
            <mat-label>Disallowed IP Address</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              formControlName="disallowedIpAddresses"
            />
            <mat-hint>Comma separated IP addresses</mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'disallowedIpAddresses')
            }}</mat-error>
          </mat-form-field>

          <hr />

          <h2>Virtual Machines</h2>

          <!-- Allow VM Activation -->
          <mat-checkbox formControlName="allowVmActivation"
            >Allow VM Activation</mat-checkbox
          >

          <!-- Allow Container Activation -->
          <mat-checkbox formControlName="allowContainerActivation"
            >Allow Container Activation</mat-checkbox
          >

          <hr />
          <h2>Operating System</h2>
          <!-- User Locked -->
          <section>
            <mat-checkbox formControlName="userLocked"
              >User Locked</mat-checkbox
            >
            <label
              >If checked, the trial activation is locked to the OS user.</label
            >
          </section>
        </section>
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <!-- Button -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
