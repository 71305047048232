import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourcePatchFormData, copyToClipboard } from 'utils';
import { saveAs } from 'file-saver';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CtxButtonComponent } from 'ng-ui';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-product-dat-dialog',
  templateUrl: './product-dat-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    MatProgressSpinnerModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatTooltipModule,
  ],
})
export class ProductDatDialogComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourcePatchFormData,
    public dialogRef: MatDialogRef<ProductDatDialogComponent>
  ) {
    super();
  }
  loadingState = true;
  productDat: any;

  copyToClipboard = copyToClipboard;

  ngOnInit(): void {
    this.setState('disabled', true);
    this.dialogData
      .patch(this.dialogData.resource.id)
      .then((response) => {
        this.productDat = response;
        this.setState('disabled', false);
        this.loadingState = false;
      })
      .catch((errorResponse) => {
        this.setApiMessage(errorResponse);
        this.setState('disabled', false);
      });
  }
  /*Download product dat file */
  downloadProductDatFile() {
    const blob = new Blob([this.productDat], { type: 'text/plain' });
    saveAs(blob, `Product_${this.dialogData.resource.name}.dat`);
  }
}
