<h2 mat-dialog-title>{{ dialogData.mode }} Channel</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Product -->
      <mat-form-field>
        <mat-label>Product</mat-label>
        <mat-select
          [disabled]="dialogData.mode === 'Update'"
          formControlName="productId"
          required
        >
          @for (product of products; track product) {
          <mat-option class="ph-no-capture" [value]="product.id">
            {{ product.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <!--Display Name -->
      <mat-form-field>
        <mat-label>Display Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="displayName"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'displayName') }}</mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea rows="1" formControlName="description" matInput></textarea>
        <mat-error>{{ getControlError(formGroup, 'description') }}</mat-error>
      </mat-form-field>

      <!-- Private -->
      <section>
        <mat-checkbox formControlName="private">Private</mat-checkbox>
        <label>Private channels do not appear in the customer portal.</label>
      </section>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
