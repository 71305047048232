<h2 mat-dialog-title mat-dialog-title>Allowed Permissions</h2>
<mat-dialog-content>
  <ctx-permission-selection
    [control]="permissionControls"
    [disableCheckbox]="true"
  ></ctx-permission-selection>
</mat-dialog-content>
<mat-dialog-actions>
  <button matDialogClose ctx-button--primary>Close</button>
</mat-dialog-actions>
