<h2 mat-dialog-title>{{ dialogData.mode }} Webhook</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Name -->
      <mat-form-field class="mt-2">
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-hint>Name for the webhook.</mat-hint>
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <!-- URL -->
      <mat-form-field>
        <mat-label>URL</mat-label>
        <input
          autocomplete="off"
          type="url"
          formControlName="url"
          matInput
          required
        />
        <mat-hint
          >The endpoint which will receive the HTTP POST payload.</mat-hint
        >
        <mat-error>{{ getControlError(formGroup, 'url') }}</mat-error>
      </mat-form-field>

      <!-- Token -->
      <mat-form-field>
        <mat-label>Token</mat-label>
        <input
          autocomplete="off"
          [type]="isTokenVisible ? 'text' : 'password'"
          formControlName="token"
          matInput
          required
        />
        <button
          matSuffix
          ctx-button--secondary
          color="accent"
          type="button"
          [actionIcon]="getVisibilityIcon(isTokenVisible)"
          (click)="isTokenVisible = !isTokenVisible"
        ></button>
        <mat-hint
          >The secret which will be used to sign the payload using HMAC256
          algorithm.</mat-hint
        >
        <mat-error>{{ getControlError(formGroup, 'token') }}</mat-error>
      </mat-form-field>

      <!-- Events -->
      <mat-form-field>
        <mat-label>Events</mat-label>
        <mat-select multiple formControlName="events" required>
          @for (key of eventTypeIterable; track key) {
          <mat-optgroup [label]="convertCamelCaseToTitleCase(key)">
            @for (eventType of events[key]; track eventType) {
            <mat-option [value]="eventType">
              {{ eventType }}
            </mat-option>
            }
          </mat-optgroup>
          }
        </mat-select>
        <mat-hint>The list of events you want to subscribe.</mat-hint>
        <mat-error>{{ getControlError(formGroup, 'events') }}</mat-error>
      </mat-form-field>

      <!-- Active -->
      <section>
        <mat-checkbox formControlName="active">Active</mat-checkbox>
        <label
          >If checked, webhook is triggered at the selected trigger
          events.</label
        >
      </section>
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
