import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { copyToClipboard, navigateWithFilters } from 'utils';
import { ResourceName } from 'utils';
import { CTX_ADMIN_ROUTES, CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { WebhookFormComponent } from './webhook-form/webhook-form.component';
import {
  FilterableProperties,
  SEARCH_STRINGS,
  TableActions,
  TableColumn,
} from 'utils';
import { ViewArrayDataComponent } from 'admin-portal/common/view-array-data/view-array-data.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class WebhookService extends ResourceTableService {
  override resourceApiPath = '/v3/webhooks';
  override resourceName: ResourceName = 'webhook';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override selections = new SelectionModel<any>(true, []);
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'url', displayType: 'text' },
    {
      property: 'events',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    { property: 'active', displayType: 'flag' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.writeAllowed;
        },
      },
      {
        label: 'Copy Token',
        callback: async (webhook) => {
          copyToClipboard(webhook.token);
        },
        icon: 'COPY',
      },
      {
        label: 'View Logs',
        callback: this.navigateToWebhookEventLogs.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };
  override _columnIdsToDisplay: string[] = ['name', 'url', 'events', 'active'];
  override filterableProperties: FilterableProperties = {};
  override tableEmptyMessage =
    'Webhooks are HTTP callbacks which are triggered by specific events. Start by creating one using the Create button';
  override creationComponent = WebhookFormComponent;
  override updationComponent = WebhookFormComponent;
  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }

  async navigateToWebhookEventLogs(webhook: any) {
    await navigateWithFilters(
      [
        {
          property: 'webhookId',
          value: webhook.id,
          operator: 'eq',
        },
      ],
      this.router,
      CTX_ADMIN_ROUTES.getUrl('webhook-event-logs')
    );
  }
}
