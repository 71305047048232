/**
 *
 * @returns string for displaying unlimited text
 */
export const UNLIMITED_TEXT = 'Unlimited';

/**
 *
 * @param license
 * @returns string for display properties for which  `unlimited===-1`
 */
export function formatUnlimited(value: number): string {
  if (value === -1) {
    return UNLIMITED_TEXT;
  }
  return `${value}`;
}
