import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ResourceViewDialogData } from 'utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CtxButtonComponent } from 'ng-ui';

@Component({
  selector: 'ctx-html-render',
  standalone: true,
  imports: [MatDialogModule, CtxButtonComponent],
  templateUrl: './html-render.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CtxHtmlRenderComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceViewDialogData,
    private domSanitizer: DomSanitizer
  ) {}
  @Input() html: string;
  sanitizeBypassedHtml: SafeHtml;
  ngOnInit() {
    this.sanitizeBypassedHtml = this.domSanitizer.bypassSecurityTrustHtml(
      this.html
    );
  }
}
