import { Injectable } from '@angular/core';
import { ResourceName } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceService } from 'ng-ui';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

/**
 * This service only fetches plans. Changing plans is part of the account service
 * as the endpoint for changing plans shares the same root as the account endpoint.
 */
@Injectable({
  providedIn: 'root',
})
export class PlanService extends ResourceService {
  override resourceName: ResourceName = 'plan';
  override resourceApiPath = '/v3/plans';

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }
}
