import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderComponent } from '../_components/loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  overlayRef = this.overlay.create({
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
    hasBackdrop: true,
  });

  constructor(private overlay: Overlay) {}

  showLoader() {
    this.overlayRef.attach(new ComponentPortal(LoaderComponent));
  }

  hideLoader() {
    this.overlayRef.detach();
  }
}
