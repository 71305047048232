<h2 mat-dialog-title>Delete</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}

<mat-dialog-content>
  <p>{{ dialogData.deletionMessage }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary [disabled]="formStates.disabled">
    Close
  </button>
  <button
    ctx-button--primary
    [loading]="formStates.submit"
    [disabled]="formStates.disabled"
    (click)="delete(dialogData, dialogRef)"
  >
    Delete
  </button>
</mat-dialog-actions>
