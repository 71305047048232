import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourceUpdateFormData } from 'utils';
import { CtxAccountStatus } from 'admin-portal/pages/account/account.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepperNextDirective } from 'ng-ui';
import { CtxButtonComponent } from 'ng-ui';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-account-status-form',
  templateUrl: './account-status-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    CtxStepperComponent,
    CtxStepComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    CtxStepperNextDirective,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class AccountStatusFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AccountStatusFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData
  ) {
    super();
  }

  /** The currently set account status */
  currentAccountStatus: CtxAccountStatus | undefined =
    this.dialogData.resource.status;

  /** The account status to set. */
  get newAccountStatus(): CtxAccountStatus {
    if (this.currentAccountStatus && this.currentAccountStatus === 'active') {
      return 'inactive';
    } else {
      return 'active';
    }
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      deactivationConfirmationText: [
        '',
        Validators.pattern(/I am absolutely sure/g),
      ],
    });
  }

  /** Status set to the active/inactive based on the current status */
  get formValue() {
    return { status: this.newAccountStatus };
  }
}
