import { environment } from 'utils';
import { CtxRoutes, RouteNode } from './base-routes';

export const _BASE_ROUTE_PATHS = [
  'home',
  'license-management',
  'activation-management',
  'feature-management',
  'trial-management',
  'release-management',
  'feature-management',
  'customers',
  'developer',
  'settings',
  'auth',
  'pay-invoice',
] as const;
type _BaseRoutePaths = (typeof _BASE_ROUTE_PATHS)[number];

export const _PAGE_ROUTE_PATHS = [
  'overview',
  'products',
  'tags',
  'licenses',
  'license-templates',
  'maintenance-policies',
  'activations',
  'activation-logs',
  'trial-activations',
  'trial-policies',
  'users',
  'organizations',
  'webhooks',
  'webhook-event-logs',
  'automated-emails',
  'automated-email-event-logs',
  'access-tokens',
  'sdk-downloads',
  'profile',
  'account',
  'billing-and-plans',
  'roles',
  'team',
  'customizations',
  'audit-logs',
  'releases',
  'files',
  'platforms',
  'channels',
  'feature-flags',
  'product-versions',
  'resellers',
  'login',
  'reset-password',
  'forgot-password',
  'sso',
  'two-factor',
  'server-info',
  'saved-filters',
  'verify-account',
  'signup',
] as const;
type _PageRoutePaths = (typeof _PAGE_ROUTE_PATHS)[number];

type AdminRouteNode = RouteNode<_BaseRoutePaths, _PageRoutePaths>;
class cAdminRoutes extends CtxRoutes<_BaseRoutePaths, _PageRoutePaths> {
  routes: AdminRouteNode[] = [
    {
      /** icons in auth and child routes are not used anywhere */
      path: 'auth',
      label: 'Log in',
      icon: 'SIGNUP',
      hidden: () => {
        return true;
      },
      children: [
        {
          path: 'login',
          label: 'Log in',
          icon: 'SIGNUP',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'reset-password',
          label: 'Reset Password',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'forgot-password',
          label: 'Forgot Password',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'sso',
          label: 'SSO Login',
          icon: 'SSO',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'two-factor',
          label: 'Two Factor Login',
          icon: 'RESET_PASSWORD',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'verify-account',
          label: 'Verify Account',
          icon: 'SIGNUP',
          hidden: () => {
            return true;
          },
        },
        {
          path: 'signup',
          label: 'Signup',
          icon: 'SIGNUP',
          hidden: () => {
            return true;
          },
        },
      ],
    },
    {
      path: 'home',
      icon: 'HOME',
      label: 'Home',
      children: [
        {
          path: 'overview',
          label: 'Overview',
          icon: 'OVERVIEW',
        },

        {
          path: 'products',
          label: 'Products',
          icon: 'PRODUCTS',
        },

        {
          path: 'tags',
          label: 'Tags',
          icon: 'TAG',
        },
      ],
    },
    {
      path: 'feature-management',
      icon: 'PRODUCT_VERSIONS',
      label: 'Features',
      children: [
        {
          path: 'feature-flags',
          label: 'Feature Flags',
          icon: 'FEATURE_FLAGS',
        },
        {
          path: 'product-versions',
          label: 'Product Versions',
          icon: 'PRODUCT_VERSIONS',
        },
      ],
    },

    {
      path: 'license-management',
      icon: 'LICENSES',
      label: 'Licenses',
      children: [
        {
          path: 'licenses',
          label: 'Licenses',
          icon: 'LICENSES',
        },

        {
          path: 'license-templates',
          label: 'License Templates',
          icon: 'LICENSE_TEMPLATES',
        },

        {
          path: 'maintenance-policies',
          label: 'Maintenance Policies',
          icon: 'MAINTENANCE_POLICIES',
        },
      ],
    },

    {
      path: 'activation-management',
      icon: 'ACTIVATIONS',
      label: 'Activations',
      children: [
        {
          path: 'activations',
          label: 'Activations',
          icon: 'ACTIVATIONS',
        },

        {
          path: 'activation-logs',
          label: 'Activation Logs',
          icon: 'LOGS',
        },
      ],
    },
    {
      path: 'trial-management',
      icon: 'TRIALS',
      label: 'Trials',
      children: [
        {
          path: 'trial-activations',
          label: 'Trial Activations',
          icon: 'TRIALS',
        },

        {
          path: 'trial-policies',
          label: 'Trial Policies',
          icon: 'TRIAL_POLICIES',
        },
      ],
    },
    {
      path: 'release-management',
      icon: 'RELEASES',
      label: 'Releases',
      children: [
        {
          path: 'releases',
          label: 'Releases',
          icon: 'RELEASES',
        },
        {
          path: 'files',
          label: 'Files',
          icon: 'FILES',
        },
        {
          path: 'platforms',
          label: 'Platforms',
          icon: 'PLATFORMS',
        },
        {
          path: 'channels',
          label: 'Channels',
          icon: 'CHANNELS',
        },
      ],
    },
    {
      path: 'customers',
      icon: 'CUSTOMERS',
      label: 'Customers',
      children: [
        {
          path: 'users',
          label: 'Users',
          icon: 'USER',
        },

        {
          path: 'organizations',
          label: 'Organizations',
          icon: 'ORGANIZATIONS',
        },
        {
          path: 'resellers',
          label: 'Resellers (Beta)',
          icon: 'RESELLERS',
        },
      ],
    },

    {
      path: 'developer',
      icon: 'DEVELOPER',
      label: 'Developer',
      children: [
        {
          path: 'webhooks',
          label: 'Webhooks',
          icon: 'WEBHOOKS',
        },

        {
          path: 'webhook-event-logs',
          label: 'Webhook Event Logs',
          icon: 'LOGS',
        },

        {
          path: 'automated-emails',
          label: 'Automated Emails',
          icon: 'AUTOMATED_EMAILS',
        },

        {
          path: 'automated-email-event-logs',
          label: 'Email Event Logs',
          icon: 'LOGS',
        },
        {
          path: 'access-tokens',
          label: 'Access Tokens',
          icon: 'ACCESS_TOKENS',
        },

        {
          path: 'sdk-downloads',
          label: 'SDK Downloads',
          icon: 'SDK_DOWNLOADS',
        },
        {
          path: 'audit-logs',
          label: 'Audit Logs',
          icon: 'LOGS',
        },
      ],
    },
    {
      path: 'pay-invoice',
      label: 'Pay Invoice',
      icon: 'BILLING_AND_PLANS',
      hidden: () => {
        return true;
      },
    },
    {
      path: 'settings',
      icon: 'SETTINGS',
      label: 'Settings',
      children: [
        {
          path: 'profile',
          label: 'Profile',
          icon: 'PROFILE',
        },

        {
          path: 'account',
          label: 'Account',
          icon: 'ACCOUNT',
        },

        {
          path: 'billing-and-plans',
          label: 'Billing and Plans',
          icon: 'BILLING_AND_PLANS',
          hidden: () => {
            return environment.get('name') === 'on-premise';
          },
        },
        {
          path: 'saved-filters',
          label: 'Saved Filters',
          icon: 'FILTER_LIST',
        },
        {
          path: 'roles',
          label: 'Roles',
          icon: 'ROLES',
        },
        {
          path: 'team',
          label: 'Team',
          icon: 'TEAM',
        },
        {
          path: 'customizations',
          label: 'Customizations',
          icon: 'WHITELABELING',
        },
        {
          path: 'server-info',
          label: 'Server Info',
          icon: 'SERVERINFO',
          hidden: () => {
            return environment.get('name') !== 'on-premise';
          },
        },
      ],
    },
  ];

  getUrl(name: _PageRoutePaths): string {
    const { baseNode, leafNode } = this.getLeaf(name);
    return `/${baseNode.path}/${leafNode.path}`;
  }
}

export const CTX_ADMIN_ROUTES = new cAdminRoutes();
