import { Provider } from '@angular/core';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

export const highlightJsDefaults: Provider = {
  provide: HIGHLIGHT_OPTIONS,
  useValue: {
    coreLibraryLoader: () => {
      return import('highlight.js/lib/core');
    },
    languages: {
      json: () => {
        return import('highlight.js/lib/languages/json');
      },
    },
  },
};
