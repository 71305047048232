<form [formGroup]="formGroup" class="border rounded-md border-solid">
  <section class="max-h-60 overflow-auto px-1">
    <table mat-table [dataSource]="DATA_SOURCE">
      <!-- Permission Column -->
      <ng-container matColumnDef="permission">
        <th mat-header-cell *matHeaderCellDef class="font-bold">Permission</th>
        <td mat-cell *matCellDef="let element">
          {{ element.permission }}
        </td>
      </ng-container>
      <!-- Create Column -->
      <ng-container matColumnDef="create">
        <th mat-header-cell *matHeaderCellDef>Create</th>
        <td mat-cell *matCellDef="let element">
          @if(element.create) {<mat-checkbox
            [formControlName]="element.create"
          ></mat-checkbox>
          }
        </td>
      </ng-container>
      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let element">
          @if(element.delete) {<mat-checkbox
            [formControlName]="element.delete"
          ></mat-checkbox>
          }
        </td>
      </ng-container>
      <!-- Update Column-->
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef>Update</th>
        <td mat-cell *matCellDef="let element">
          @if(element.update) {<mat-checkbox
            [formControlName]="element.update"
          ></mat-checkbox>
          }
        </td>
      </ng-container>
      <!-- Read Column -->
      <ng-container matColumnDef="read">
        <th mat-header-cell *matHeaderCellDef>Read</th>
        <td mat-cell *matCellDef="let element">
          @if (element.read) {
          <mat-checkbox [formControlName]="element.read"></mat-checkbox>
          }
        </td>
      </ng-container>

      <!-- Write Column -->
      <ng-container matColumnDef="write">
        <th mat-header-cell *matHeaderCellDef>Write</th>
        <td mat-cell *matCellDef="let element">
          @if (element.write) {
          <mat-checkbox [formControlName]="element.write"></mat-checkbox>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
  </section>
</form>
