import {
  CdkStep,
  CdkStepper,
  STEPPER_GLOBAL_OPTIONS,
  StepperOptions,
} from '@angular/cdk/stepper';
import {
  Component,
  ContentChild,
  Inject,
  Input,
  Optional,
  forwardRef,
} from '@angular/core';
import { CtxStepLabelDirective } from '../step-directives/step-label.directive';
import { FormControl } from '@angular/forms';
import { CtxIcons } from 'utils';

@Component({
  selector: 'ctx-step, ctx-tab',
  templateUrl: './step.component.html',
  providers: [{ provide: CdkStep, useExisting: CtxStepComponent }],
  exportAs: 'ctxStep',
  standalone: true,
})
export class CtxStepComponent extends CdkStep {
  /**
   * As defined in the StepLabelDirective, it is still unclear why this is used.
   */
  @ContentChild(CtxStepLabelDirective)
  override stepLabel: CtxStepLabelDirective;

  /**
   * String identifier for icon
   */
  @Input() icon: CtxIcons;

  /**
   * The getter hasError is over-ridden to account for
   * frameworks in ability to update status of parent formControl based
   * on child formControls validity status.
   */
  override get hasError() {
    const isInvalid = () => {
      if (
        this.stepControl &&
        this.stepControl.value &&
        Array.isArray(this.stepControl.value)
      ) {
        return this.stepControl.value.some((fc: unknown) => {
          return fc instanceof FormControl && fc.invalid;
        });
      }
      return false;
    };

    return super.hasError || isInvalid();
  }

  constructor(
    // https://angular.io/api/core/forwardRef#example
    @Inject(
      forwardRef(() => {
        return CdkStepper;
      })
    )
    _stepper: CdkStepper,
    // Enables app-wide or module-wide provider for StepperOptions
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(_stepper, stepperOptions);
  }
}
