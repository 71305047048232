import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { AccountAliasesFormData } from 'utils';
import { CtxButtonComponent } from '../button/button.component';
import { HlmIconComponent } from '../icon';

@Component({
  selector: 'ctx-account-aliases',
  templateUrl: './account-aliases.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    CtxButtonComponent,
    MatDialogModule,
    HlmIconComponent,
  ],
})
export class AccountAliasesComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: AccountAliasesFormData
  ) {}
}
