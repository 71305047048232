import { Pipe, PipeTransform } from '@angular/core';
import { nextDayDate } from 'utils';

@Pipe({
  name: 'addDays',
  standalone: true,
})
export class AddDaysPipe implements PipeTransform {
  transform(value: number, startDate: string): any {
    if (value === 0) {
      value = 1;
    }
    return nextDayDate(startDate, value);
  }
}
