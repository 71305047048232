<h2 mat-dialog-title>
  {{ dialogData.mode === 'Create' ? 'Add' : 'Update' }} Credit Card
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form [formGroup]="formGroup" (ngSubmit)="_submit()">
  <mat-dialog-content>
    <section class="mb-2">
      @if (!formStates.stripe) {
      <h2>Card Details</h2>
      }
      <div id="payment-element">
        <!-- Stripe Elements will create form elements here -->
      </div>
    </section>
    @if (formStates.stripe) {
    <span class="flex flex-col items-center gap-1">
      <mat-spinner></mat-spinner>
    </span>
    } @else {
    <section class="mt-6">
      <h2>Billing Address</h2>
      <section class="flex flex-col gap-4">
        <mat-form-field>
          <mat-label>Address Line 1</mat-label>
          <input type="text" formControlName="addressLine1" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Address Line 2</mat-label>
          <input type="text" formControlName="addressLine2" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input type="text" formControlName="city" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>ZIP / Post Code</mat-label>
          <input type="text" formControlName="postalCode" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>State / Province / Region</mat-label>
          <input type="text" formControlName="state" matInput />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Country</mat-label>
          <mat-select formControlName="country">
            @for (country of countries | keyvalue; track country) {
            <mat-option [value]="country.key">
              {{ country.value }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>
    </section>
    }
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row">
    <button
      ctx-button--secondary
      type="button"
      matDialogClose
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode === 'Create' ? 'Add' : 'Update' }}
    </button>
  </mat-dialog-actions>
</form>
