import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { projectEnv } from 'utils';
import { CtxForm, ScriptService, ThemeManagerService } from 'ng-ui';
import {
  ALL_COUNTRIES,
  ResourceCreateFormData,
  ResourceUpdateFormData,
} from 'utils';
import { PaymentCardService } from '../card.service';
import { KeyValuePipe } from '@angular/common';
import { CtxButtonComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CtxAlertComponent } from 'ng-ui';
declare const Stripe: any;

@Component({
  selector: 'ctx-stripe-card-form',
  templateUrl: './stripe-card-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    KeyValuePipe,
  ],
})
export class StripeCardFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<StripeCardFormComponent>,
    public fb: FormBuilder,
    private scriptService: ScriptService,
    private paymentCardService: PaymentCardService,
    private themeManagerService: ThemeManagerService
  ) {
    super();
  }
  countries = ALL_COUNTRIES;
  stripe: any;
  stripeCard: any;
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      addressLine1: ['', Validators.maxLength(256)],
      addressLine2: ['', Validators.maxLength(256)],
      city: ['', Validators.maxLength(256)],
      postalCode: ['', Validators.maxLength(256)],
      state: ['', Validators.maxLength(256)],
      country: ['', Validators.maxLength(256)],
    });
    this.setState('stripe', true);
    this.scriptService.loadStripe().then((loaded) => {
      if (loaded) {
        this.setState('stripe', false);
        this.setupStripe();
      } else {
        this.setCustomApiMessage({
          alertLevel: 'warning',
          text: `Unable to load Stripe, please try reloading this page. If the problem persists, contact support using the chat bubble.`,
        });
      }
    });
  }
  /**
   * Setup the stripe card element
   */
  setupStripe() {
    this.stripe = Stripe(projectEnv.get('stripeApiKey'));
    const elements = this.stripe.elements();

    // Create and mount the Card Element. Note that the Appearance API is not applicable on the Card Element.
    this.stripeCard = elements.create('card', {
      style: {
        base: {
          color: `${this.themeManagerService.isDarkMode ? 'white' : 'black'} `, //accounts for theme change
        },
      },
    });
    this.stripeCard.mount('#payment-element');
  }
  async _submit() {
    this.setState('submit', true);
    try {
      const result = await this.stripe.createToken(this.stripeCard);
      if (result.error) {
        this.setApiMessage(result.error.message);
      } else {
        const token = result.token.id;
        const body = { token, ...this.formGroup.value };
        await this.paymentCardService.create(body);
        await this.closeDialogAfterTimeout(this.dialogRef, true);
      }
    } catch (error) {
      this.setApiMessage(error);
    }
    this.setState('submit', false);
  }
}
