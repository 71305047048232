import { Component, Inject, OnInit } from '@angular/core';

import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CtxAlertComponent, CtxButtonComponent, CtxForm } from 'ng-ui';
import { ResourceUpdateFormData } from 'utils';
import { ProfileService } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'ctx-two-factor-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatDialogModule,
    CtxAlertComponent,
    ReactiveFormsModule,
    CtxButtonComponent,
    MatProgressSpinnerModule,
    QRCodeModule,
  ],
  templateUrl: './two-factor-form.component.html',
})
export class TwoFactorFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<TwoFactorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData
  ) {
    super();
  }

  /** OTP URL required to generate a code on Authentication Applications. */
  twoFactorOtpUrl: string;

  ngOnInit() {
    this.formGroup = this.fb.group({
      twoFactorCode: [null, [Validators.minLength(6)]],
    });
    this.profileService
      .getTwoFactorSecret()
      .then((response) => {
        this.twoFactorOtpUrl = response.body.url;
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  get formValue() {
    return {
      twoFactorCode: this.formGroup.value.twoFactorCode,
      twoFactorEnabled: !this.dialogData.resource.twoFactorEnabled,
    };
  }

  override async submit(): Promise<void> {
    try {
      this.resetApiMessage();
      this.setState('submit', true);
      await this.profileService.update('', this.formValue);
      await this.closeDialogAfterTimeout(this.dialogRef, true);
      if (this.formGroup.value.twoFactorCode) {
        await this.profileService.launchRecoveryCodeDialog();
      }
    } catch (error) {
      this.setApiMessage(error);
    }
    this.setState('submit', false);
  }
}
