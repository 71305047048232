import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  ReactiveFormsModule,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxDynamicForm, CtxStepComponent, CtxStepperComponent } from 'ng-ui';
import {
  ALL_COUNTRIES,
  originalOrder,
  ResourceCreateFormData,
  ResourceUpdateFormData,
} from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxTagComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'ctx-address-form',
  templateUrl: './address-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    CtxStepComponent,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    CtxTagComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatSelectModule,
    MatOptionModule,
    CtxStepperComponent,
  ],
})
export class AddressFormComponent extends CtxDynamicForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    fb: FormBuilder,

    public dialogRef: MatDialogRef<AddressFormComponent>
  ) {
    super(fb);
  }
  ALL_COUNTRIES = ALL_COUNTRIES;
  originalOrder = originalOrder;

  addressTab: FormControl;

  contactTab: FormArray;

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      taxIdentifier: [
        this.getInitialControlValue('taxIdentifier', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      firstLine: [
        this.getInitialControlValue('firstLine', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      secondLine: [
        this.getInitialControlValue('secondLine', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      city: [
        this.getInitialControlValue('city', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      region: [
        this.getInitialControlValue('region', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      postalCode: [
        this.getInitialControlValue('postalCode', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      countryCode: [
        this.getInitialControlValue('countryCode', this.dialogData) || '',
        Validators.maxLength(256),
      ],
      contacts: this.fb.array(
        this.getInitialFormArrayValue('contacts', this.dialogData) || []
      ),
    });

    this.addressTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('taxIdentifier'),
      this.formGroup.get('firstLine'),
      this.formGroup.get('secondLine'),
      this.formGroup.get('postalCode'),
      this.formGroup.get('countryCode'),
      this.formGroup.get('city'),
      this.formGroup.get('region'),
    ]);

    this.contactTab = this.formGroup.get('contacts') as FormArray;
  }

  addContact() {
    const contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.contactTab.push(contactForm);
  }

  removeContact(index: number) {
    this.contactTab.removeAt(index);
  }
}
