import { Component, Inject, OnInit } from '@angular/core';

import { CtxButtonComponent } from 'ng-ui';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ViewArrayDataComponent } from '../view-array-data/view-array-data.component';
import { ResourceViewDialogData, getCountriesByName } from 'utils';

@Component({
  selector: 'ctx-view-country-data',
  standalone: true,
  imports: [MatDialogModule, CtxButtonComponent],
  templateUrl: './view-country-data.component.html',
})
export class ViewCountryDataComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ViewArrayDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceViewDialogData
  ) {}
  countries: string[];
  ngOnInit(): void {
    if (this.dialogData.property) {
      this.countries = getCountriesByName(
        this.dialogData.resource,
        this.dialogData.property
      );
    }
  }
}
