<h2 mat-dialog-title mat-dialog-title>{{ dialogData.mode }} Product</h2>
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef, tab)"
>
  @if (showApiMessage) {
  <ctx-alert [alertLevel]="apiMessage.alertLevel">{{
    apiMessage.text
  }}</ctx-alert>
  }
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Details" [stepControl]="detailsTab" icon="DETAILS">
        <section class="flex flex-col gap-4">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Display Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="displayName"
              matInput
              required
            />
            <mat-hint>This name is displayed in the customer portal.</mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'displayName')
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea
              rows="1"
              formControlName="description"
              matInput
              required
            ></textarea>
            <mat-error>{{
              getControlError(formGroup, 'description')
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Default License Template</mat-label>
            <mat-select formControlName="licensePolicyId" required>
              @for (licenseTemplate of licenseTemplates; track licenseTemplate)
              {
              <mat-option class="ph-no-capture" [value]="licenseTemplate.id">
                {{ licenseTemplate.name }}
              </mat-option>
              }
            </mat-select>
            <mat-hint
              >A fallback value for all licenses of this product. Values can be
              overriden during license creation. Updating the default license
              template does not update any licenses.</mat-hint
            >
            <mat-error>{{
              getControlError(formGroup, 'licensePolicyId')
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Trial Policy</mat-label>
            <mat-select formControlName="trialPolicyId">
              <mat-option value=""><em>None</em></mat-option>
              @for (trialPolicy of trialPolicies; track trialPolicy) {
              <mat-option class="ph-no-capture" [value]="trialPolicy.id">
                {{ trialPolicy.name }}
              </mat-option>
              }
            </mat-select>
            <mat-hint
              >Sets the configuration for trials within this product.</mat-hint
            >
            <mat-error>{{
              getControlError(formGroup, 'licensePolicyId')
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Automated Emails</mat-label>
            <mat-select formControlName="automatedEmails" multiple>
              @for (automatedEmail of automatedEmails; track automatedEmail) {
              <mat-option class="ph-no-capture" [value]="automatedEmail.name">
                {{ automatedEmail.name }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'automatedEmails')
            }}</mat-error>
          </mat-form-field>
        </section>
      </ctx-tab>

      <ctx-tab label="Metadata" [stepControl]="metadataTab" icon="METADATA">
        <ctx-metadata-form
          [dialogData]="dialogData"
          [hintText]="METADATA_HINT_TEXT"
          [parentForm]="this"
        ></ctx-metadata-form>
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      ctx-button--secondary
      matDialogClose
      type="button"
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
