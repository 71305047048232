import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxButtonComponent } from '../../button/button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'ctx-incorrect-role-error',
  templateUrl: './incorrect-role-error.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class IncorrectRoleErrorComponent implements OnInit {
  incorrectRole: string;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {}

  ngOnInit(): void {
    this.dialogData.logout();
    this.incorrectRole = `The role associated with the entered credentials is incorrect for this application. Please recheck your URL and try again.`;
  }
}
