import { nextDayDate } from 'utils';

export const computeValidityControlHint = (
  action: ActionType,
  validityMethod: ValidityInputType,
  value: number,
  startDate = new Date().toDateString()
) => {
  if (validityMethod === 'days') {
    return ` ${action === 'create' ? 'through' : 'upto'} : ${nextDayDate(
      startDate,
      Number(value)
    ).toDateString()}.`;
  } else {
    return `${action === 'create' ? 'for' : 'by'} : ${value} day(s)`;
  }
};

export type ValidityInputType = 'lifetime' | 'days' | 'date';
type ActionType = 'create' | 'extend';

export const EXPIRY_DATE_EXCEEDS_RANGE =
  'False (New Expiry Date Exceeds the supported range)';
export const LIFETIME_VALIDITY = 'False (License has perpetual validity)';
export const EXPIRY_DATE_LESS_THAN_CREATION =
  'False (Expiry Date cannot be less than Creation Date)';
export const DELAYED_STRATEGY =
  'False (Delayed Strategy and no activations yet)';
export const ROLLING_STRATEGY = 'False (Rolling Strategy)';
export const UNLIMITED_VALUE_MINUS_ONE = '-1 represents an unlimited value.';
export const UNLIMITED_VALUE_ZERO_AND_MINUS_ONE =
  '-1 and 0 both represent an unlimited value.';
