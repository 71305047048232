import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CtxButtonComponent } from 'ng-ui';

@Component({
  selector: 'ctx-free-plan-confirmation',
  standalone: true,
  imports: [CtxButtonComponent, MatDialogModule],
  templateUrl: './free-plan-confirmation.component.html',
})
export class FreePlanConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<FreePlanConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { planSubmit: () => void; daysLeft: number }
  ) {}

  closeDialog() {
    this.dialogRef.disableClose = false;
    this.dialogRef.close();
  }
}
