<h2 mat-dialog-title>{{ dialogData.mode }} Automated Email</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef, tab)"
>
  <mat-dialog-content>
    <ctx-tab-group #tab (selectionChange)="getEmailPreview($event)">
      <ctx-tab label="Details" icon="DETAILS" [stepControl]="detailsTab">
        <section class="flex flex-col gap-4">
          <!--  Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
              pattern="^[a-zA-Z0-9_-]+$"
            />
            <mat-hint>Name of the automated email.</mat-hint>
            <mat-error
              >Only letters, numbers, underscores and hyphens are
              allowed.</mat-error
            >
          </mat-form-field>

          <!-- Enabled -->
          <section>
            <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
            <label
              >If checked, the Automated Email will be functional and will be
              sent when triggered.</label
            >
          </section>

          <!-- From Name -->
          <mat-form-field>
            <mat-label>From Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="fromName"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'fromName') }}</mat-error>
            <mat-hint>Name of the email sender.</mat-hint>
          </mat-form-field>

          <!-- From Email  -->
          <mat-form-field>
            <mat-label>From Email</mat-label>
            <input
              autocomplete="off"
              type="email"
              formControlName="fromEmail"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'fromEmail') }}</mat-error>
            <mat-hint>Email address of sender.</mat-hint>
          </mat-form-field>

          <!-- Subject -->
          <mat-form-field>
            <mat-label>Subject</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="subject"
              matInput
              required
            />
            <mat-hint
              >The subject of the email. It can contain data placeholders
              too.</mat-hint
            >
            <mat-error>{{ getControlError(formGroup, 'subject') }}</mat-error>
          </mat-form-field>

          <!-- Event -->
          <mat-form-field>
            <mat-label>Trigger Event</mat-label>
            <mat-select formControlName="event" required>
              @for (EVENT of DEFAULT_EVENTS; track EVENT) {
              <mat-option [value]="EVENT">
                {{ EVENT }}
              </mat-option>
              }
            </mat-select>
            <mat-hint>The event to trigger the email.</mat-hint>
            <mat-error>{{ getControlError(formGroup, 'event') }}</mat-error>
          </mat-form-field>

          <!-- CC -->
          <mat-form-field>
            <mat-label>CC</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="cc"
              matInput
            />
            @if(formGroup.get('event')?.value==='user.reset-password-request'){
            <mat-hint
              >CC is disabled for the user.reset-password-request
              event.</mat-hint
            >
            } @else {
            <mat-hint
              >CC address for the automated email. Separate multiple emails with
              commas.</mat-hint
            >
            }
          </mat-form-field>
          <!-- BCC -->
          <mat-form-field>
            <mat-label>BCC</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="bcc"
              matInput
            />
            @if(formGroup.get('event')?.value==='user.reset-password-request'){
            <mat-hint
              >BCC is disabled for the user.reset-password-request
              event.</mat-hint
            >
            } @else {
            <mat-hint
              >BCC address for the automated email. Separate multiple emails
              with commas.</mat-hint
            >
            }
          </mat-form-field>
          <!-- Reply To -->
          <mat-form-field>
            <mat-label>Reply To</mat-label>
            <input
              autocomplete="off"
              type="email"
              formControlName="replyTo"
              matInput
            />
            <mat-hint>Reply-To address for the automated email.</mat-hint>
          </mat-form-field>

          <!-- Custom? -->
          <section>
            <mat-checkbox formControlName="custom">Custom HTML</mat-checkbox>
            <label
              >By default, the email body is wrapped in a responsive HTML
              template. If checked, this is not done, and you can write your own
              HTML.</label
            >
          </section>
        </section>
      </ctx-tab>
      <ctx-tab label="Body" icon="ADVANCED_SETTINGS" [stepControl]="bodyTab">
        <section class="flex flex-col gap-4">
          <!-- Body -->
          <section class="flex flex-col gap-4">
            <button
              ctx-button--secondary
              navIcon="ACTION_MENU"
              class="self-end"
              type="button"
              [matMenuTriggerFor]="placeholderMatMenu"
            >
              Insert Placeholder
            </button>
            <mat-menu #placeholderMatMenu="matMenu">
              @for (placeholder of placeholders(); track placeholder) {
              <button
                mat-menu-item
                (click)="appendPlaceholderToBody(placeholder)"
              >
                {{ placeholder }}
              </button>
              }
            </mat-menu>
            <!-- Body -->
            <mat-form-field>
              <mat-label>Body</mat-label>
              <textarea
                matInput
                required
                rows="7"
                formControlName="body"
              ></textarea>
              <mat-hint>Body of the email template.</mat-hint>
              <mat-error>{{ getControlError(formGroup, 'body') }}</mat-error>
            </mat-form-field>
          </section>
        </section>
      </ctx-tab>
      <ctx-tab label="Preview" icon="PREVIEW">
        @if (formGroup.invalid) {
        <section>
          <p>
            The form is incomplete. Complete the required form parameters to
            proceed.
          </p>
        </section>
        } @else { @if(!html){
        <div class="flex justify-center items-center">
          <mat-spinner></mat-spinner>
        </div>
        } @else {
        <section class="flex flex-col max-w-full p-4 rounded-md">
          <table class="ctx-table--object max-w-md">
            <tr>
              <td>
                <h3>From:</h3>
              </td>
              <td>
                <span class="flex flex-row gap-1">
                  <h3 class="m-0 !font-bold">
                    {{ formGroup.get(['fromName'])?.value }}
                  </h3>
                  <h3 class="text-xs">
                    &lt;{{ formGroup.get(['fromEmail'])?.value }}&gt;
                  </h3>
                </span>
              </td>
            </tr>
            @if(formGroup.get(['cc'])?.value){
            <tr>
              <td>
                <h3>CC:</h3>
              </td>
              <td>
                <h3 class="m-0">{{ formGroup.get(['cc'])?.value }}</h3>
              </td>
            </tr>
            }

            <tr>
              <td>
                <h3>Subject:</h3>
              </td>
              <td>
                <h3 class="m-0">{{ formGroup.get(['subject'])?.value }}</h3>
              </td>
            </tr>
          </table>

          <section class="max-w-screen-md">
            <ctx-html-render [html]="html"></ctx-html-render>
          </section>
        </section>
        } }
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
