import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthnService,
  CryptlexApiService,
  DataCacheService,
  ResourceService,
} from 'ng-ui';
import {
  CtxFormMode,
  ResourceCreateFormData,
  ResourceDeleteFormData,
  ResourceName,
  ResourceUpdateFormData,
  reloadAfterSuccess,
} from 'utils';
import { OidcFormComponent } from './oidc-form/oidc-form.component';
import { OidcDisableComponent } from './oidc-disable/oidc-disable.component';
import { HttpResponse } from '@angular/common/http';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class OidcConfigurationService extends ResourceService {
  override resourceName: ResourceName = 'oidc-configuration';

  override get resourceApiPath() {
    return `/v3/accounts/${this.authn.accountId}/oidc-configuration`;
  }

  constructor(
    apiService: CryptlexApiService,
    dataCache: DataCacheService,
    private authn: AuthnService,
    private router: Router,
    private dialog: MatDialog,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCache, permissionsService);
  }

  /**
   * Launches a dialog that allows the user to configure SAML SSO
   */
  async launchUpdateOidcConfigurationDialog() {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      OidcFormComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource: this.dataCacheService.getCachedValues('oidc-configuration'),
          update: this.update.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }
  /**
   * Launches a dialog that allows the user to configure SAML SSO
   */
  async launchOidcConfigurationDialog() {
    const _dialog = this.dialog.open<any, ResourceCreateFormData>(
      OidcFormComponent,
      {
        data: {
          mode: CtxFormMode.Create,
          create: this.create.bind(this),
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  async launchOidcDisableDialog() {
    const _dialog = this.dialog.open<any, ResourceDeleteFormData>(
      OidcDisableComponent,
      {
        data: {
          resources: [
            this.dataCacheService.getCachedValues('oidc-configuration'),
          ],
          delete: this.delete.bind(this),
          deletionMessage: ``,
        },
      }
    );

    await reloadAfterSuccess(_dialog.afterClosed(), this.router);
  }

  override update(_id: string, body: any): Promise<HttpResponse<any>> {
    return super.update('', body);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override delete(_id: string): Promise<HttpResponse<any>> {
    return super.delete('');
  }
}
