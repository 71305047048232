import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResourceName, projectEnv, resourceToPermissionName } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private jwtHelper: JwtHelperService) {}

  allowedAction(resource: ResourceName, action: string) {
    /** for components like table used by all portals */
    if (projectEnv.get('projectName') !== 'admin-portal') {
      return true;
    }

    /** if scope is not present or empty */
    if (!this.jwtHelper.decodeToken()?.scope) {
      return false;
    }
    /**  permissions checked  for action on a resource   */
    if (this.jwtHelper.decodeToken().scope instanceof Array) {
      return this.jwtHelper
        .decodeToken()
        ?.scope.some((resourceString: string) => {
          const [resourceName, permission] = resourceString.split(':');

          return (
            /**  resources and their coressponding permissions are checked, based on which permissions are  given 
            resourceToPermissionName is used as there is inconsistency  in naming   
            */
            (resourceName === resourceToPermissionName[resource] &&
              permission.includes(action)) ||
            /** for admin roles with access to all resources, full: read and full:write is used, 
               hence dealt seperately.
                 */
            (resourceName === 'full' && permission.includes(action))
          );
        });
    } else {
      // single resource and permission doesn't come in an array hence handled seperately
      return Array(this.jwtHelper.decodeToken().scope).some(
        (resourceString: string) => {
          const [resourceName, permission] = resourceString.split(':');
          return (
            resourceName === resourceToPermissionName[resource] &&
            permission.includes(action)
          );
        }
      );
    }
  }
}
