import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

/** Gets parent page */
export const getParentPage = async (_state: RouterStateSnapshot) => {
  return _state.url.split('/').slice(0, -1).join('/');
};

// https://developer.mozilla.org/en-US/docs/Glossary/Base64
/**
 * Converts a base64 string to a Uint8Array of bytes
 * @param base64 The base64 encoded string to convert
 * @returns A Uint8Array containing the decoded bytes
 */
export function base64ToBytes(base64: string): Uint8Array {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => {
    return m.codePointAt(0) ?? 0;
  });
}

/**
 * Converts a Uint8Array of bytes to a base64 string
 * @param bytes The Uint8Array of bytes to convert
 * @returns The base64 encoded string
 */
export function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (byte: number) => {
    return String.fromCodePoint(byte);
  }).join('');
  return btoa(binString);
}

/**
 * Standard method for generating a string for a QueryParam used within the dashboard using
 * ActivatedRoute or similar. This is mostly used for setting filters and other data across pages.
 * @param key Parameter name
 * @param data Data to encode in QueryParam
 * @returns An QueryParam object to pass to router.navigate([],{queryParams})
 */
export function generateRouterQueryParams(
  key: string,
  data: unknown
): Record<string, string> {
  const queryParams: Record<string, string> = {};
  queryParams[key] = encodeURIComponent(
    bytesToBase64(new TextEncoder().encode(JSON.stringify(data)))
  );

  return queryParams;
}

/**
 * Standard method to decode data from router QueryParams
 */
export function getDataFromRouterQueryParams(
  key: string,
  route: ActivatedRoute
): unknown {
  if (route.snapshot.queryParams[key]) {
    const _parsedFilterArray = JSON.parse(
      new TextDecoder().decode(
        base64ToBytes(decodeURIComponent(route.snapshot.queryParams[key]))
      )
    );
    return _parsedFilterArray;
  } else {
    return undefined;
  }
}

/**
 * Used to reload the page after a dialog is closed.
 * @param afterClosed$
 * @param router
 * @param callback
 */
export async function reloadAfterSuccess(
  afterClosed$: Observable<any>,
  router: Router,
  callback?: () => void
) {
  const dialogResponse = await firstValueFrom(afterClosed$);
  if (dialogResponse) {
    if (callback) {
      callback();
    }
    await router.navigate([router.url]);
  }
}
