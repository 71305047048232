import { Component, Inject } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ResourceViewDialogData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';

@Component({
  selector: 'ctx-view-array-data',
  standalone: true,
  imports: [MatDialogModule, CtxButtonComponent],
  templateUrl: './view-array-data.component.html',
})
export class ViewArrayDataComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewArrayDataComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceViewDialogData
  ) {}
}
