<h2 mat-dialog-title>Update Company Details</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <mat-form-field>
        <mat-label>Notification Email</mat-label>
        <input type="text" formControlName="email" matInput required />
        <mat-error>{{ getControlError(formGroup, 'email') }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Account Alias</mat-label>
        <input type="text" formControlName="accountAlias" matInput required />
        <mat-error>{{ getControlError(formGroup, 'accountAlias') }}</mat-error>
        <mat-hint
          >Changing account alias will update the Cryptlex URLs for customer
          portal and reseller portal.</mat-hint
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input type="text" formControlName="company" matInput required />
        <mat-error>{{ getControlError(formGroup, 'company') }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
