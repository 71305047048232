<h2 mat-dialog-title>Redirecting to app.cryptlex.com</h2>

<mat-dialog-content>
  <p>
    The domain app-next.cryptlex.com was used for beta testing and is now no
    longer available. You will be automatically redirected to the new domain
    <a href="https://app.cryptlex.com">app.cryptlex.com</a>.
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button ctx-button--primary [disabled]="true" [loading]="true">
    Redirecting
  </button>
</mat-dialog-actions>
