import {
  AfterViewInit,
  Component,
  Input,
  afterNextRender,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtxButtonComponent } from '../button/button.component';
import { MatSidenav } from '@angular/material/sidenav';
import { CtxCompanyLogoComponent } from '../company-logo/company-logo.component';
import { AuthnService } from '../../_services/authn.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { setCustomFavicon, setCustomHue, setTitle } from 'utils';
import { ConfigurationService } from '../../_services/configuration.service';
import { Title } from '@angular/platform-browser';

import { RedirectDialogComponent } from '../redirect-modal/redirect-dialog.component';
import { DataCacheService } from '../../_services/data-cache.service';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'ctx-app-bar-base',
  standalone: true,
  imports: [CommonModule, CtxButtonComponent, CtxCompanyLogoComponent],
  templateUrl: './app-bar.component.html',
})
export class CtxAppBarComponent implements AfterViewInit {
  @Input() justifyBetween = true;
  @Input() justifyEnd = false;
  @Input() sidenav: MatSidenav;
  @Input() sideNavShow: boolean; //used here as to indicate no side-nav in login page nav
  @Input() trialExpiredComponent: any;
  constructor(
    public authn: AuthnService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private configService: ConfigurationService,
    private titleService: Title,
    private dataCache: DataCacheService
  ) {
    afterNextRender(() => {
      setCustomFavicon();
      setCustomHue();
      setTitle(titleService);

      if (authn.isAuthenticated && Bugsnag.isStarted()) {
        const accountId = this.dataCache?.getCachedValues('account')?.id;
        Bugsnag.setUser(accountId);
      }

      //TODO: To be removed once app-next is deleted
      if (this.authn.getHostname().startsWith('app-next')) {
        this.dialog.open<any>(RedirectDialogComponent, {
          disableClose: true,
          closeOnNavigation: false,
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.authn.isTrialExpired && this.trialExpiredComponent) {
      this.dialog.open<
        any,
        {
          message: string;
        }
      >(this.trialExpiredComponent, {
        data: {
          message:
            'Your trial has expired. Please upgrade your plan or contact support.',
        },
        disableClose: true,
        closeOnNavigation: false,
      });
    }
  }
  /** Returns true if toggle button is to be shown. */
  get showToggleButton(): boolean {
    // TODO
    return !!this.sidenav;
  }
}
