import { environment, projectEnv } from 'utils';
import { jwtOptions } from '../_providers';
import { PersistentStore } from '../_services/persistent-store.service';

export const setBaseApiForPortals = () => {
  let apiUrl = localStorage.getItem('apiBaseUrl');
  let releaseServerUrl = localStorage.getItem('releaseServerBaseUrl');
  const euApiUrl = localStorage.getItem('apiBaseUrlEu');
  const euReleaseServerUrl = localStorage.getItem('releaseServerBaseUrlEu');

  if (
    projectEnv.get('projectName') === 'admin-portal' &&
    PersistentStore.getRegion() &&
    PersistentStore.getRegion() === 'eu'
  ) {
    apiUrl = euApiUrl;
    releaseServerUrl = euReleaseServerUrl;
  }

  if (apiUrl) {
    environment.set('apiBaseUrl', apiUrl);
    jwtOptions.addDomainToAllowedDomains(new URL(apiUrl).hostname);
  }
  if (releaseServerUrl) {
    projectEnv.set('releaseServerBaseUrl', releaseServerUrl);
  }
};
