import { Directive } from '@angular/core';
import { CdkStepLabel } from '@angular/cdk/stepper';

/**
 * It is completely unclear why the CDK and Angular Material uses this directive.
 * Going through CdkStep, MatStep, CdkStepLabel and MatStepLabel does not make it clear where this is used.
 * It is overriden, or even defined just to be consistent with MatStep.
 */
@Directive({
  selector: '[ctxStepLabel]',
  standalone: true,
})
export class CtxStepLabelDirective extends CdkStepLabel {}
