<img
  [src]="logoUrl"
  alt=""
  [height]="height"
  aria-hidden="true"
  class="block opacity-90 h-6"
  [ngClass]="{
    'brightness-[250] grayscale': theme.isDarkMode,
    'grayscale': isLogoCustom
  }"
/>
