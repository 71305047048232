import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { CtxEnvironmentName, environment, getDashboardVersion } from 'utils';

/**
 * Initializes Bugsnag with the default configuration and user information.
 */
export function initializeBugsnag(
  environmentName: CtxEnvironmentName,
  applicationVersion: string
) {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      apiKey: 'b66e6be65194c699139f9107f8ba2e64',
      appVersion: applicationVersion,
      autoTrackSessions: false,
      releaseStage: environmentName,
      enabledReleaseStages: ['development', 'production', 'on-premise'],
      maxEvents: 50,
      onError: (bugsnagEvent) => {
        if (
          bugsnagEvent.errors.length &&
          bugsnagEvent.errors[0].errorMessage?.indexOf('Loading chunk') > -1
        ) {
          window.location.reload();
          return false;
        }
        if (bugsnagEvent.app.releaseStage === 'on-premise') {
          return false;
        }
        return true;
      },
    });
  }
}

/**
 * @returns BugsnagErrorHandler instance.
 */
export function bugsnagErrorHandlerFactory() {
  initializeBugsnag(environment?.get('name'), getDashboardVersion());
  return new BugsnagErrorHandler();
}
