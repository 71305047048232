import { Component } from '@angular/core';

import { CTX_ADMIN_ROUTES, CtxLayoutComponent } from 'ng-ui';
import { RouterModule } from '@angular/router';
import { CtxDashboardPageService } from './page.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogMessageComponent } from 'admin-portal/common/dialog-message/dialog-message.component';
@Component({
  selector: 'ctx-page-layout',
  standalone: true,
  imports: [CtxLayoutComponent, RouterModule, MatProgressSpinnerModule],
  templateUrl: './page-layout.component.html',
})
export class CtxPageLayoutComponent {
  constructor(public pageService: CtxDashboardPageService) {}

  routes = CTX_ADMIN_ROUTES;
  trialExpiredComponent = DialogMessageComponent;
}
