import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { ResourceCreateFormData, ResourceUpdateDynamicFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxDynamicForm } from 'ng-ui';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CtxButtonGroupComponent } from 'ng-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'ctx-metadata-form',
  templateUrl: './metadata-form.component.html',
  standalone: true,
  imports: [
    MatInputModule,
    CtxButtonComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    CtxButtonGroupComponent,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class CtxMetadataFormComponent implements OnInit {
  @Input() dialogData: ResourceCreateFormData | ResourceUpdateDynamicFormData;
  @Input() parentForm: CtxDynamicForm;
  @Input() hintText: string;

  formArray: FormArray;
  ngOnInit(): void {
    this.formArray = this.parentForm.formGroup.get('metadata') as FormArray;
  }

  /**
   * Returns a FormGroup that is created on runtime. The values that we get from the API for metadata
   * have the 'id' property and other properties that we do not define in this function. This helps
   * us discern between FormGroup instances created using the API response and FormGroup instances created using
   * user input on runtime.
   */
  _createNewMetadataField(
    key = '',
    value = '',
    viewPermissions = [],
    required = false
  ): FormGroup {
    return this.parentForm.fb.group({
      key: [key, Validators.required],
      value: [value, Validators.required],
      viewPermissions: [viewPermissions],
      required,
    });
  }

  /**
   * Handles deletion of metedata
   */
  async handleMetadataDeletion(
    dialogData: ResourceCreateFormData | ResourceUpdateDynamicFormData,
    metadataFields: FormArray,
    index: number
  ): Promise<void> {
    const _metadata = metadataFields.at(index);

    this.parentForm.asyncRequests.push(async () => {
      if (
        dialogData.mode === 'Update' &&
        dialogData.deleteMetadata &&
        _metadata?.getRawValue().id
      ) {
        return dialogData.deleteMetadata(_metadata.getRawValue().id);
      } else {
        return Promise.resolve();
      }
    });

    this.parentForm.removeFormArrayField(metadataFields, index);
  }

  isMetadataFieldOnApi(metadataField: AbstractControl): boolean {
    return metadataField.getRawValue()?.id;
  }
}
