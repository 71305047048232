<h2 mat-dialog-title>Update Assets</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <p>
      Update the logo and favicon displayed on the customer facing web
      applications.
    </p>
    <section class="flex flex-col gap-4">
      <!-- Logo Url -->
      <mat-form-field>
        <mat-label>Logo URL</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="logoUrl"
          matInput
        />
        <mat-hint
          >Please ensure the logo has a transparent background. PNG, and SVG
          formats are supported.</mat-hint
        >
        <mat-error>{{ getControlError(formGroup, 'logoUrl') }}</mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field>
        <mat-label>Favicon URL</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="faviconUrl"
          matInput
        />
        <mat-hint
          >Please ensure that the Favicon URL points to an ICO, or PNG format
          file.</mat-hint
        >
        <mat-error>{{ getControlError(formGroup, 'faviconUrl') }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>
  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
