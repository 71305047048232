<section
  class="flex flex-row items-center justify-center py-1 px-2 ctx-alert gap-2 m-1 opacity-80 flex-wrap"
  [ngClass]="{
    'bg-warning-1 text-warning-9': alertLevel === 'warning',
    'bg-success-1 text-success-9': alertLevel === 'success'
  }"
>
  <hlm-icon
    [name]="alertLevel === 'success' ? 'SUCCESS' : 'WARNING'"
    inline
  ></hlm-icon>
  <span>
    <ng-content></ng-content>
  </span>
</section>
