import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { PaymentCardService } from '../card.service';
import { CtxButtonComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-card-delete-form',
  templateUrl: './card-delete-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class CardDeleteFormComponent extends CtxForm {
  constructor(
    private paymentCardService: PaymentCardService,
    public dialogRef: MatDialogRef<CardDeleteFormComponent>
  ) {
    super();
  }

  override async submit(): Promise<void> {
    try {
      this.setState('submit', true);
      // Only one card is present.
      await this.paymentCardService.delete(this.paymentCardService.card.id);
      this.setCustomApiMessage({
        alertLevel: 'success',
        text: 'Card deleted successfully.',
      });
      await this.closeDialogAfterTimeout(this.dialogRef, true);
    } catch (error) {
      this.setApiMessage(error);
    }
    this.setState('submit', false);
  }
}
