<h2 mat-dialog-title>{{ dialogData.mode }} Tag</h2>
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  @if (showApiMessage) {
  <ctx-alert [alertLevel]="apiMessage.alertLevel">{{
    apiMessage.text
  }}</ctx-alert>
  }
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea
          rows="1"
          formControlName="description"
          matInput
          required
        ></textarea>
        <mat-error>{{ getControlError(formGroup, 'description') }}</mat-error>
      </mat-form-field>

      <section class="flex flex-col items-center">
        <p>Preview:</p>
        <ctx-tag [title]="formGroup.get('description')?.value">{{
          formGroup.get('name')?.value
        }}</ctx-tag>
      </section>
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
