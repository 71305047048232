import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  CtxFormMode,
  ResourceCreateFormData,
  SegmentCreationFormData,
} from 'utils';

import { MatInputModule } from '@angular/material/input';
import { CtxButtonComponent } from '../../_components/button/button.component';
import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxForm } from '../models/form.model';
import {
  DataCacheService,
  getLabelForFilter,
} from '../../_services/data-cache.service';

@Component({
  selector: 'ctx-create-segment-form',
  standalone: true,
  imports: [
    MatInputModule,
    CtxButtonComponent,
    MatDialogModule,
    CtxAlertComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './create-segment-form.component.html',
})
export class CreateSegmentFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SegmentCreationFormData,
    public dialogRef: MatDialogRef<CreateSegmentFormComponent>,
    private fb: FormBuilder,
    public dataCache: DataCacheService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      resource: this.dialogData.resource,
      filters: [this.dialogData.filters],
    });
  }

  get _data(): ResourceCreateFormData {
    return { create: this.dialogData.create, mode: CtxFormMode.Create };
  }

  getLabelForFilter = getLabelForFilter;
}
