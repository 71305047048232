/**
 * TODO This could be insecure since it is not guaranteed that Webpack will tree-shake the whole JSON
 * and consequently, the whole package.json can be available in the frontend application.
 *
 * This poses security and size concerns.
 */
import packageJson from 'package.json';

/**
 * @returns Dashboard version from package.json
 */
export function getDashboardVersion(): string {
  return packageJson.version;
}
