import { CtxProjectName } from 'utils';
import { getPropertyForId } from './objects';
import { convertCamelCaseToTitleCase } from './string';

export const AUDIT_LOG_NAMES = [
  'account',
  'activation',
  'automatedEmail',
  'card',
  'featureFlag',
  'license',
  'licenseTemplate',
  'maintenancePolicy',
  'organization',
  'personalAccessToken',
  'product',
  'productVersion',
  'release',
  'releaseChannel',
  'releaseFile',
  'releasePlatform',
  'reseller',
  'role',
  'samlConfiguration',
  'segment',
  'sendingDomain',
  'tag',
  'trialActivation',
  'trialPolicy',
  'user',
  'userGroup',
  'webhook',
] as const;

/** Resource Name should ALWAYS be in singular form */
export const RESOURCE_NAMES = [
  'access-token',
  'account',
  'activation',
  'activation-log',
  'admin-role',
  'audit-log',
  'automated-email',
  'automated-email-event',
  'automated-email-event-log',
  'address',
  'card',
  'feature-flag',
  'invoice',
  'license',
  'license-template',
  'maintenance-policy',
  'organization',
  'plan',
  'product',
  'product-version',
  'profile',
  'release',
  'release-channel',
  'release-file',
  'release-platform',
  'report',
  'role',
  'role-claim',
  'saml-configuration',
  'segment',
  'sending-domain',
  'setting',
  'tag',
  'team-member',
  'trial',
  'trial-policy',
  'user',
  'user-group',
  'webhook',
  'webhook-event-log',
  'webhook-trigger',
  'reseller',
  'oidc-configuration',
  'organization-claim',
  'reseller-claim',
] as const;

export type ResourceName = (typeof RESOURCE_NAMES)[number];

const RESOURCE_DISPLAY_NAMES: Record<string, string> = {
  'id': 'ID',
  'createdAt': 'Creation Date',
  'scopes': 'Permissions',
  'updatedAt': 'Last Updated',
  'expiresAt': 'Expiration Date',
  'lastSeenAt': 'Last Seen',
  'os': 'OS',
  'osVersion': 'OS Version',
  'key': 'License Key',
  'vmName': 'VM Name',
  'container': 'Container',
  'allowedIpRange': 'Allowed IP Range',
  'allowedIpRanges': 'Allowed IP Ranges',
  'allowedIpAddresses': 'Allowed IP Addresses',
  'disallowedIpAddresses': 'Disallowed IP Addresses',
  'allowVmActivation': 'Allow VM Activation',
  'disableGeoLocation': 'Disable Geolocation',
  'user.id': 'User ID',
  'userId': 'User',
  'productId': 'Product',
  'downloads': 'Total Downloads',
  'claims': 'Permissions',
  'googleSsoEnabled': 'Google Login Enabled',
  'lastAttemptedAt': 'Last Attempt Date',
  'url': 'URL',
  'trialPolicy.name': 'Trial Policy Name',
  'licensePolicy.name': 'License Template Name',
  'licensePolicy': 'License Template',
  'eventLog': 'Audit Log',
  'cc': 'CC Recepients',
  'bcc': 'BCC Recepients',
  'ipAddress': 'IP Address',
  'resellerId': 'Reseller',
  'productVersionId': 'Product Version',
  'releaseId': 'Release',
  'maintenancePolicyId': 'Maintenance Policy',
  'webhookId': 'Webhook',
  'automatedEmailId': 'Automated Email',
  'location.countryName': 'Country',
  'location.ipAddress': 'IP Address',
  'location.countryCode': 'Country Code',
  'location.latitude': 'Latitude',
  'location.longitude': 'Longitude',
  'location.regionCode': 'Region Code',
  'location.regionName': 'Region Name',
  'location.timeZone': 'Time Zone',
  'location.zipCode': 'ZIP Code',
  'location.city': 'City',
  'organizationId': 'Organization',
  'address.country': 'Country',
  'address.addressLine1': 'Address Line 1',
  'address.addressLine2': 'Address Line 2',
  'responseStatusCode': 'HTTP Status Code',
  'resourceId': 'Resource ID',
  'Sso': 'SAML SSO 2.0',
  'reseller.name': 'Reseller',
  'organization.name': 'Organization',
  'sendingDomain': 'Email Sending Domain',
  'expiresIn': 'Expires In',
};
const ANALYTICS_RESOURCES = [
  'stats',
  'licenses',
  'activations',
  'activations/os',
  'activations/geo-location',
  'activations/vm-name',
  'trial-activations',
  'trial-activations/os',
  'trial-activations/geo-location',
] as const;

export type AnalyticsResource = (typeof ANALYTICS_RESOURCES)[number];

export const RESOURCE_PERMISSION_NAMES = [
  'account',
  'activation',
  'analytics',
  'automatedEmail',
  'automatedEmailEventLog',
  'eventLog',
  'featureFlag',
  'invoice',
  'license',
  'licensePolicy',
  'maintenancePolicy',
  'organization',
  'paymentMethod',
  'personalAccessToken',
  'product',
  'productVersion',
  'release',
  'reseller',
  'role',
  'segment',
  'sendingDomain',
  'tag',
  'trialActivation',
  'trialPolicy',
  'user',
  'webhook',
  'webhookEventLog',
  'profile', // added for completeteness
  'address',
] as const;

export type ResourcePermissionName = (typeof RESOURCE_PERMISSION_NAMES)[number];

export const resourceToPermissionName: Record<
  ResourceName,
  ResourcePermissionName
> = {
  'access-token': 'personalAccessToken',
  'account': 'account',
  'activation': 'activation',
  'activation-log': 'activation',
  'admin-role': 'role',
  'audit-log': 'eventLog',
  'automated-email': 'automatedEmail',
  'automated-email-event': 'automatedEmail',
  'automated-email-event-log': 'automatedEmailEventLog',
  'card': 'paymentMethod',
  'feature-flag': 'featureFlag',
  'invoice': 'invoice',
  'license': 'license',
  'license-template': 'licensePolicy',
  'maintenance-policy': 'maintenancePolicy',
  'organization': 'organization',
  'plan': 'account',
  'product': 'product',
  'product-version': 'productVersion',
  'profile': 'profile',
  'release': 'release',
  'release-channel': 'release',
  'release-file': 'release',
  'release-platform': 'release',
  'report': 'analytics',
  'role': 'role',
  'role-claim': 'role',
  'saml-configuration': 'account',
  'segment': 'segment',
  'sending-domain': 'sendingDomain',
  'setting': 'account',
  'tag': 'tag',
  'team-member': 'user',
  'trial': 'trialActivation',
  'trial-policy': 'trialPolicy',
  'user': 'user',
  'user-group': 'organization',
  'webhook': 'webhook',
  'webhook-event-log': 'webhookEventLog',
  'webhook-trigger': 'webhook',
  'reseller': 'reseller',
  'oidc-configuration': 'account',
  'organization-claim': 'role',
  'reseller-claim': 'role',
  'address': 'paymentMethod',
};

// Display names specific to customer and reseller portal
const OTHER_PORTALS_DISPLAY_NAME: Record<string, string> = {
  'product.displayName': 'Product',
  'productVersion.displayName': 'Product Version',
  'licenseId': 'License ID',
};
/**
 * @returns Name for resource id, if present in DataCache
 */
export function getNameForId(resource: any[], id: string): string {
  return getPropertyForId(resource, id, 'name');
}

/**
 * This function generates the text to display in the UI for resources.
 *
 * @param resourceName Property or ID that represents the resource.
 */
export function getResourceDisplayName(
  resourceName: string,
  portal: CtxProjectName
) {
  if (portal !== 'admin-portal' && resourceName in OTHER_PORTALS_DISPLAY_NAME) {
    return OTHER_PORTALS_DISPLAY_NAME[resourceName];
  } else if (resourceName in RESOURCE_DISPLAY_NAMES) {
    return RESOURCE_DISPLAY_NAMES[resourceName];
  } else {
    return convertCamelCaseToTitleCase(resourceName);
  }
}

/**
 * Convert plural resource name string to singular. Ex: Products => Product, Policies => Policy
 */
export function singularizeResourceName(resourceName: string): string {
  const EW_IES_REGEX = /ies$/g;
  const EW_S_REGEX = /s$/g;

  switch (true) {
    case EW_IES_REGEX.test(resourceName):
      return resourceName.replace(EW_IES_REGEX, 'y');
    case EW_S_REGEX.test(resourceName):
      return resourceName.replace(EW_S_REGEX, '');
    default:
      return resourceName;
  }
}

/** Return ResourceName in kebab-case pluralized */
export function pluralizeResourceName(resourceName: string) {
  if (/y$/.test(resourceName)) {
    return resourceName.replace(/y$/, 'ies');
  } else {
    return resourceName.concat('s');
  }
}

/**
 * Convert kebab-case string to Title Case
 * WARNING: Might not be a complete solution
 */
export function convertKebabCaseToTitleCase(str: string): string {
  return (
    str
      .split('-')
      .map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      })
      .join(' ')
      // TODO performance optimization
      .replace('Sdk', 'SDK')
  );
}
