import { environment, projectEnv } from 'utils';
import { ScriptService } from '../_services/script.service';

/** True if Recaptcha is available */
export function isRecaptchaAvailable(): boolean {
  return environment.get('name') !== 'on-premise';
}

/** Function will load the recaptcha script and return recaptcha token*/
export async function getRecaptchaToken(): Promise<string> {
  if (isRecaptchaAvailable() && (window as any).grecaptcha) {
    return await (window as any).grecaptcha.execute(
      projectEnv.get('recaptchaSiteKey'),
      {
        action: 'submit',
      }
    );
  } else {
    return Promise.resolve('');
  }
}

/**  */
export async function loadRecaptcha(
  scriptService: ScriptService
): Promise<boolean> {
  if (isRecaptchaAvailable()) {
    return await scriptService.loadRecaptcha(
      projectEnv.get('recaptchaSiteKey')
    );
  }
  return true;
}
