import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName, navigateWithFilters } from 'utils';
import { FilterableProperties } from 'utils';
import { CtxFormMode, ResourceUpdateFormData } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CTX_ADMIN_ROUTES, CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { AutomatedEmailFormComponent } from './automated-email-form/automated-email-form.component';
import { SendTestEmailComponent } from './send-test-email/send-test-email.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({ providedIn: 'root' })
export class AutomatedEmailService extends ResourceTableService {
  override resourceApiPath = '/v3/automated-emails';
  override resourceName: ResourceName = 'automated-email';
  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override tableEmptyMessage =
    'Automated emails are linked to Products and are triggered when a certain task is performed on the Product.';

  override selections = new SelectionModel<any>(true, []);

  override creationComponent = AutomatedEmailFormComponent;
  override updationComponent = AutomatedEmailFormComponent;

  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Send Test Email',
        callback: this.launchTestEmailDialog.bind(this),
        icon: 'SEND',
      },
      {
        label: 'View Logs',
        callback: this.navigateToAutomatedEmailLogs.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override columns: TableColumn[] = [
    { property: 'name', displayType: 'text' },
    { property: 'fromName', displayType: 'text' },
    { property: 'fromEmail', displayType: 'text' },
    { property: 'cc', displayType: 'text' },
    { property: 'bcc', displayType: 'text' },
    { property: 'subject', displayType: 'text' },
    { property: 'replyTo', displayType: 'text' },
    { property: 'event', displayType: 'text' },
    //{ property: 'body', displayType: 'text' },
    { property: 'enabled', displayType: 'flag' },
    { property: 'custom', displayType: 'flag' },
    { property: 'sent', displayType: 'number' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];

  _columnIdsToDisplay: string[] = [
    'name',
    'event',
    'fromEmail',
    'subject',
    'enabled',
    'sent',
  ];

  override filterableProperties: FilterableProperties = {};

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }

  async navigateToAutomatedEmailLogs(automatedEmail: any) {
    await navigateWithFilters(
      [
        {
          property: 'automatedEmailId',
          value: automatedEmail.id,
          operator: 'eq',
        },
      ],
      this.router,
      CTX_ADMIN_ROUTES.getUrl('automated-email-event-logs')
    );
  }
  sendTestEmail(id: string, body: any) {
    return this.apiService.post(
      `${this.resourceApiPath}/${id}/send-test-email`,
      body
    );
  }

  fetchEmailPreview(
    subject: string,
    body: string,
    event: string,
    custom: boolean
  ) {
    return this.apiService.post(`${this.resourceApiPath}/preview`, {
      subject,
      body,
      event,
      custom,
    });
  }
  async launchTestEmailDialog(automatedEmail: any) {
    const _dialog = this.dialog.open<any, ResourceUpdateFormData>(
      SendTestEmailComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          update: this.sendTestEmail.bind(this),
          resource: automatedEmail,
        },
      }
    );

    await this.handleDialogClose(_dialog);
  }
}
