<h2 mat-dialog-title>
  Export {{ getResourceDisplayName(dialogData.resourceName, projectName) }}(s)
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<mat-dialog-content>
  <p>
    Pressing the 'Export' button will export
    {{ dialogData.tableRowCount }}
    {{ resourceString }}. @if ( dialogData.filters && dialogData.filters.length
    && dialogData.filters.length > 0 ) {
    <span class="">
      The export will include resources that match the following filters:
      <ul>
        @for (filter of dialogData.filters; track filter) {
        <li>
          {{ getLabelForFilter(filter, dataCache) }}
        </li>
        }
      </ul>
    </span>
    }
    <br />
    Depending on the number of
    {{ resourceString }}, the export process may take up to 10 minutes.
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button ctx-button--secondary [disabled]="formStates.disabled" matDialogClose>
    Cancel
  </button>
  <button
    ctx-button--primary
    (click)="submit()"
    [disabled]="formStates.disabled"
    [loading]="formStates.submit"
  >
    Export
  </button>
</mat-dialog-actions>
