<span
  class="ctx-tag rounded-l-lg rounded-r-none text-xs items-center border-2 px-2 py-1 font-bold border-solid mr-1 select-none align-middle"
>
  <!-- Hole -->
  <span
    class="ctx-tag__hole inline-block w-2 h-2 rounded-full align-center mr-2"
  ></span>

  <!-- Content -->
  <span>
    <ng-content></ng-content>
  </span>
</span>
