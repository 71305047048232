import posthog from 'posthog-js';
import { environment, projectEnv } from 'utils';

/** Could this be a decorator? Would it be better? */
export function isPosthogAvailable() {
  return (
    environment.get('name') === 'production' &&
    projectEnv.get('posthogApiKey') !== '' &&
    projectEnv.get('posthogApiHost') !== ''
  );
}
/**
 * Initializes Posthog with the the default configuration.
 */
export function initializePosthog({
  apiKey,
  apiHost,
}: {
  apiKey: string;
  apiHost: string;
}) {
  if (isPosthogAvailable()) {
    posthog.init(apiKey, {
      api_host: apiHost,
    });
  }
}

/**
 * Sets the userId and Email for Posthog.
 */
export function identifyPosthogUser(config: { id: string }) {
  if (isPosthogAvailable()) {
    posthog.identify(config.id);
  }
}

/**
 * Sets the company id and name for Posthog.
 * @param config
 */
export function identifyPosthogGroup(config: {
  id: string;
  name: string;
  plan: string;
}) {
  if (isPosthogAvailable()) {
    posthog.group('company', config.id, {
      name: config.name,
      plan: config.plan,
    });
    posthog.people.set_once({ company: config.name });
  }
}

/**
 * Generic function for capturing events in Posthog.
 * @param eventName
 */
export function posthogTrack(
  eventName: string,
  properties?: Record<string, string | undefined>
) {
  if (isPosthogAvailable()) {
    posthog.capture(eventName, properties);
  }
}

/**
 * https://github.com/PostHog/posthog-js/issues/323
 * Reset PostHog user is not working as expected
 * Throws unhandled exception
 */
export function resetPosthog() {
  try {
    if (isPosthogAvailable()) {
      posthog.reset();
    }
  } catch (error) {
    /** Handled by Bugsnag */
    null;
  }
}
