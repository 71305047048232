import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import {
  ResourceCreateFormData,
  ResourcePatchFormData,
  ResourceUpdateDynamicFormData,
  ResourceUpdateFormData,
} from 'utils';
import { getVisibilityIcon } from 'utils';

import { CtxForm } from './form.model';
import { MatDialogRef } from '@angular/material/dialog';
import { CtxStepperComponent } from 'libs/ng-ui/src/lib/_components/stepper/stepper/stepper.component';

export abstract class CtxDynamicForm extends CtxForm {
  constructor(public fb: FormBuilder) {
    super();
  }

  /** Property that holds the asynchronous tasks that have to be done in the form */
  asyncRequests: Array<(id: string) => Promise<any>> = [];

  /**
   * Returns the correct initial value for a FormArray based on the CtxFormMode
   * @param property Property name in API response. Can be written in dot notation.
   * @param dialogData dialogData object used to get CtxFormMode
   */
  getInitialFormArrayValue(
    property: string,
    dialogData:
      | ResourceCreateFormData
      | ResourceUpdateFormData
      | ResourceUpdateDynamicFormData
  ) {
    const array = this.getInitialControlValue(property, dialogData);

    return this.getFormGroupArray(array);
  }

  /**
   * @returns An array of FormGroup instances
   */
  getFormGroupArray(array: any[]): FormGroup[] {
    if (array && Array.isArray(array)) {
      return array.map((value) => {
        const viewPermissions = Array.isArray(value?.viewPermissions)
          ? value?.viewPermissions
          : null;
        if (viewPermissions) {
          value.viewPermissions = null;
        }
        const group = this.fb.group(value);

        if (viewPermissions) {
          group.get('viewPermissions')?.setValue(viewPermissions);
          // This revives the original row, otherwise viewpermssions are not displayed for this row if modal closed without submiting
          value.viewPermissions = viewPermissions;
        }
        if ('id' in value && value.id) {
          group.get('totalUses')?.disable();
          group.get('grossUses')?.disable();
          group.get('floating')?.disable();
        }
        return group;
      });
    } else {
      return [];
    }
  }

  /**
   * Pushes the formGroup parameter to the FormArray instance.
   * @param formArray
   * @param formGroup
   */
  addFormArrayField(formArray: FormArray, formGroup: FormGroup): void {
    formArray.push(formGroup);
  }

  /**
   * Removes the FormGroup instance from the formArray parameter at the index
   * @param formArray FormArray instance
   * @param index Index at which FormGroup is located
   */
  removeFormArrayField(formArray: FormArray, index: number): void {
    formArray.removeAt(index);
  }

  /** Toggles visibility of the field. Used for Meter Attributes and Metadata */
  toggleVisibility(field: AbstractControl<any, any>): void {
    field.patchValue({ visible: !field.value.visible });
  }

  /** Returns visibility icon to be used with the visibility button of the field. Used for Meter Attributes and Metadata */
  getVisibilityButtonIcon = getVisibilityIcon;

  /** Returns a label that describes the action of the visibility button of the field. Used for Meter Attributes and Metadata */
  getVisibilityButtonTitle(field: AbstractControl<any, any>) {
    return field.value.visible
      ? 'Press to hide this field in the Customer Portal'
      : 'Press to show this field in the Customer Portal';
  }

  /** Returns a label for the metadata delete field */
  getMetadataDeleteButtonTitle(metadataField: AbstractControl<any, any>) {
    if (metadataField.value.id) {
      return 'WARNING: Deleting a Metadata field that is being used in your application can cause unexpected behvaiour.';
    } else {
      return 'Remove Metadata field.';
    }
  }

  /**  Returns a label for the meter attribute field */
  getMeterDeleteButtonTitle(meterAttributeField: AbstractControl<any, any>) {
    if (meterAttributeField.value.id) {
      return 'WARNING: Deleting a Meter Attribute that is being used in your application can cause unexpected behvaiour.';
    } else {
      return 'Remove Meter Attribute.';
    }
  }

  override async submit(
    formValue: any,
    dialogData:
      | ResourceCreateFormData
      | ResourceUpdateFormData
      | ResourcePatchFormData,
    dialogRef: MatDialogRef<any, any>,
    ctxTab?: CtxStepperComponent
  ): Promise<void> {
    if (this.formGroup.invalid) {
      ctxTab ? ctxTab.handleInvalidForm() : null;
      return;
    } else {
      reqBlock: try {
        this._startFormSubmission(dialogRef);

        // Run the general update/create Promise to the requests array
        // This prevents the form from having a stale state if the form has been updated after an error
        const response = await this.getSubmissionHandler(formValue, dialogData);

        await this._handleAsyncRequests(response.body?.id);
        // message on successful submission off form
        await this._afterSubmissionSuccess({ ok: true }, dialogRef);
      } catch (errorResponse) {
        // TODO Handle errors for when atleast one request passes.
        this._handleFormError(errorResponse, dialogRef);
        break reqBlock;
      }
      this._endFormSubmission();
    }
  }

  /** Handles the async requests in the asyncRequests array */
  protected async _handleAsyncRequests(id: string) {
    // Copy the asyncRequests array to avoid mutating the original array
    const requests = [...this.asyncRequests];

    // If there are less than 20 requests, run them all at once
    if (requests.length < 20) {
      await Promise.all(
        requests.map((request) => {
          return request(id);
        })
      );
    } else {
      // Sequentially await all requests in Async Requests
      for (const request of requests) {
        await request(id);
      }
    }
  }
}
