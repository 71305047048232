<h2 mat-dialog-title>Downgrade To Free Plan</h2>

<mat-dialog-content>
  Your trial is still active, giving you access to all the features for the next
  {{ data.daysLeft }} days at no cost. Downgrading to the free plan will limit
  the access to features. Are you sure you want to proceed?
</mat-dialog-content>

<mat-dialog-actions>
  <button ctx-button--primary (click)="closeDialog(); data.planSubmit()">
    Yes
  </button>
  <button ctx-button--primary (click)="closeDialog()">No</button>
</mat-dialog-actions>
