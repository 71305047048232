import { Injectable, OnDestroy } from '@angular/core';
import {
  EventType,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class RouterEventService implements OnDestroy {
  private navigationStartFunctions = new Set<VoidFunction>();
  private navigationEndFunctions = new Set<VoidFunction>();

  /** The updated value of the current url */
  currentUrl = this.router.routerState.snapshot.url;

  /**
   * Is false if router is not executing otherwise it is true.
   */
  isPageLoading = false;

  /** Subscription to the router.events Observable */
  private routerEvents: Subscription;

  constructor(private router: Router) {
    this.routerEvents = router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        this.isPageLoading = true;

        this.currentUrl = this.router.routerState.snapshot.url;
        // Call the functions defined by addEvent(fn, EventType.NavigationStart)
        for (const navigationStartFunction of this.navigationStartFunctions) {
          await navigationStartFunction();
        }
      } else if (event instanceof NavigationEnd) {
        this.isPageLoading = false;

        // This line serves as our own subscription to the URL changes. Rather than duplicating it across components,
        // we can use this as our source of truth.
        this.currentUrl = this.router.routerState.snapshot.url;

        // Call the functions defined by addEvent(fn, EventType.NavigationEnd)
        for (const navigationEndFunction of this.navigationEndFunctions) {
          await navigationEndFunction();
        }
      }
    });
  }

  /**
   * Hooks a function `voidFunction` to the router EventType defined by the eventType parameter
   * @param voidFunction Function to execute, usually passed with bind(this)
   * @param eventType EventType enum
   */
  addEvent(voidFunction: VoidFunction, eventType: EventType) {
    switch (eventType) {
      case EventType.NavigationStart:
        this.navigationStartFunctions.add(voidFunction);
        break;
      case EventType.NavigationEnd:
        this.navigationEndFunctions.add(voidFunction);
        break;
      default:
        break;
    }
  }

  /**
   * Un-hooks a function `voidFunction` to the router EventType defined by the eventType parameter
   * @param voidFunction Function to execute, usually passed with bind(this)
   * @param eventType EventType enum
   */
  removeEvent(voidFunction: VoidFunction, eventType: EventType) {
    switch (eventType) {
      case EventType.NavigationStart:
        this.navigationStartFunctions.delete(voidFunction);
        break;
      case EventType.NavigationEnd:
        this.navigationEndFunctions.delete(voidFunction);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    /** Unsubscribe from the router.events Observable */
    this.routerEvents.unsubscribe();
  }
}
