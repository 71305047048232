import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CtxButtonComponent } from 'ng-ui';

/** Toggle for MatDatepicker which is used in MatSuffix of a MatFormField */
@Component({
  selector: 'ctx-datepicker-toggle',
  standalone: true,
  imports: [CommonModule, MatInputModule, CtxButtonComponent],
  templateUrl: './datepicker-toggle.component.html',
})
export class CtxDatepickerToggleComponent {
  @Input() for: MatDatepicker<any>;
}
