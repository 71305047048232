import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import {
  ResourceExportFormData,
  getResourceDisplayName,
  projectEnv,
} from 'utils';
import { CtxButtonComponent } from '../button/button.component';
import { CtxAlertComponent } from '../alert/alert.component';
import { CtxForm } from '../../_forms/models/form.model';
import {
  DataCacheService,
  getLabelForFilter,
} from '../../_services/data-cache.service';

@Component({
  selector: 'ctx-export',
  templateUrl: './export.component.html',
  standalone: true,
  imports: [CtxButtonComponent, MatDialogModule, CtxAlertComponent],
})
export class CtxExportComponent extends CtxForm {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceExportFormData,
    public dialogRef: MatDialogRef<CtxExportComponent>,
    public dataCache: DataCacheService
  ) {
    super();
  }
  projectName = projectEnv.get('projectName');

  getResourceDisplayName = getResourceDisplayName;
  getLabelForFilter = getLabelForFilter;
  override async submit() {
    try {
      this.resetApiMessage();
      this.setState('submit', true);
      const response = await this.dialogData.export(this.dialogData.query);
      // Create CSV and download using saveAs()
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      saveAs(
        blob,
        getResourceDisplayName(
          this.dialogData.resourceName,
          this.projectName
        ).concat('_Export_', Date.now().toString(), '.csv')
      );

      this.setCustomApiMessage({
        text: `Exported ${getResourceDisplayName(
          this.dialogData.resourceName,
          this.projectName
        )}(s) as CSV successfully.`,
        alertLevel: 'success',
      });
      await this.closeDialogAfterTimeout(this.dialogRef, true);
    } catch (errorResponse) {
      this.setApiMessage(errorResponse);
    }
    this.setState('submit', false);
  }

  get resourceString() {
    return `${getResourceDisplayName(
      this.dialogData.resourceName,
      this.projectName
    )}${this.dialogData.tableRowCount > 1 ? 's' : ''}`;
  }
}
