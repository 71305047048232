import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { ButtonSizes, CtxIcons, ThemeColors, ThemeSizes } from 'utils';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AnimationService } from '../../_services/animation.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HlmIconComponent } from '../icon';
// / List of classes to style as different variants.
export const BUTTON_HOST_ATTRIBUTES = [
  'ctx-button--primary',
  'ctx-button--secondary',
];

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ctx-button--secondary], button[ctx-button--primary]',
  standalone: true,
  imports: [HlmIconComponent, MatProgressSpinnerModule],
  templateUrl: './button.component.html',
  exportAs: 'ctxButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtxButtonComponent implements OnInit {
  // Disabled could either be assigned to a property using <button [disabled]="boolean">
  // OR
  // It could be set standalone like <button disabled> which sets disabled=''
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  // Sets the disabled attribute for host element since the disabled attribute is part of the HTML spec.
  @HostBinding('attr.disabled') get disabledAttr() {
    return this._disabled ? true : null;
  }

  /**
   * The loading property is always set programatically and displays a loading spinner within the button.
   */
  @Input() loading = false;
  // Add class for loading spinner when loading input is set.
  @HostBinding('class.ctx-button--loading') get loadingClass() {
    return this.loading;
  }

  @Input() color: ThemeColors = 'primary';
  @Input() size: ButtonSizes = 'base';
  @Input() font: 'sans' | 'mono' = 'sans';
  @Input() actionIcon?: CtxIcons;
  @Input() navIcon?: CtxIcons;
  spinnerSize = 0;

  constructor(
    private elementRef: ElementRef,
    private focusMonitor: FocusMonitor,
    public animationService: AnimationService
  ) {
    elementRef.nativeElement.classList.add('ctx-button');
  }

  ngOnInit(): void {
    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding class.
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        (this.getHostElement() as HTMLElement).classList.add(attr);
      }
    }

    this.setSpinnerSize();
    this.elementRef.nativeElement.classList.add(
      `ctx-button--color-${this.color}`
    );
    this.elementRef.nativeElement.classList.add(`ctx-button--${this.size}`);
    this.elementRef.nativeElement.classList.add(`ctx-button--${this.font}`);
  }

  getHostElement() {
    return this.elementRef.nativeElement;
  }

  // Whether the button has one of the given attributes.
  hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) => {
      return this.getHostElement().hasAttribute(attribute);
    });
  }

  private setSpinnerSize() {
    this.spinnerSize = this.getSpinnerSize(this.size);
  }

  private getSpinnerSize(size: ThemeSizes) {
    switch (size) {
      case 'xs':
      // 10 size doesn't seem to work.
      /* eslint-disable-next-line no-fallthrough*/
      case 'sm':
        return 12;
      case 'base':
      case 'lg':
        return 16;
      case 'xl':
        return 18;
    }
  }

  // Focuses the button.
  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (origin) {
      this.focusMonitor.focusVia(this.getHostElement(), origin, options);
    } else {
      this.getHostElement().focus(options);
    }
  }
}
