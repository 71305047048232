import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import {
  allowedTagsOnLicense,
  CtxValidators,
  ResourceUpdateFormData,
} from 'utils';
import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxButtonComponent } from '../../_components/button/button.component';
import { CtxTagInputComponent } from '../tag-input/tag-input.component';
import { CtxBatchForm } from '../models/batch-form.model';

@Component({
  selector: 'ctx-batch-tag-form',
  templateUrl: './batch-tag-form.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  /** Successful message was not being shown, hence commented */
  standalone: true,
  imports: [
    CtxAlertComponent,
    MatSelectModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    CtxButtonComponent,
    CtxTagInputComponent,
  ],
})
export class CtxBatchTagFormComponent extends CtxBatchForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<CtxBatchTagFormComponent>,
    public fb: FormBuilder
  ) {
    super();
  }
  allowedTagsOnLicense = allowedTagsOnLicense;
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      tags: [
        [],
        [Validators.required, CtxValidators.tags(allowedTagsOnLicense)],
      ],
    });
  }
}
