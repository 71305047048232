import { Component, Inject } from '@angular/core';

import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { CtxButtonComponent } from 'ng-ui';

@Component({
  selector: 'ctx-global-notification-snackbar',
  standalone: true,
  imports: [CtxButtonComponent],
  templateUrl: './global-notification-snackbar.component.html',
})
export class GlobalNotificationSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<GlobalNotificationSnackbarComponent>
  ) {}
}
