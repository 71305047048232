<h2 mat-dialog-title>Copy Product.dat</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<mat-dialog-content>
  @if (this.loadingState) {
  <mat-spinner class="mx-auto"></mat-spinner>
  }
  <code class="break-all">{{ productDat }}</code>
</mat-dialog-content>
<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary>Close</button>
  <button
    [disabled]="formStates.disabled"
    ctx-button--primary
    actionIcon="COPY"
    (click)="copyToClipboard(productDat, downnloadTooltip)"
    #downnloadTooltip="matTooltip"
    matTooltip="Product.dat Copied!"
    [matTooltipDisabled]="true"
  >
    Copy
  </button>
  <button
    matDialogClose
    ctx-button--primary
    [disabled]="formStates.disabled"
    (click)="downloadProductDatFile()"
  >
    Download
  </button>
</mat-dialog-actions>
