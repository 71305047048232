import { SegmentCreationModel } from 'utils';

/**
 * Converts a string from 'camelCase' to 'Title Case'
 * @param string String to convert to `Title Case`
 */
export function convertCamelCaseToTitleCase(string: string) {
  if (string) {
    const spacedString = string.replace(/([A-Z])/g, ' $1');
    return spacedString
      .charAt(0)
      .toUpperCase()
      .concat(spacedString.slice(1))
      .replace(/\.[a-z]/, (substr: string) => {
        return substr.replace('.', ' ').toUpperCase();
      });
  } else {
    return string;
  }
}

/**
 * Converts a string from 'camelCase' to 'kebab-case'
 * @param string String to convert to `kebab-case`
 * @returns string
 */
export function convertKebabCaseToCamelCase(string: string) {
  return string
    .replace(/-([a-z])/g, (match) => {
      return match.toUpperCase();
    })
    .replace(/-/g, '');
}

/**
 * Stringifies the values of filters of the segment for storing in backend
 * @param segment
 */
export function serializeSegment(segment: SegmentCreationModel) {
  segment.filters.forEach((filter: any) => {
    filter.value = JSON.stringify(filter.value);
  });
}

/**
 * Parses the values of filters of the segment for using in frontend
 * @param segment
 */
export function deserializeSegment(segment: SegmentCreationModel) {
  segment.filters.forEach((filter: any) => {
    filter.value = JSON.parse(filter.value);
  });
}
