<h2 mat-dialog-title>Update Profile Details</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- First Name -->
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="firstName"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'firstName') }}</mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="lastName"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'lastName') }}</mat-error>
      </mat-form-field>

      <!-- Email -->
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input
          autocomplete="off"
          type="email"
          formControlName="email"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'email') }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>
  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
