<h2 mat-dialog-title>Confirm Deactivation</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    Are you sure you want to <b>DEACTIVATE</b> the server license?
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      ctx-button--primary
      [disabled]="this.formStates['submit']"
      [loading]="this.formStates['submit']"
    >
      Yes
    </button>
    <button ctx-button--primary (click)="close()">No</button>
  </mat-dialog-actions>
</form>
f
