<h2 mat-dialog-title>Update Color</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formValue(), dialogData, dialogRef)"
>
  <mat-dialog-content>
    <p>
      Change the color for the admin portal, customer portal and the reseller
      portal by setting the Hue value below.
    </p>

    <!-- Hue -->
    <section class="flex flex-col">
      <div class="flex flex-row justify-between">
        <label>Hue</label>
        <label class="font-bold">{{
          formGroup.get('webPortalTheme')?.value
        }}</label>
      </div>
      <mat-slider min="0" max="360">
        <input matSliderThumb formControlName="webPortalTheme" />
      </mat-slider>
      <button
        class="self-end mb-2"
        ctx-button--secondary
        type="button"
        (click)="resetTheme()"
        [disabled]="formStates.disabled"
        size="sm"
      >
        Reset to Default
      </button>
    </section>
    <p>
      You can use the converter at
      <a href="https://oklch.com">oklch.com</a> to get a Hue value for an
      existing RGB/HEX color.
    </p>
  </mat-dialog-content>
  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      type="button"
      ctx-button--secondary
      [disabled]="formStates.disabled"
      [matDialogClose]
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
