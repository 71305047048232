<h2 mat-dialog-title>{{ dialogData.mode }} Feature Flag</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Product -->
      <mat-form-field>
        <mat-label>Product</mat-label>
        <mat-select formControlName="productId" matInput required>
          @for (product of products; track product) {
          <mat-option class="ph-no-capture" [value]="product.id">
            {{ product.name }}
          </mat-option>
          }
        </mat-select>
        @if (dialogData.mode === 'Create') {
        <mat-hint
          >Select the product that this feature flag will be added to.</mat-hint
        >
        }
        <mat-error>{{ getControlError(formGroup, 'productId') }}</mat-error>
      </mat-form-field>

      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea
          rows="1"
          matInput
          formControlName="description"
          required
        ></textarea>
        <mat-error>{{ getControlError(formGroup, 'description') }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
