<h2 mat-dialog-title>
  {{ dialogData.mode === 'Create' ? 'Configure' : 'Update' }} OIDC SSO
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formValue, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Client ID -->
      <mat-form-field>
        <mat-label>Client ID</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="clientId"
          matInput
          required
        />
        <mat-hint>From your SSO identity provider </mat-hint>
      </mat-form-field>

      <!-- Additional Client IDs -->
      <mat-form-field>
        <mat-label>Additional Client IDs</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="additionalClientIds"
          matInput
        />
        <mat-hint>Comma separated client IDs.</mat-hint>
      </mat-form-field>

      <!-- Idp issuer Url-->
      <mat-form-field>
        <mat-label>IdP Issuer URL </mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="issuerUrl"
          matInput
          required
        />
        <mat-hint>From your SSO identity provider</mat-hint>
      </mat-form-field>
      <!-- Default role-->
      <mat-form-field>
        <mat-label>Default Role </mat-label>
        <mat-select formControlName="defaultRole">
          @for (role of roles; track role) {
          <mat-option class="ph-no-capture" [value]="role.name">
            {{ role.name }}
          </mat-option>
          }
        </mat-select>
        <mat-hint> Default role assigned to the new users. </mat-hint>
      </mat-form-field>
      <!-- Claims-->
      <ng-container formGroupName="claimMapping">
        <mat-form-field>
          <mat-label>Name Claim </mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="name"
            matInput
          />
          <mat-hint>Claim to get the user name</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Email Claim </mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="email"
            matInput
          />
          <mat-hint>Claim to get the user email</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Role Claim </mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="role"
            matInput
          />
          <mat-hint>Claim to get the user role</mat-hint>
        </mat-form-field>
      </ng-container>
      <!-- Auto-Provision Users -->
      <mat-checkbox
        formControlName="autoProvisionUsers"
        (change)="createRoleMapping($event.checked)"
        >Auto-Provision Users on First Login
      </mat-checkbox>

      <section formArrayName="roleMappings" class="flex flex-col gap-4">
        @for ( oidcRoleMapping of roleMappingsFields.controls; track
        oidcRoleMapping; let index = $index) {
        <span class="flex flex-row gap-2 items-start" [formGroupName]="index">
          <!-- Service Provider Role -->
          <mat-form-field class="flex-grow">
            <mat-label>Service Provider Role</mat-label>
            <mat-select formControlName="serviceProviderRole" required>
              @for (role of roles; track role) {
              <mat-option class="ph-no-capture" [value]="role.name">
                {{ role.name }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(oidcRoleMapping, 'serviceProviderRole')
            }}</mat-error>
          </mat-form-field>
          <!-- Identity Provider Role -->
          <mat-form-field class="flex-grow">
            <mat-label>Identity Provider Role</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="identityProviderRole"
              matInput
              required
            />
            <mat-error>{{
              getControlError(oidcRoleMapping, 'identityProviderRole')
            }}</mat-error>
          </mat-form-field>
          <!-- Role mapping delete -->
          <button
            ctx-button--secondary
            type="button"
            color="accent"
            (click)="removeFormArrayField(roleMappingsFields, index)"
            actionIcon="CLEAR"
            [disabled]="
              this.formGroup.get('autoProvisionUsers')?.value &&
              this.roleMappingsFields.length === 1
            "
          ></button>
        </span>
        }
      </section>

      <!-- Add Role Mappings -->
      @if (this.formGroup.get('autoProvisionUsers')?.value) {
      <button
        ctx-button--secondary
        type="button"
        actionIcon="CREATE"
        class="self-center"
        (click)="
          addFormArrayField(roleMappingsFields, _createNewOidcRoleMapping())
        "
      >
        Add Role Mappings
      </button>
      }
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="this.formStates['submit']"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode === 'Update' ? 'Update' : 'Save' }}
    </button>
  </mat-dialog-actions>
</form>
