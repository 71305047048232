import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AuthnService, CtxForm, HlmIconComponent } from 'ng-ui';

import {
  CtxPlanResponse,
  dateDifference,
  formatFilesize,
  getPlanAllowedValue,
  getResourceDisplayName,
  projectEnv,
  secondsToDays,
} from 'utils';
import { ResourceUpdateFormData } from 'utils';
import { DataCacheService } from 'ng-ui';
import { PaymentCardService } from '../card.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CtxButtonComponent } from 'ng-ui';
import { MatRadioModule } from '@angular/material/radio';
import { CurrencyPipe, NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CtxAlertComponent } from 'ng-ui';
import { FreePlanConfirmationComponent } from '../free-plan-confirmation/free-plan-confirmation.component';
const PLAN_CATEGORIES = [
  'License',
  'Identity',
  'Release',
  'Customization',
  'Security',
] as const;
type PlanCategory = (typeof PLAN_CATEGORIES)[number];

/** Date when plans changes were introduced */
const NEW_PLAN_DATE = new Date(2023, 5, 21);

const PLAN_FEATURES = {
  License: [
    { property: 'allowedProducts' },
    { property: 'allowedActivations' },
    { property: 'allowedTrialActivations' },
    { property: 'allowMaintenancePolicies' },
    {
      property: 'allowedMeterAttributes',
      display: (plan) => {
        if (plan.allowedMeterAttributes === 0) {
          return false;
        } else {
          return plan.allowedMeterAttributes;
        }
      },
    },
    { property: 'allowedFeatureFlags' },
    { property: 'allowOfflineActivations' },
    { property: 'allowHostedFloatingLicenses' },
    { property: 'allowOnPremiseFloatingLicenses' },
    {
      property: 'allowActivationLogs',
      display: (plan, account) => {
        if (plan.allowActivationLogs) {
          return true;
        } else if (account?.createdAt < NEW_PLAN_DATE) {
          return true;
        } else {
          return false;
        }
      },
    },
    { property: 'activationLogsRetentionPeriod' },
  ],
  Identity: [
    { property: 'allowedAdmins' },
    { property: 'allowedUsers' },
    { property: 'allowedOrganizations' },
    { property: 'allowOrganizationAdmins' },
    { property: 'allowSso' },
    {
      property: 'allowCustomRoles',
      display: (plan, account) => {
        if (plan.allowCustomRoles) {
          return true;
        } else if (
          plan.allowLegacyCustomRoles &&
          account?.createdAt < NEW_PLAN_DATE
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    { property: 'allowCustomerPortalAccess' },
    { property: 'allowResellers' },
  ],
  Release: [
    { property: 'allowedReleases' },
    {
      property: 'allowedProductSpace',
      display: (plan: CtxPlanResponse) => {
        return formatFilesize(plan.allowedProductSpace);
      },
    },
  ],
  Customization: [
    { property: 'allowCustomEmailTemplates' },
    { property: 'allowCustomDomain' },
    { property: 'allowSendingDomain' },
  ],
  Security: [
    {
      property: 'allowAuditLogs',
      display: (plan: CtxPlanResponse, account: any) => {
        if (plan.allowAuditLogs) {
          return true;
        } else if (plan.allowEventLogs && account?.createdAt < NEW_PLAN_DATE) {
          return true;
        } else {
          return false;
        }
      },
    },
  ],
} satisfies Record<
  PlanCategory,
  {
    property: keyof CtxPlanResponse;
    display?: (
      plan: CtxPlanResponse,
      account: any
    ) => boolean | number | string;
  }[]
>;

type PlanRow = Record<string, boolean | number | string>;

@Component({
  selector: 'ctx-plan-change-form',
  templateUrl: './plan-change-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatSlideToggleModule,
    MatTableModule,
    NgClass,
    MatRadioModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatProgressSpinnerModule,
    CurrencyPipe,
    HlmIconComponent,
  ],
})
export class PlanChangeFormComponent extends CtxForm implements OnInit {
  plans: CtxPlanResponse[] = this.dataCache
    .getCachedValues('plan')
    .filter((plan: CtxPlanResponse) => {
      return plan.deprecated === false;
    })
    .filter((plan: CtxPlanResponse) => {
      return plan.name !== 'trial';
    });

  // Table data with Feature name as Key and PlanRow[] as dataSource for
  PLAN_TABLE_DATA: PlanRow[] = this.generatePlanData();

  // Icons

  constructor(
    private fb: FormBuilder,
    public dataCache: DataCacheService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<PlanChangeFormComponent>,
    public paymentCardService: PaymentCardService,
    public authnService: AuthnService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      planId: [
        this.getInitialControlValue('plan.name', this.dialogData) === 'trial'
          ? this.plans.find((plan) => {
              return plan.name === 'startup';
            })?.id
          : this.getInitialControlValue('plan.id', this.dialogData),
        [Validators.required],
      ],
      billedYearly: [
        this.getInitialControlValue('plan.interval', this.dialogData) ===
          'yearly',
      ],
    });
  }

  /**
   * @returns An array generated from the plan data retrieved from the Web API that conforms to the format reqruired by mat-table.
   */
  private generatePlanData(): PlanRow[] {
    const _data: PlanRow[] = [];
    for (const category of PLAN_CATEGORIES) {
      _data.push({ Feature: category, isCategory: true });

      for (const feature of PLAN_FEATURES[category]) {
        const row: PlanRow = {
          Feature: getResourceDisplayName(
            feature.property.replace('allowed', '').replace('allow', ''),
            projectEnv.get('projectName')
          ),
        };

        for (const plan of this.plans) {
          if ('display' in feature && feature.display) {
            row[plan.name] = feature.display(plan, this.dialogData.resource);
          } else {
            row[plan.name] = plan[feature.property];
          }
        }

        _data.push(row);
      }
    }

    return _data;
  }

  /**
   * @returns An array of plan ids for the table to display. The planIds change based on the value of the billed yearly checkbox.
   */
  getPlanIdsToDisplay() {
    return ['Feature'].concat(
      this.getPlansToDisplay().map((plan: any) => {
        return plan.name;
      })
    );
  }

  /**
   * @returns An array of plan objects. The plans change based on the value of the billed yearly checkbox.
   */
  getPlansToDisplay(): any[] {
    let PLANS_TO_DISPLAY = [];

    PLANS_TO_DISPLAY = this.plans
      .filter((plan: any) => {
        return (
          plan.interval ===
          (this.formGroup.value.billedYearly === true ? 'yearly' : 'monthly')
        );
      })
      .sort((a: any, b: any) => {
        return a.amount - b.amount;
      });

    return PLANS_TO_DISPLAY;
  }

  account = this.dataCache.getCachedValues('account');

  /** Disabling and setting loading state till Paddle Checkout appears */
  submitButtonState = false;
  setSubmitButtonState(value: boolean) {
    this.submitButtonState = value;
  }

  openCreateCardDialog(
    setSubmitButtonState: (value: boolean) => void,
    paddlePlan?: CtxPlanResponse,
    submit?: () => void,
    showLoadOnPlan?: () => void
  ) {
    if (this.account.psp === 'stripe') {
      this.paymentCardService.launchAddStripeCardDialog();
    } else if (this.account.psp === 'paddle') {
      setSubmitButtonState(true);
      this.paymentCardService.launchAddPaddleCardDialog(
        setSubmitButtonState,
        paddlePlan,
        submit,
        showLoadOnPlan
      );
    } else {
      setSubmitButtonState(true);
      this.paymentCardService.launchAddPaddleBillingCardDialog(
        setSubmitButtonState,
        paddlePlan,
        submit,
        showLoadOnPlan
      );
    }
  }
  /** Returns true if value is of type number */
  isNumber(value: any) {
    return typeof value === 'number';
  }

  /** Returns true if value is of type boolean */
  isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  /** Returns true if value is of type string */
  isString(value: any) {
    return typeof value === 'string';
  }

  getPlanAllowedValue = getPlanAllowedValue;

  /** Don't show pricing for Enterprise 3 */
  showPrice(plan: CtxPlanResponse) {
    if (plan.name === 'enterprise3' || plan.name === 'enterprise3_annual') {
      return false;
    } else {
      return true;
    }
  }

  get selectedPlan() {
    return this.plans.find((plan) => {
      return plan.id === this.formGroup.value.planId;
    });
  }

  async plansubmit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    } else if (
      this.selectedPlan?.displayName === 'Free' &&
      this.authnService.trialExpirationDate &&
      secondsToDays(dateDifference(this.authnService.trialExpirationDate)) >= 2
    ) {
      this.dialog.open<any, { planSubmit: () => void; daysLeft: number }>(
        FreePlanConfirmationComponent,
        {
          data: {
            planSubmit: this._submit.bind(this),
            daysLeft: Math.round(
              secondsToDays(
                dateDifference(this.authnService.trialExpirationDate)
              )
            ),
          },
          disableClose: true,
        }
      );
    } else if (
      !this.paymentCardService.card &&
      this.selectedPlan?.displayName !== 'Free' &&
      this.account.paymentMethod !== 'wire_transfer'
    ) {
      this.openCreateCardDialog(
        this.setSubmitButtonState.bind(this),
        this.selectedPlan,
        this._submit.bind(this),
        this.showLoadOnPlan.bind(this)
      );
    } else {
      this._submit();
    }
  }
  async _submit() {
    this.submit(this.formGroup.value, this.dialogData, this.dialogRef);
  }

  showLoading = false;
  async showLoadOnPlan() {
    this.dialogRef.disableClose = true;
    this.showLoading = true;
  }
}
