import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

/** JS callbacks for screen breakpoints */
@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  /** Subscription to Breakpoint changes */
  private _changes: Subscription;

  /** True if Breakpoint is Small/XSmall */
  get isBreakpointSmall() {
    return this._isBreakpointSmall;
  }
  private set isBreakpointSmall(value: boolean) {
    this._isBreakpointSmall = value;
  }
  private _isBreakpointSmall = false;

  constructor(_bkpObserver: BreakpointObserver) {
    const changes = _bkpObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    this._changes = changes.subscribe((result) => {
      if (result.matches) {
        // Set isBreakpointSmall to true when viewport size is XS or S.
        this.isBreakpointSmall = true;
      } else {
        // Else, set isBreakpointSmall to false.
        this.isBreakpointSmall = false;
      }
    });
  }

  ngOnDestroy(): void {
    this._changes.unsubscribe();
  }
}
