import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AlertType, ThemeSizes } from 'utils';
import { HlmIconComponent } from '../icon';

@Component({
  selector: 'ctx-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  imports: [CommonModule, HlmIconComponent],
})
export class CtxAlertComponent {
  /**
   * alertLevel property defines the color and icon for the alert.
   */
  @Input() alertLevel: AlertType = 'warning';
  /**
   * Font size of the alert. XS by default.
   */
  @Input() size: ThemeSizes = 'xs';

  // Icons
}
