import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  CtxForm,
  CustomValidityInputComponent,
  ValidityInputType,
  computeValidityControlHint,
} from 'ng-ui';
import {
  CtxValidators,
  daysToSeconds,
  nextDayDate,
  secondsToDays,
} from 'utils';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'ctx-maintenance-policy-form',
  templateUrl: './maintenance-policy-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    MatRadioModule,
    CustomValidityInputComponent,
  ],
})
export class MaintenancePolicyFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<MaintenancePolicyFormComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  computeValidityControlHint = computeValidityControlHint;
  validityMethods: ValidityInputType = 'lifetime';
  tommorow: Date;
  ngOnInit(): void {
    this.tommorow = nextDayDate(new Date().toISOString());

    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      validity: new FormControl(
        {
          value: secondsToDays(
            this.getInitialControlValue('validity', this.dialogData)
          ),
          disabled: this.dialogData.mode === 'Update',
        },
        [Validators.required, Validators.min(0), CtxValidators.maxDays]
      ),
      expirationStrategy: new FormControl({
        value:
          this.getInitialControlValue('expirationStrategy', this.dialogData) ||
          'immediate',
        disabled: this.dialogData.mode === 'Update',
      }),
      allowMajorVersionUpdates: [
        this.getInitialControlValue(
          'allowMajorVersionUpdates',
          this.dialogData,
          false
        ),
      ],
      allowMinorVersionUpdates: new FormControl({
        value: this.getInitialControlValue(
          'allowMinorVersionUpdates',
          this.dialogData,
          false
        ),
        disabled: this.getInitialControlValue(
          'allowMajorVersionUpdates',
          this.dialogData
        ),
      }),
    });
  }
  get formValue() {
    const _value = Object.assign({}, this.formGroup.value);

    if (this.dialogData.mode === 'Create' && _value.validity) {
      _value.validity = Math.floor(daysToSeconds(_value.validity));
    }

    return _value;
  }

  handleAllowMajorVersionChange(change: MatCheckboxChange) {
    if (change && change.checked && change.checked === true) {
      this.formGroup.get('allowMinorVersionUpdates')?.disable();
      this.formGroup.get('allowMinorVersionUpdates')?.setValue(true);
    } else {
      this.formGroup.get('allowMinorVersionUpdates')?.enable();
    }
  }

  handleValidityMethodChange(event: MatRadioChange) {
    this.validityMethods = event.value;
    this.formGroup.patchValue({
      validity: 1,
    });
    this.formGroup.get('Validity')?.updateValueAndValidity();
  }
}
