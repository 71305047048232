@for (navLink of navLinks; track $index) { @if(!(navLink.hidden &&
navLink.hidden(authnService))){
<a
  class="flex flex-row border-transparent items-center gap-2 pl-6 pr-2 py-3 w-full hover:bg-secondary-2 bg-opacity-20 text-left text-primary-9 hover:text-primary-9 no-underline"
  [ngClass]="{
    'bg-secondary-2 font-bold border-secondary-2': isLinkDisabled(navLink),
  }"
  [routerLink]="isLinkDisabled(navLink) ? null : getUrlFromNavLink(navLink)"
>
  @if(navLink.icon){
  <div
    [ngClass]="{
      'rounded-full p-0.5 text-secondary-1 bg-secondary-9':
        isLinkDisabled(navLink)
    }"
    class="inline-flex"
  >
    <hlm-icon [name]="navLink.icon" class=""></hlm-icon>
  </div>
  }
  <span>
    {{ navLink.label }}
  </span>
</a>
} }
