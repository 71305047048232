import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { CtxDatepickerToggleComponent, ValidityInputType } from 'ng-ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInput, MatInputModule } from '@angular/material/input';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { dateDifference, nextDayDate, secondsToDays } from 'utils';
import { AddDaysPipe } from '../../_pipes/date.pipe';

/**
 * Internally, the FormGroup within this component always holds values in either Days or Date. The value is transformed
 * to seconds using a getter and passed to the EventEmitter validityInSeconds.
 */
@Component({
  selector: 'ctx-custom-validity-input',
  templateUrl: './custom-validity-input.component.html',
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CtxDatepickerToggleComponent,
    DatePipe,
    AddDaysPipe,
  ],
  providers: [
    { provide: MatFormFieldControl, useExisting: CustomValidityInputComponent },
  ],
})
export class CustomValidityInputComponent
  implements
    MatFormFieldControl<number>,
    OnInit,
    OnDestroy,
    ControlValueAccessor
{
  constructor(
    public focusMonitor: FocusMonitor,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: any,
    public errorStateMatcher: ErrorStateMatcher
  ) {
    this.ngControl.valueAccessor = this;
  }
  get maxdate(): Date {
    return nextDayDate(new Date().toDateString(), 24854);
  }
  @ViewChild(MatInput, { read: ElementRef, static: true })
  input: ElementRef<HTMLInputElement>;
  id: string;
  onChange: any;
  onTouched: any;
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  handleChanges(event: any) {
    this.onTouched();
    // If the method is date, calculate the difference in days between the current date and the selected date
    if (this.method === 'date') {
      const oldDate = new Date(
        formatDate(this.startDate, 'yyyy-MM-dd', 'en-US')
      );
      const newDate = new Date(
        formatDate(event.target.value, 'yyyy-MM-dd', 'en-US')
      );
      const diffInDays = secondsToDays(dateDifference(newDate, oldDate));
      this.onChange(diffInDays);
    } else {
      this.onChange(event.target.value);
    }
  }
  onContainerClick(event: MouseEvent) {
    if (
      this.input &&
      (event.target as Element).tagName.toLowerCase() !== 'input'
    ) {
      this.input.nativeElement.focus();
    }
  }
  /**
   * The method of input for the validity duration
   */
  @Input({ required: true }) method: ValidityInputType;
  /**
   * Incase you want to set a start date for the validity
   */
  @Input() startDate: string = new Date().toISOString();
  @Input() mindate: Date = nextDayDate(new Date().toISOString());

  get value(): number {
    return this._value;
  }
  @Input()
  set value(value: number) {
    this._value = value;
    this.stateChanges.next();
  }
  stateChanges = new Subject<void>();
  private _value: number;
  placeholder: string;
  focused = false;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  get errorState(): boolean {
    this.ngControl?.control?.updateValueAndValidity();
    return this.errorStateMatcher.isErrorState(
      this.ngControl.control,
      this._parentFormGroup
    );
  }
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;
  setDescribedByIds(): void {
    //TODO
  }
  ngOnInit(): void {
    if (this.input) {
      this.focusMonitor.monitor(this.input).subscribe((focused) => {
        this.focused = !!focused;
        this.stateChanges.next();
      });
    }
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.input);
    this.stateChanges.complete();
  }
}
