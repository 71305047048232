import { ActivatedRoute, Router } from '@angular/router';
import {
  generateRouterQueryParams,
  getDataFromRouterQueryParams,
} from './routing';

export const ERROR_QUERY_KEY = 'error';
/**
 * Returns a QueryParam object with filters
 */
function convertErrorDataToQuery(
  errorResponse: unknown
): Record<string, string> {
  return generateRouterQueryParams(ERROR_QUERY_KEY, errorResponse);
}

/**
 * Navigates to error page with errorData in queryParams.
 */
export async function navigateToErrorPage(
  errorResponse: unknown,
  router: Router
) {
  await router.navigate(['error'], {
    queryParams: convertErrorDataToQuery(errorResponse),
  });
}

/**
 * Get filter from query param if present in ActivatedRoute
 */
export function getErrorFromUrl(route: ActivatedRoute): unknown {
  return getDataFromRouterQueryParams(ERROR_QUERY_KEY, route);
}

/** Extract error message from API response */
export function getErrorMessageFromResponse(errorResponse: any): string {
  if (errorResponse.error && errorResponse.error.message) {
    return errorResponse.error.message;
  } else if (errorResponse.message) {
    return errorResponse.message;
  } else {
    console.error(errorResponse);
    return 'An unexpected error occurred.';
  }
}

/** Extract HTTP Status code from API response */
export function getErrorStatusFromResponse(errorResponse: any): string {
  if (typeof errorResponse.status === 'number') {
    return errorResponse.status;
  } else {
    return '';
  }
}
