import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AnimationService } from '../../_services/animation.service';
import { SegmentService } from '../../_services/segment.service';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { RouteNode } from '../../_models/base-routes';
import { CtxButtonComponent } from '../button/button.component';
import { MatMenuModule } from '@angular/material/menu';
import { HlmIconComponent } from '../icon';
import { AuthnService } from '../../_services/authn.service';

@Component({
  selector: 'ctx-secondary-nav-rail-links',
  templateUrl: './secondary-nav-rail-links.component.html',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    CtxButtonComponent,
    MatMenuModule,
    RouterModule,
    HlmIconComponent,
  ],
})
export class SecondaryNavRailLinksComponent {
  constructor(
    private router: Router,
    public animationService: AnimationService,
    public segmentService: SegmentService,
    public authnService: AuthnService
  ) {}

  /** Currenyly only Admin routes have children nav links */
  @Input() navLinks: RouteNode<unknown, null>[];
  @Input() allRoutes: RouteNode<unknown, unknown>[];

  isLinkDisabled(link: RouteNode<unknown, null>) {
    return this.getUrlFromNavLink(link) === this.router.url.split('?')[0];
  }

  getUrlFromNavLink(link: RouteNode<unknown, null>) {
    let routelink = '';
    this.allRoutes.forEach((route: any) => {
      if (route.children) {
        route.children.forEach((childroute: any) => {
          if (childroute.path === link.path) {
            routelink = `/${route.path}/${childroute.path}`;
          }
        });
      } else if (route.path === link.path) {
        routelink = `/${route.path}`;
      }
    });
    return routelink;
  }
}
