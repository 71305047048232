<section class="ctx-stepper mb-2">
  <!-- Header for all steps  -->
  <nav
    class="flex flex-row overflow-auto h-full box-border w-full items-stretch mb-2"
  >
    <!-- This will enable using stepper condtionaly where there are more than one and only one steps to show and hide the button for only one step which make no sense otherwise -->
    @if(steps.length>1){ @for (step of steps; track step; let i = $index) {
    <button
      ctx-step-header
      class="ctx-stepper__header__button flex-grow transition-all"
      [ngClass]="
        selectedIndex === i ? 'ctx-stepper__header__button--active' : null
      "
      (click)="step.select(); stepperRef.scrollTo(0, 0)"
      [icon]="step.icon"
      [hasError]="step.hasError"
      [isNavigable]="_stepIsNavigable(i, step)"
      [disabled]="
        (linear && i > selectedIndex) || (!step.editable && i < selectedIndex)
      "
      (keydown)="_onKeydown($event)"
      [tabIndex]="_getFocusIndex() === i ? 0 : -1"
      [id]="_getStepLabelId(i)"
      [attr.aria-posinset]="i + 1"
      [attr.aria-setsize]="steps.length"
      [attr.aria-controls]="_getStepContentId(i)"
      [attr.aria-selected]="selectedIndex === i"
      [attr.aria-label]="step.ariaLabel || null"
      [attr.aria-labelledby]="
        !step.ariaLabel && step.ariaLabelledby ? step.ariaLabelledby : null
      "
      [attr.aria-disabled]="_stepIsNavigable(i, step) ? null : true"
      type="button"
    >
      <!-- Show Number if Linear -->
      @if (linear) {
      <span
        class="ctx-stepper__header__button__number px-2 font-mono rounded-xl mr-1"
        >{{ (i + 1).toString() }}</span
      >
      }
      <!-- Step label -->
      <span>
        {{ step.label }}
      </span>
    </button>
    }}
  </nav>
  <!-- Step Content  -->
  <section
    class="ctx-stepper__content max-h-[44vh] overflow-auto p-4"
    [@.disabled]="animationService.prefersReducedMotion"
    [@stepTransition]="selectedIndex"
    [attr.aria-labelledby]="stepLabelId"
    [id]="stepContentId"
    #stepperRef
  >
    <ng-container
      [ngTemplateOutlet]="selected ? selected.content : null"
    ></ng-container>
  </section>
</section>
