import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AccountAliasResponse, AccountAliasesFormData } from 'utils';
import { AccountAliasesComponent } from '../_components/account-aliases/account-aliases.component';
import { CryptlexApiService } from './cryptlex-api.service';
import { getRecaptchaToken } from '../_utils/recaptcha';

@Injectable({
  providedIn: 'root',
})
export class AccountAliasesService {
  constructor(
    private apiService: CryptlexApiService,
    private dialog: MatDialog
  ) {}

  /**
   * Fetch accountAlias linked to email
   * @param email
   * @returns account aliases in case multple account are linked to email open's dialog to select
   * Incase user close dialog without selecting any account it returns undefined
   */
  private async read(email: string): Promise<AccountAliasResponse[]> {
    return this.apiService
      .get('/v3/accounts/account-aliases', {
        email,
        recaptcha: await getRecaptchaToken(),
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      });
  }

  async getUserAccountAlias(email: string): Promise<string> {
    const accountAliases = await this.read(email);

    if (accountAliases) {
      const accountAlias = await this.launchAccountAliasPicker(
        accountAliases,
        email
      );
      return accountAlias;
    } else {
      return '';
    }
  }
  /**
   * Check for multiple account
   * @param accountAliases
   * @param email
   * @returns
   */
  private async launchAccountAliasPicker(
    accountAliases: any[],
    email: string
  ): Promise<string> {
    if (accountAliases.length > 1) {
      const _dialog = this.dialog.open<
        AccountAliasesComponent,
        AccountAliasesFormData
      >(AccountAliasesComponent, {
        data: {
          accountAliases,
          email,
        },
      });
      return await firstValueFrom(_dialog.afterClosed());
    } else if (accountAliases.length === 0) {
      return '';
    } else {
      return accountAliases[0].accountAlias;
    }
  }
}
