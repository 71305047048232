import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ResourceUpdateFormData } from 'utils';
import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxButtonComponent } from '../../_components/button/button.component';
import { CtxForm } from '../models/form.model';

@Component({
  selector: 'ctx-profile-update-form',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    CtxAlertComponent,
    CtxButtonComponent,
  ],
  templateUrl: './profile-update-form.component.html',
})
export class ProfileUpdateFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<ProfileUpdateFormComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      firstName: [
        this.getInitialControlValue('firstName', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      lastName: [
        this.getInitialControlValue('lastName', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      email: [
        this.getInitialControlValue('email', this.dialogData),
        [Validators.required, Validators.email],
      ],
    });
  }
}
