<h2 mat-dialog-title>Trial Expired</h2>

<mat-dialog-content>
  {{ data.message }}
</mat-dialog-content>

<mat-dialog-actions>
  <button ctx-button--primary (click)="openIntercom()">Contact Support</button>
  <button
    ctx-button--primary
    (click)="accountService.launchPlanChangeDialog(closeDialog.bind(this))"
  >
    Choose Plan
  </button>
</mat-dialog-actions>
