import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ResourceViewDialogData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxPermissionSelectionComponent } from '../permission-selection/permission-selection.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ctx-view-allowed-permissions',
  standalone: true,
  imports: [
    MatDialogModule,
    CtxButtonComponent,
    CtxPermissionSelectionComponent,
  ],
  templateUrl: './view-allowed-permissions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAllowedPermissionsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceViewDialogData
  ) {}
  permissionControls: FormControl;

  ngOnInit(): void {
    if (this.dialogData.property) {
      const permissions = this.dialogData.resource[this.dialogData.property];
      this.permissionControls = new FormControl(permissions);
    }
  }
}
