<h2 mat-dialog-title>Configure 2FA</h2>

@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <mat-dialog-content>
    @if (!dialogData.resource.twoFactorEnabled) {
    <section>
      <h2>Scan the QR code</h2>
      <p>
        Using your authenticator app (we recommend Authy), scan the QR code
        below.
      </p>
      @if (twoFactorOtpUrl) {
      <section class="flex flex-row justify-center">
        <qrcode
          [qrdata]="twoFactorOtpUrl"
          [width]="200"
          errorCorrectionLevel="M"
        ></qrcode>
      </section>
      } @else {
      <span class="w-full box-border flex flex-row justify-center p-4">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
      }
      <h2>Validate the 2FA code</h2>
      <p>
        To enable two-factor authentication, enter the two-factor code from your
        authenticator app.
      </p>
      <mat-form-field class="w-full py-2">
        <mat-label>Two Factor Code</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="twoFactorCode"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'twoFactorCode') }}</mat-error>
      </mat-form-field>
    </section>
    } @else {
    <p>
      Please be advised that disabling two-factor authentication (2FA) on your
      account can greatly decrease the security of your profile. We highly
      recommend keeping 2FA enabled to ensure the safety of your account and
      personal information.
    </p>
    }
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.resource.twoFactorEnabled ? 'Disable' : 'Enable' }}
    </button>
  </mat-dialog-actions>
</form>
