<button
  ctx-button--secondary
  actionIcon="DATE"
  color="accent"
  type="button"
  [attr.aria-haspopup]="for ? 'dialog' : null"
  aria-label="Open calendar"
  [attr.tabindex]="for.disabled ? -1 : 0"
  [disabled]="for.disabled"
  [ngClass]="for && for.opened ? 'ctx-button--active' : null"
  (click)="for && !for.disabled ? for.open() : null"
></button>
