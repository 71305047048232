<h2 mat-dialog-title>{{ dialogData.title }}</h2>
<mat-dialog-content>
  <ul>
    @for (item of countries; track item) {
    <li>{{ item }}</li>
    }
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button ctx-button--primary mat-dialog-close>Close</button>
</mat-dialog-actions>
