import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CtxAlertComponent, CtxButtonComponent, CtxForm } from 'ng-ui';
import { ResourceCreateFormData } from 'utils';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ctx-deactivate-server',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    CtxButtonComponent,
    CtxAlertComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './deactivate-server.component.html',
})
export class DeactivateServerComponent extends CtxForm {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData,
    public dialogRef: MatDialogRef<DeactivateServerComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({});
  }

  close() {
    this.dialogRef.close();
  }
}
