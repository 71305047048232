<h2 mat-dialog-title>Choose account to continue</h2>
<mat-dialog-content>
  <p>The following account aliases are available for {{ dialogData.email }}.</p>

  <mat-action-list>
    <!-- Button closes dialog and outputs accountAlias using matDialogClose -->
    @for (account of dialogData.accountAliases; track account) {
    <button mat-list-item [matDialogClose]="account.accountAlias">
      <hlm-icon name="ACCOUNT" matListItemIcon></hlm-icon>
      <h3 matListItemTitle>{{ account.company }}</h3>
      <p matListItemLine>{{ account.accountAlias }}</p>
    </button>
    }
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button [matDialogClose]="undefined" ctx-button--primary>Close</button>
</mat-dialog-actions>
