import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { Subscription } from 'rxjs';
import { ResourceUpdateFormData, getThemeHue, setThemeHue } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatSliderModule } from '@angular/material/slider';
import { CtxAlertComponent } from 'ng-ui';

/** TODO use value from design tokens */
const DEFAULT_HUE = 240;

@Component({
  selector: 'ctx-color-change-form',
  templateUrl: './color-change-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatSliderModule,
    CtxButtonComponent,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class ColorChangeFormComponent
  extends CtxForm
  implements OnInit, OnDestroy
{
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData,
    public dialogRef: MatDialogRef<ColorChangeFormComponent>
  ) {
    super();
  }

  /**  */
  initialHue = coerceNumberProperty(getThemeHue()) || DEFAULT_HUE;

  valueSubscriptions: Subscription;

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      webPortalTheme: this.initialHue,
    });

    this.valueSubscriptions = this.formGroup.valueChanges.subscribe((value) => {
      setThemeHue(value.webPortalTheme);
    });

    const $afterClosed = this.dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.handleClose();
        $afterClosed.unsubscribe();
      } else {
        setThemeHue(this.formGroup.value.webPortalTheme);
      }
    });
  }

  ngOnDestroy(): void {
    this.valueSubscriptions.unsubscribe();
  }

  /** Reset the theme to default */
  resetTheme() {
    this.formGroup.patchValue({ webPortalTheme: DEFAULT_HUE });
  }

  /** Reset the theme to default */
  private handleClose() {
    // Due to RxJS constantly changing values, we will need to unsubscribe from the value changes
    // to prevent the theme from being reset to the last value in the form
    this.valueSubscriptions.unsubscribe();
    // Reset to default
    setThemeHue(this.initialHue);
  }

  formValue() {
    const value = Object.assign({}, this.formGroup.value);
    value.webPortalTheme = value.webPortalTheme.toString();
    return value;
  }
}
