@if (!showLoading) {
<h2 mat-dialog-title>Change Plan</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}
<form [formGroup]="formGroup" (ngSubmit)="plansubmit()">
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Current plan message -->
      <p class="">
        You are currently subscribed to
        <strong
          >{{ dialogData.resource.plan.displayName }} ({{
            dialogData.resource.plan.interval
          }})</strong
        >. The plan is billed
        <strong class="font-mono">{{
          dialogData.resource.plan.amount | currency : 'USD'
        }}</strong
        >.
      </p>
      <!-- Yearly? -->
      <section class="flex flex-col gap-4 mb-4 items-center">
        <mat-slide-toggle formControlName="billedYearly"
          >Billed yearly</mat-slide-toggle
        >
      </section>
    </section>
    <!-- Plan Table -->
    <section class="overflow-auto max-h-96">
      <table mat-table [dataSource]="PLAN_TABLE_DATA">
        <!-- Feature Column  -->
        <ng-container matColumnDef="Feature" sticky>
          <th
            mat-header-cell
            class="whitespace-nowrap border-0 border-r-2 border-solid"
            *matHeaderCellDef
          >
            Feature
          </th>
          <td
            mat-cell
            class="border-0 border-r-2 border-solid"
            *matCellDef="let element"
          >
            <span
              class="whitespace-nowrap"
              [ngClass]="{
                      'font-bold': element?.['isCategory']
                      }"
              >{{ element['Feature'] }}
            </span>
          </td>
        </ng-container>
        <!-- Plan Columns  -->
        <mat-radio-group required formControlName="planId">
          @for (plan of plans; track plan) {
          <ng-container [matColumnDef]="plan.name">
            <th mat-header-cell class="whitespace-nowrap" *matHeaderCellDef>
              <div class="flex flex-row items-center gap-2">
                <span class="flex flex-col">
                  <!-- Name -->
                  <span>{{ plan.displayName }}</span>
                  <!-- Price -->
                  <span class="font-mono text-xs">
                    {{
                      showPrice(plan)
                        ? (plan.amount | currency : 'USD')
                        : 'Contact Sales'
                    }}
                  </span>
                </span>
                <!-- Plan selector -->
                <mat-radio-button [value]="plan.id"></mat-radio-button>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <!-- Number -->
              @if (isNumber(element[plan.name])) {
              <span class="font-mono">
                {{ getPlanAllowedValue(element[plan.name]) }}
              </span>
              }
              <!-- Boolean -->
              @if (isBoolean(element[plan.name])) {
              <ng-container #booleanValue>
                <hlm-icon
                  [name]="
                    element[plan.name] ? 'PLAN_ALLOWED' : 'PLAN_NOT_ALLOWED'
                  "
                ></hlm-icon>
              </ng-container>
              }
              <!-- String -->
              @if (isString(element[plan.name])) {
              <span class="font-mono">
                {{ element[plan.name] }}
              </span>
              }
            </td>
          </ng-container>
          }
        </mat-radio-group>
        <tr
          mat-header-row
          *matHeaderRowDef="getPlanIdsToDisplay(); sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: getPlanIdsToDisplay()"></tr>
      </table>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="this.formStates['submit']"
    >
      Close
    </button>
    <button
      ctx-button--primary
      type="submit"
      [loading]="this.formStates['submit'] || submitButtonState"
      [disabled]="this.formStates['submit'] || submitButtonState"
    >
      {{ account.plan.name === 'trial' ? 'Choose Plan' : 'Change Plan' }}
    </button>
  </mat-dialog-actions>
</form>
} @else {
<div class="flex flex-col flex-grow items-center justify-center min-h-[30vh]">
  <p class="max-w-prose">Your card details are being processed...</p>
  <mat-spinner> </mat-spinner>
</div>
}
