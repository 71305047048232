<h2 mat-dialog-title>
  {{ this.dialogData.resource.googleClientId ? 'Disable' : 'Configure' }} Google
  SSO
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}

<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    @if (!dialogData.resource.googleClientId) {
    <section class="flex flex-col gap-4">
      <div>
        To enable Google SSO for your customers, you will have to set up Google
        SSO using a Google Client ID. Once you have obtained it, paste your
        Google Client ID in the field below.To do so, complete the following
        steps:
        <ul>
          <li>
            Open the Credentials page of the
            <a href="https://console.developers.google.com/apis" target="_blank"
              >Google APIs console.</a
            >
          </li>
          <li>
            Create or select a Google APIs project. If you already have a
            project for the Sign In With Google button or Google One Tap, use
            the existing project and the web client ID. If your project doesn't
            have a Web application-type client ID, click
            <b>Create credentials > OAuth client ID</b> to create one. Be sure
            to include your site's domain in the Authorized JavaScript origins
            box. Please note that Google One Tap can only be displayed in HTTPS
            domains. When you perform local tests or development, you must add
            both http://localhost and http://localhost:port_number to the
            <b>Authorized JavaScript origins box</b>. The
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy"
              target="_blank"
              >Referrer-Policy</a
            >
            header must also be set to no-referrer-when-downgrade when using
            http and localhost.
          </li>
        </ul>
      </div>
      <!--  -->
      <mat-form-field>
        <mat-label>Google Client ID</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="googleClientId"
          matInput
          required
        />
        <mat-error>{{
          getControlError(formGroup, 'googleClientId')
        }}</mat-error>
      </mat-form-field>
    </section>
    }@else {
    <section class="flex flex-col gap-4">
      <p>
        Disabling Google SSO will remove the configured Google Client ID from
        the account. This will disable your customers from logging in using
        Google.
      </p>
    </section>
    }
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="this.formStates['submit']"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="
        !dialogData.resource.googleClientId
          ? formGroup.invalid || this.formStates['submit']
          : this.formStates['submit']
      "
      [loading]="this.formStates['submit']"
    >
      {{
        dialogData.resource.googleClientId
          ? 'Remove Client ID'
          : 'Add Client ID'
      }}
    </button>
  </mat-dialog-actions>
</form>
