import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { ResourceName } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { FilterableProperties } from 'utils';
import { SelectionModel } from '@angular/cdk/collections';
import { TrialPolicyFormComponent } from './trial-policy-form/trial-policy-form.component';
import { Router } from '@angular/router';
import { SEARCH_STRINGS } from 'utils';
import { ViewArrayDataComponent } from 'admin-portal/common/view-array-data/view-array-data.component';
import { ViewCountryDataComponent } from 'admin-portal/common/view-country-data/view-country-data.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class TrialPolicyService extends ResourceTableService {
  override resourceApiPath = '/v3/trial-policies';
  override resourceName: ResourceName = 'trial-policy';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override creationComponent = TrialPolicyFormComponent;
  override updationComponent = TrialPolicyFormComponent;
  override selections = new SelectionModel<any>(true, []);
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    {
      property: 'trialLength',
      displayType: 'seconds',
    },
    {
      property: 'fingerprintMatchingStrategy',
      displayType: 'flag',
    },
    {
      property: 'allowVmActivation',
      displayType: 'flag',
    },
    { property: 'allowContainerActivation', displayType: 'flag' },
    { property: 'userLocked', displayType: 'flag' },
    {
      property: 'disableGeoLocation',
      displayType: 'flag',
    },
    {
      property: 'allowedIpRanges',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'allowedCountries',
      displayType: 'arrayData',
      modalComponent: ViewCountryDataComponent,
    },
    {
      property: 'disallowedCountries',
      displayType: 'arrayData',
      modalComponent: ViewCountryDataComponent,
    },
    {
      property: 'allowedIpAddresses',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'disallowedIpAddresses',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.writeAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override _columnIdsToDisplay: string[] = [
    'name',
    'trialLength',
    'allowVmActivation',
    'userLocked',
    'disableGeoLocation',
    'allowedIpRanges',
    'updatedAt',
  ];

  override filterableProperties: FilterableProperties = {};
  override tableEmptyMessage = 'No active trials policy found.';

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
