import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  prefersReducedMotion: boolean = false;

  constructor(mediaMatcher: MediaMatcher) {
    this.prefersReducedMotion = mediaMatcher.matchMedia(
      '(prefers-reduced-motion)'
    ).matches;
  }
}
