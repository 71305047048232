import { Injectable } from '@angular/core';
import { RouterEventService } from 'admin-portal/services/router-event.service';

/**
 * This service handles the current state of dashaboard
 */
@Injectable({
  providedIn: 'root',
})
export class CtxDashboardPageService {
  pageApiUrl: string;

  /**
   * TODO
   *
   * Returns boolean value for page if it is allowed in plan.
   *
   */
  get isAllowedInPlan() {
    return true;
  }

  /**
   * TODO
   *
   */
  get isAuthorized() {
    return true;
  }

  constructor(public routerEvent: RouterEventService) {}
}
