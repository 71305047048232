import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import {
  getErrorFromUrl,
  getErrorMessageFromResponse,
  getErrorStatusFromResponse,
} from 'utils';
import { CtxLayoutComponent, HlmIconComponent } from 'ng-ui';

export type ErrorPageData = {
  previousUrl: string;
  errorResponse: any;
};

@Component({
  selector: 'ctx-error-page',
  standalone: true,
  imports: [CtxLayoutComponent, HlmIconComponent],
  templateUrl: './error-page.component.html',
})
export class CtxErrorPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  errorMessage: string;
  errorStatus: string;

  ngOnInit() {
    const _errorResponse = getErrorFromUrl(this.route);
    this.errorMessage = getErrorMessageFromResponse(_errorResponse);
    this.errorStatus = getErrorStatusFromResponse(_errorResponse);
  }
}
