import { Component } from '@angular/core';

import { DataCacheService } from '../../_services/data-cache.service';
import { ThemeManagerService } from '../../_services/theme-manager.service';
import { AuthnService } from '../../_services/authn.service';
import { MatMenuModule } from '@angular/material/menu';
import { CtxButtonComponent } from '../button/button.component';
import { HlmIconComponent } from '../icon';
import {
  draftIntercomBugReportMessage,
  draftIntercomFeedbackMessage,
  isIntercomVisible,
} from '../../_utils/intercom';
import { environment, projectEnv } from 'utils';

@Component({
  selector: 'ctx-app-bar-actions',
  standalone: true,
  imports: [HlmIconComponent, MatMenuModule, CtxButtonComponent],
  templateUrl: './app-bar-actions.component.html',
  styles: [],
})
export class CtxAppBarActionsComponent {
  /** Cached values for profile */
  profile = this.dataCacheService.getCachedValues('profile');

  constructor(
    private dataCacheService: DataCacheService,
    public authnService: AuthnService,
    public themeManager: ThemeManagerService
  ) {}

  get buttonLabel() {
    if (this.profile && this.profile.firstName && this.profile.lastName) {
      return `${this.profile.firstName.charAt(0)}${this.profile.lastName.charAt(
        0
      )}`;
    } else {
      return 'CX';
    }
  }

  get isIntercomVisible(): boolean {
    return isIntercomVisible(
      environment.get('name'),
      projectEnv.get('projectName')
    );
  }

  draftIntercomBugReportMessage = draftIntercomBugReportMessage;
  draftIntercomFeedbackMessage = draftIntercomFeedbackMessage;
}
