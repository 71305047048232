<h2 mat-dialog-title>
  {{ isSsoEnabled ? 'Disable' : 'Enable' }} Login with Google
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<mat-dialog-content>
  <p>
    Login with Google is currently
    <strong>
      <code>{{ isSsoEnabled ? 'Enabled' : 'Disabled' }}</code>
    </strong>
  </p>
  @if (isSsoEnabled) {
  <p>
    Press the 'Disable' button below to disable Login with Google on your email
    {{ dialogData.resource.email }}.
  </p>
  } @else {
  <p>
    Press the 'Enable' button below to enable Login with Google on your email
    {{ dialogData.resource.email }}.
  </p>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary [disabled]="formStates.disabled">
    Close
  </button>
  <button
    ctx-button--primary
    [disabled]="formStates.disabled"
    [loading]="formStates.submit"
    (click)="submit(formValue, dialogData, dialogRef)"
  >
    {{ isSsoEnabled ? 'Disable' : 'Enable' }}
  </button>
</mat-dialog-actions>
