import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CryptlexApiService } from './cryptlex-api.service';
import { isPlatformBrowser } from '@angular/common';
import { setBaseApiForPortals } from '../_utils/apiBaseUrl';
import { CUSTOM_CONFIG_KEYS, projectEnv } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private apiService: CryptlexApiService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  config: any;
  async getAndSetConfig() {
    if (isPlatformBrowser(this.platformId)) {
      setBaseApiForPortals();
      return (
        this.apiService
          .get('/v3/accounts/config')
          .then((response: any) => {
            this.config = response.body;
            this.setConfig();
            return response.body;
          })
          // handle failed promise which sometimes effects bootstrapping
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          })
      );
    }
    // handles ssr
    return new Promise((resolve) => {
      resolve('');
    });
  }

  setConfig(account?: any) {
    const host = window.location.hostname.toLowerCase();
    if (
      host.startsWith('app.cryptlex.com') ||
      host.startsWith('app.dev.cryptlex.com') ||
      host.startsWith('localhost')
    ) {
      this.config = null;
    }
    /** custom asssets obtained cuonfig endpoints  or account updating*/
    const config = account ?? this.config;
    if (config) {
      for (const key of CUSTOM_CONFIG_KEYS) {
        if (config?.[key]) {
          localStorage.setItem(key, config[key]);
        } else {
          localStorage.removeItem(key);
        }
      }
    }

    /** project env variables obtained from config endpoint */
    for (const key of projectEnv.keys) {
      if (this.config?.[key]) {
        projectEnv.set(key, this.config[key]);
      }
    }
  }
}
