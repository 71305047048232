import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxTagComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-tag-form',
  templateUrl: './tag-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    CtxTagComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class TagFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TagFormComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: new FormControl(
        {
          value: this.getInitialControlValue('name', this.dialogData),
          disabled: this.dialogData.mode === 'Update',
        },
        [Validators.maxLength(256)]
      ),
      description: [
        this.getInitialControlValue('description', this.dialogData),
        Validators.maxLength(256),
      ],
    });
  }
}
