import { Component, Inject, OnInit } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { CtxForm } from '../models/form.model';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CtxValidators, ResourceUpdateFormData } from 'utils';
import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxButtonComponent } from '../../_components/button/button.component';

@Component({
  selector: 'ctx-change-password-form',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    CtxAlertComponent,
    CtxButtonComponent,
  ],
  templateUrl: './change-password-form.component.html',
})
export class ChangePasswordFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        oldPassword: ['', [Validators.required, Validators.minLength(8)]],
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validators: CtxValidators.passwordMatch }
    );
  }
}
