import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AnimationService } from 'libs/ng-ui/src/lib/_services/animation.service';
import { CtxStepHeaderButtonComponent } from '../step-header-button/step-header-button.component';
import { CtxStepComponent } from '../step/step.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';

/**
 * Left to right animation in @angular/animations
 */
export const ANIMATE_LEFT_TO_RIGHT = animate(
  '500ms 0ms ease-out',
  keyframes([
    style({ transform: 'translate3d(-100%, 0 ,0 )', visibility: 'hidden' }),
    style({ transform: 'translate3d(0, 0 ,0 )', visibility: 'inherit' }),
  ])
);
/**
 * Right to left animation in @angular/animations
 */
export const ANIMATE_RIGHT_TO_LEFT = animate(
  '500ms 0ms ease-out',
  keyframes([
    style({ transform: 'translate3d(100%, 0 ,0 )', visibility: 'hidden' }),
    style({ transform: 'translate3d(0, 0 ,0 )', visibility: 'inherit' }),
  ])
);

/**
 * The StepperComponent is used for both step based and tab based forms.
 */
@Component({
  selector: 'ctx-stepper, ctx-tab-group',
  templateUrl: './stepper.component.html',
  providers: [{ provide: CdkStepper, useExisting: CtxStepperComponent }],
  animations: [
    trigger('stepTransition', [
      // Animate right-to-left when going to step with a lower index
      transition((fromState, toState) => {
        return fromState > toState;
      }, ANIMATE_LEFT_TO_RIGHT),
      // Animate left-to-right when going to step with a higher index
      transition((fromState, toState) => {
        return fromState < toState;
      }, ANIMATE_RIGHT_TO_LEFT),
    ]),
  ],
  standalone: true,
  imports: [CtxStepHeaderButtonComponent, NgClass, NgTemplateOutlet],
})
export class CtxStepperComponent extends CdkStepper {
  /** The list of step headers of the steps in the stepper. */
  @ViewChildren(CtxStepHeaderButtonComponent)
  override _stepHeader: QueryList<CtxStepHeaderButtonComponent>;

  /** Full list of steps inside the stepper, including inside nested steppers. */
  @ContentChildren(CtxStepComponent, { descendants: true })
  override _steps: QueryList<CtxStepComponent>;

  @ViewChild('stepperRef') stepperRef: ElementRef;
  /** Steps that belong to the current stepper, excluding ones from nested steppers. */
  override readonly steps: QueryList<CtxStepComponent> =
    new QueryList<CtxStepComponent>();

  constructor(
    _dir: Directionality,
    _cdRef: ChangeDetectorRef,
    _elementRef: ElementRef,
    public animationService: AnimationService
  ) {
    super(_dir, _cdRef, _elementRef);
  }

  /** Returns true if any of the steps has an error. */
  get hasError() {
    // CdkStep property `hasError` is false if step.interacted = false.
    this.markStepsAsInteracted();
    return this.steps.some((step) => {
      return step.hasError;
    });
  }

  /**
   * Sets step.interacted = true for all steps within the stepper.
   */
  private markStepsAsInteracted() {
    for (const step of this.steps) {
      step._markAsInteracted();
    }
  }

  /** Returns true if step is navigable */
  _stepIsNavigable(index: number, step: CtxStepComponent): boolean {
    return step.completed || this.selectedIndex === index || !this.linear;
  }

  /** Generated ID string for currently active step. This matches the ARIA label of the corresponding button in the header */
  get stepLabelId(): string {
    return this._getStepLabelId(this.selectedIndex);
  }

  /** Generated ID string for the content in currently active step */
  get stepContentId() {
    return this._getStepContentId(this.selectedIndex);
  }

  /** Selects the first invalid step.*/
  handleInvalidForm() {
    const firstStepWithError = this._steps.find((step) => {
      /**
       * since the `hasError` getter checks for `interacted` && `stepControl.invalid`,
       * it is essential for us to ensure that `interacted` is set to true for all steps
       */
      step._markAsInteracted();
      if (step.hasError) {
        /** Ensure that errors are shown when navigated to the tab by marking fields as touched
         * This doesn't work on custom components, a fix has been done in v18 https://github.com/angular/angular/issues/10887
         */
        step?.stepControl.markAllAsTouched();
        return true;
      } else {
        return false;
      }
    });

    //reset Scroll
    this.stepperRef.nativeElement.scrollTo(0, 0);
    /** Navigate to the first tab with errors */
    firstStepWithError?.select();
  }
}
