import { Injectable } from '@angular/core';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';
import { CryptlexApiService, DataCacheService, ResourceService } from 'ng-ui';
import { ResourceName } from 'utils';

@Injectable({
  providedIn: 'root',
})
/**
 * Service is used to fetch roles that have type admin.
 */
export class AdminRoleService extends ResourceService {
  override resourceName: ResourceName = 'admin-role';
  override resourceApiPath = '/v3/roles?type=admin';

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }
}
