import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CtxDynamicForm } from 'ng-ui';

import {
  CtxFormMode,
  ResourceCreateFormData,
  ResourceUpdateFormData,
} from 'utils';
import { DataCacheService } from 'ng-ui';
import { FeatureFlagService } from '../../feature-flags/feature-flag.service';
import { CtxButtonComponent } from 'ng-ui';
import { NgClass } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-product-version-form',
  templateUrl: './product-version-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    CtxStepperComponent,
    CtxStepComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgClass,
    MatSlideToggleModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class ProductVersionFormComponent
  extends CtxDynamicForm
  implements OnInit
{
  /** Resolved and cached products. */
  products = this.dataCacheService.getCachedValues('product');

  /** Controls to manage tabs */
  detailsTab: FormControl;
  featuresTab: FormArray;

  featureFlags: any[];

  constructor(
    fb: FormBuilder,
    private dataCacheService: DataCacheService,
    public dialogRef: MatDialogRef<ProductVersionFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    private featureFlagService: FeatureFlagService
  ) {
    super(fb);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      productId: new FormControl(
        {
          value: this.getInitialControlValue('productId', this.dialogData),
          disabled: this.dialogData.mode === CtxFormMode.Update,
        },
        [Validators.required]
      ),
      name: [
        this.getInitialControlValue('name', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      displayName: [
        this.getInitialControlValue('displayName', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      featureFlags: this.fb.array(
        this.getInitialFormArrayValue('featureFlags', this.dialogData)
      ),
    });

    this.detailsTab = this.fb.control([
      this.formGroup.get('productId'),
      this.formGroup.get('name'),
      this.formGroup.get('displayName'),
      this.formGroup.get('description'),
    ]);

    this.featuresTab = this.formGroup.get('featureFlags') as FormArray;
  }

  trackByFeatureFlagId(index: number, item: AbstractControl): any {
    return item.value.id;
  }

  /**
   * Gets the Feature Flags corresponding to the Product and accordingly sets the featureFlag FormArray.
   */
  async setFeatureFlags(id: string) {
    this.setState('features', true);
    await this.featureFlagService
      .list(1, 100, { productId: id })
      .then((response) => {
        this.featureFlags = response.body ? response.body : [];
        const formArray = this.formGroup.get('featureFlags') as FormArray;
        formArray.clear();
        this.featureFlags.forEach((flag) => {
          const newFlagControl = this.fb.group({
            ...flag,
            enabled: false,
            data: '',
          });
          formArray.push(newFlagControl);
        });

        this.setState('features', false);
      })
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.error(errorResponse);
        this.setState('features', false);
      });
  }
}
