import { ActivatedRoute, Router } from '@angular/router';
import { isObject } from './objects';
import {
  generateRouterQueryParams,
  getDataFromRouterQueryParams,
} from './routing';
import {
  COMPARISON_OPERATOR_LABELS,
  Filter,
  FilterComparisonOperator,
} from '../types/filter.types';
import { TableFilterQueryParam } from '../types/table.types';

export const FILTER_QUERY_KEY = 'filter';
/**
 * Returns a QueryParam object with filters
 */
function convertFiltersToQuery(filters: Filter[]): Record<string, string> {
  return generateRouterQueryParams(FILTER_QUERY_KEY, filters);
}

/**
 * Navigates to path with filter where propertyName eq propertyValue using the router param.
 */
export async function navigateWithFilters(
  filters: Filter[],
  router: Router,
  path: string
) {
  await router.navigate([path], {
    queryParams: convertFiltersToQuery(filters),
  });
}

/**
 * Get filter from query param if present in ActivatedRoute
 */
export function getFiltersFromUrl(route: ActivatedRoute): Filter[] | undefined {
  return getDataFromRouterQueryParams(FILTER_QUERY_KEY, route) as any;
}

/**
 *
 * @param _serviceFilter
 * @returns
 */
export function getFiltersFromService(
  _serviceFilter: TableFilterQueryParam
): Filter[] | undefined {
  const _filterParams = Object.keys(_serviceFilter);

  //  IF Service already has active filters, parse them and return Filter[]
  if (isObject(_serviceFilter) && _filterParams.length !== 0) {
    const filterArr: Filter[] = [];

    for (const _param of _filterParams) {
      const { filterOperator, filterProperty } = getFilterInfo(_param);

      for (const value of _serviceFilter[_param]) {
        filterArr.push({
          property: filterProperty,
          operator: filterOperator,
          value: value.toString(),
        });
      }
    }
    return filterArr;
  } else {
    return undefined;
  }

  /**
   * Converts query parameter like createdAt[gte] to property and operator
   */
  function getFilterInfo(_param: string): {
    filterProperty: string;
    filterOperator: FilterComparisonOperator;
  } {
    const FILTER_WITH_OPERATOR_REGEX = /(.*)\[(.*)\]/;
    if (FILTER_WITH_OPERATOR_REGEX.test(_param)) {
      return {
        filterProperty: _param.replace(FILTER_WITH_OPERATOR_REGEX, '$1'),
        filterOperator: _param.replace(
          FILTER_WITH_OPERATOR_REGEX,
          '$2'
        ) as FilterComparisonOperator,
      };
    } else {
      return { filterProperty: _param, filterOperator: 'eq' };
    }
  }
}

/**
 * WARNING: This is an approximate comparison. Do not use when result of comparison should be STRICTLY correct.
 *
 * @param arr1 Array 1
 * @param arr2 Array 2
 * @returns True if the two arrays are equivalent.
 */
export function compareFilterArrays(arr1: any[], arr2: any[]): boolean {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

/**
 * Returns human-readable string for comparison operator.
 * @param comparisonOperator Comparison operator string
 */
export function getLabelForComparisonOperator(
  comparisonOperator: FilterComparisonOperator
) {
  return COMPARISON_OPERATOR_LABELS[comparisonOperator];
}
