import { Injectable } from '@angular/core';
import { ResourceName } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceService } from 'ng-ui';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ResellerClaimService extends ResourceService {
  override resourceName: ResourceName = 'reseller-claim';
  override resourceApiPath = '/v3/roles/claims?roleType=reseller';

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    permissionsService: PermissionsService
  ) {
    super(apiService, dataCacheService, permissionsService);
  }
}
