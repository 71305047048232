/**
 * @param resouceArray Array of resources.
 * @param id Id of resource to lookup
 * @returns Name of resource with id or id
 */
export function getPropertyForId(
  resouceArray: any[],
  id: string,
  property: string
) {
  try {
    const selectedResource = resouceArray?.find((resource: any) => {
      return resource.id === id;
    });
    if (selectedResource && selectedResource[property]) {
      return selectedResource[property];
    } else {
      return id;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return id;
  }
}

/**
 * https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
 * @returns True if the vairable is an object.
 */
export function isObject(variable: any): boolean {
  if (
    typeof variable === 'object' &&
    !Array.isArray(variable) &&
    variable !== null
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * @returns An object without the keys defined in the keysToOmit parameters
 */
export function omit(object: any, keysToOmit: string[]) {
  return Object.keys(object)
    .filter((key) => {
      return !keysToOmit.includes(key);
    })
    .reduce((accumulator: any, key: string) => {
      accumulator[key] = object[key];
      return accumulator;
    }, {});
}

/**
 * @returns An object with only the keys defined in the keysToKeep parameter
 */
export function pick(object: any, keysToKeep: string[]) {
  return Object.keys(object)
    .filter((key) => {
      return keysToKeep.includes(key);
    })
    .reduce((accumulator: any, key: string) => {
      accumulator[key] = object[key];
      return accumulator;
    }, {});
}

/**
 * @returns True if parameter is an array of objects.
 */
export function isArrayOfObjects(value: any) {
  return Array.isArray(value) && value.length > 0 && isObject(value[0]);
}

/**
 * Extracts ther data from the object parameter as per the defined dot notation accessor string defined in
 * the property parameter.
 *
 * @param object Any JS object
 * @param property Property to access in dot notation
 */
export function getObjectProperty(object: any, property: string): any {
  return property.split('.').reduce((prev, curr) => {
    return prev && prev[curr];
  }, object);
}
