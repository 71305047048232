@if(method==='days'){
<section class="flex items-center">
  <input
    type="number"
    matInput
    [value]="value"
    (input)="handleChanges($event)"
  />
  <span matSuffix>days</span>
</section>
} @else{
<section class="flex items-center">
  <input
    matInput
    required
    [matDatepicker]="picker"
    [max]="maxdate"
    [min]="mindate"
    [readonly]="true"
    (dateChange)="handleChanges($event)"
    [value]="value | addDays : startDate"
  />
  <ctx-datepicker-toggle matSuffix [for]="picker"></ctx-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</section>
}
