import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxDeleteForm } from 'ng-ui';
import { ResourceDeleteFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-oidc-disable',
  templateUrl: './oidc-disable.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class OidcDisableComponent extends CtxDeleteForm {
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceDeleteFormData,
    public dialogRef: MatDialogRef<OidcDisableComponent>
  ) {
    super();
  }
  ngOnInit(): void {
    this.formGroup = this.fb.group({});
  }
}
