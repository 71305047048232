// TODO LiveAnnouncer for announcing theme changes to screeen readers.
import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable, OnDestroy, afterNextRender } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { ThemeService } from 'ng2-charts';
import { Observable, Subscription, fromEvent, map, startWith } from 'rxjs';
import {
  IntercomDarkTheme,
  IntercomLightTheme,
  updateIntercom,
} from '../_utils/intercom';
import { PersistentStore } from './persistent-store.service';
import { NG_ICONS } from 'utils';

export type CtxThemes = 'cryptlex-light' | 'cryptlex-dark';

/** Colors for use with chart.js, Color schemes go from a scale of 1-5 where 1 is transparent and 5 is opaque. */
const CHART_COLORS: Record<string, Record<number, string>> = {
  WHITE: {
    1: 'rgba(255,255,255,0.1)',
    2: 'rgba(255,255,255,0.34)',
    3: 'rgba(255,255,255,0.6)',
    4: 'rgba(255,255,255,0.81)',
    5: 'rgba(255,255,255,1)',
  },
  BLACK: {
    1: 'rgba(0,0,0,0.1)',
    2: 'rgba(0,0,0,0.34)',
    3: 'rgba(0,0,0,0.6)',
    4: 'rgba(0,0,0,0.81)',
    5: 'rgba(0,0,0,1)',
  },
};

const CHART_DARK_THEME: ChartOptions = {
  scales: {},
  plugins: {
    title: { color: CHART_COLORS['WHITE'][3] },
    legend: {
      align: 'end',
      labels: {
        color: CHART_COLORS['WHITE'][4],
      },
    },
  },
  elements: {
    bar: {
      backgroundColor: CHART_COLORS['WHITE'][4],
      borderColor: CHART_COLORS['WHITE'][3],
    },
  },
};

const CHART_LIGHT_THEME: ChartOptions = {
  scales: {},
  plugins: {
    legend: {
      align: 'end',
    },
  },
  elements: {
    bar: {
      backgroundColor: CHART_COLORS['BLACK'][4],
      borderColor: CHART_COLORS['BLACK'][3],
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ThemeManagerService implements OnDestroy {
  /** True when active theme is dark */
  isDarkMode = false;

  /** True when automatic switching of themes is done */
  isAutoMode = true;

  /** Subscription that allows automatic switching of themes based on client preference */
  autoModeSubscription = new Subscription();

  constructor(
    private mediaMatcher: MediaMatcher,
    private chartThemeService: ThemeService
  ) {
    afterNextRender(() => {
      PersistentStore.get('currentTheme').then((theme) => {
        if (theme) {
          this.setTheme(theme as CtxThemes);
        } else {
          // Set theme to OS Default
          this.enableAutoThemeMode();
        }
      });
    });
  }

  ngOnDestroy() {
    this.autoModeSubscription.unsubscribe();
  }

  /**
   * Enables automatic switching of themes based on client.
   */
  enableAutoThemeMode() {
    this.isAutoMode = true;

    // Unsubscribe from any previous media query subscription.
    this.autoModeSubscription.unsubscribe();

    // Remove theme from localStorage
    PersistentStore.remove('currentTheme');

    this.autoModeSubscription = this.checkDarkModeMediaQuery().subscribe(
      (isDarkMode) => {
        this.isDarkMode = isDarkMode;
        /**
         * Set theme based on the isDarkMode property set by the automode subscription
         */
        this.isDarkMode ? this.setDarkMode() : this.setLightMode();
      }
    );
  }

  /**
   * Disables automatic switching of themes based on client.
   */
  disableAutoThemeMode() {
    this.isAutoMode = false;
    this.autoModeSubscription.unsubscribe();
  }

  /** Icon for the theme options */
  get activeThemeIcon() {
    if (this.isAutoMode) {
      return NG_ICONS.OS_DEFAULT_THEME;
    } else if (this.isDarkMode) {
      return NG_ICONS.DARK_MODE;
    } else {
      return NG_ICONS.LIGHT_MODE;
    }
  }

  /**
   * Returns an observable that emits true if client prefers dark mode.
   */
  private checkDarkModeMediaQuery(): Observable<boolean> {
    // For browsers with no support for matchMedia(), we will have light mode by default.
    const mediaQuery = this.mediaMatcher.matchMedia(
      '(prefers-color-scheme:dark)'
    );

    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((list: MediaQueryList) => {
        return list.matches;
      })
    );
  }

  /**
   * Sets the theme in localStorage using PersistentStore and accordingly sets
   * the isDarkMode flag. Disables auto mode.
   */
  setTheme(theme: CtxThemes) {
    PersistentStore.set('currentTheme', theme);

    if (theme === 'cryptlex-dark') {
      this.isDarkMode = true;
      this.setDarkMode();
    } else {
      this.isDarkMode = false;
      this.setLightMode();
    }

    this.disableAutoThemeMode();
  }

  /**
   * Sets the stylesheet link to the dark mode CSS.
   */
  private setDarkMode() {
    document.documentElement.setAttribute('data-theme', 'dark');

    updateIntercom(IntercomDarkTheme);
    this.chartThemeService.setColorschemesOptions(CHART_DARK_THEME);
  }

  /**
   * Removes the link for CSS to dark mode.
   */
  private setLightMode() {
    document.documentElement.removeAttribute('data-theme');
    updateIntercom(IntercomLightTheme);
    this.chartThemeService.setColorschemesOptions(CHART_LIGHT_THEME);
  }
}
