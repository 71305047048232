<h2 mat-dialog-title>
  {{ action }}
  {{ convertCamelCaseToTitleCase(dialogData.resource.portal) }} Portal Domain
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      @if ( dialogData.resource.portal==='customer' ) {
      <mat-form-field>
        <mat-label>Customer Portal Custom Domain</mat-label>
        <input type="text" formControlName="customerPortalDomain" matInput />
        <mat-error>{{
          getControlError(formGroup, 'customerPortalDomain')
        }}</mat-error>
      </mat-form-field>

      }@else {
      <mat-form-field>
        <mat-label>Reseller Portal Custom Domain</mat-label>
        <input type="text" formControlName="resellerPortalDomain" matInput />
        <mat-error>{{
          getControlError(formGroup, 'resellerPortalDomain')
        }}</mat-error>
      </mat-form-field>
      }
    </section>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ action }}
    </button>
  </mat-dialog-actions>
</form>
