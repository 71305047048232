<h2 mat-dialog-title>Delete Card</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}
<mat-dialog-content>
  <p>
    Please be aware that deleting your credit card information may affect any
    recurring payments or subscriptions associated with that card. If you have
    any questions or concerns, please contact our customer support team for
    assistance.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    matDialogClose
    ctx-button--secondary
    [disabled]="this.formStates['submit']"
  >
    Close
  </button>
  <button
    ctx-button--primary
    (click)="submit()"
    color="warn"
    [disabled]="this.formStates['submit']"
    [loading]="this.formStates['submit']"
  >
    Delete
  </button>
</mat-dialog-actions>
