import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { convertCamelCaseToTitleCase } from 'utils';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { CtxPermissionSelectionComponent } from '../../../common/permission-selection/permission-selection.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-role-form',
  templateUrl: './role-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    ReactiveFormsModule,
    CtxAlertComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    CtxPermissionSelectionComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class RoleFormComponent extends CtxForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<RoleFormComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      description: [
        this.getInitialControlValue('description', this.dialogData),
        Validators.maxLength(256),
      ],
      claims: [this.getInitialControlValue('claims', this.dialogData) || []],
    });
  }

  convertCamelCaseToTitleCase = convertCamelCaseToTitleCase;
}
