import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { CtxValidators, getVisibilityIcon } from 'utils';
import { convertCamelCaseToTitleCase } from 'utils';
import { DataCacheService } from 'ng-ui';
import { WebhookService } from '../webhook.service';
import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CtxButtonComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-webhook-form',
  templateUrl: './webhook-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    CtxButtonComponent,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class WebhookFormComponent extends CtxForm implements OnInit {
  /**
   * Events are cached in DataCacheService using a resolver.
   */
  events = this.dataCacheService.getCachedValues('webhook-trigger');
  /**
   * Return keys to group the related events.
   */
  get eventTypeIterable() {
    return Object.keys(this.events);
  }
  convertCamelCaseToTitleCase = convertCamelCaseToTitleCase;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    public dialogRef: MatDialogRef<WebhookFormComponent>,
    private fb: FormBuilder,
    public productService: WebhookService,
    private dataCacheService: DataCacheService
  ) {
    super();
  }
  /** True if Token is visible */
  isTokenVisible = false;
  getVisibilityIcon = getVisibilityIcon;
  ngOnInit() {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        Validators.maxLength(256),
      ],
      url: [
        this.getInitialControlValue('url', this.dialogData),
        CtxValidators.url,
      ],
      token: [
        this.getInitialControlValue('token', this.dialogData),
        Validators.maxLength(256),
      ],
      active: [this.getInitialControlValue('active', this.dialogData, true)],
      events: [
        this.getInitialControlValue('events', this.dialogData),
        Validators.required,
      ],
    });
  }
}
