import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { ScriptService } from 'ng-ui';
import { projectEnv } from 'utils';

export const initializeGoogleAnalytics = async (
  scriptService: ScriptService,
  angulartics: Angulartics2GoogleGlobalSiteTag
) => {
  const gaKey = projectEnv.get('googleAnalyticsKey');
  if (gaKey) {
    scriptService.addGoogleAnalytics(gaKey).then((loaded) => {
      if (loaded) {
        angulartics.startTracking();
      }
    });
  }
};
