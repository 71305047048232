import { HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DataCacheService } from './data-cache.service';
import {
  DEFAULT_ACTIVATION_LOG_SEGMENTS,
  DEFAULT_ACTIVATION_SEGMENTS,
  DEFAULT_FILE_SEGMENTS,
  DEFAULT_LICENSE_SEGMENTS,
  DEFAULT_ORGANIZATION_SEGMENTS,
  DEFAULT_RELEASE_SEGMENTS,
  DEFAULT_TRIAL_SEGMENTS,
  DEFAULT_USER_SEGMENTS,
  Filter,
  FilterableProperties,
  ResourceName,
  SEARCH_STRINGS,
  Segment,
  SegmentCreationFormData,
  SegmentCreationModel,
  SegmentResourceName,
  SegmentStore,
  TableActions,
  TableColumn,
  deserializeSegment,
  serializeSegment,
} from 'utils';
import { CryptlexApiService } from './cryptlex-api.service';
import { CreateSegmentFormComponent } from '../_forms/create-segment-form/create-segment-form.component';
import { ResourceTableService } from './resource-table.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { PermissionsService } from './permissions.service';

const DEFAULT_SEGMENTS: SegmentStore = {
  'license': DEFAULT_LICENSE_SEGMENTS,
  'activation': DEFAULT_ACTIVATION_SEGMENTS,
  'trial-activation': DEFAULT_TRIAL_SEGMENTS,
  'user': DEFAULT_USER_SEGMENTS,
  'activation-log': DEFAULT_ACTIVATION_LOG_SEGMENTS,
  'release-file': DEFAULT_FILE_SEGMENTS,
  'organization': DEFAULT_ORGANIZATION_SEGMENTS,
  'release': DEFAULT_RELEASE_SEGMENTS,
};

@Injectable({
  providedIn: 'root',
})
export class SegmentService extends ResourceTableService implements OnDestroy {
  override resourceName: ResourceName = 'segment';
  override resourceApiPath = '/v3/segments';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  // override creationComponent = OrganizationFormComponent;
  // override updationComponent = OrganizationFormComponent;
  override tableEmptyMessage = 'You have not saved any filters.';
  override selections = new SelectionModel<any>(true, []);
  override filterableProperties: FilterableProperties = {};

  override actions: TableActions = {
    read: true,
    create: false,
    update: false,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override columns: TableColumn[] = [
    { displayType: 'name', property: 'name' },
    { displayType: 'flag', property: 'resource' },
  ];
  _columnIdsToDisplay: string[] = ['name', 'resource'];

  constructor(
    apiService: CryptlexApiService,
    dataCacheService: DataCacheService,
    dialog: MatDialog,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }

  /** Segments fetched from API */
  private _segmentsFromApi: Segment[] = [];
  set segmentsFromApi(segments: Segment[]) {
    this._segmentsFromApi = segments;
  }
  get segmentsFromApi() {
    return this._segmentsFromApi;
  }

  async launchCreationDialogWithParams(filters: Filter[]) {
    this.dialog.open<any, SegmentCreationFormData>(CreateSegmentFormComponent, {
      data: {
        create: this.create.bind(this),
        filters,
        resource: this.activeResource,
      },
    });
  }

  override create(body: SegmentCreationModel): Promise<HttpResponse<Segment>> {
    serializeSegment(body);
    return this.apiService
      .post(this.resourceApiPath, body)
      .then((response) => {
        // TEST THIS
        deserializeSegment(body); //segment from which new segmented is created has to be deserialized as well
        // as filter values are directly passed as body and they get mutated on serialization above
        // causing former segment values to be JSON string
        deserializeSegment(response.body);
        this.segmentsFromApi = this.segmentsFromApi.concat(response.body); // response.body is Segment type body has no id
        this.setActiveSegment(response.body);
        return response;
      })
      .catch((error) => {
        deserializeSegment(body);
        throw error;
      });
  }

  override delete(id: string): Promise<HttpResponse<any>> {
    return this.apiService
      .delete(`${this.resourceApiPath}/${id}`)
      .then((response) => {
        // Remove segment from locally stored segments
        this.segmentsFromApi = this.segmentsFromApi.filter((value) => {
          return value.id !== id;
        });
        // Reset active segment
        this.setActiveSegment(this.segments[0]);

        return response;
      });
  }

  /**
   * Segments available for current resource
   */
  get segments(): Segment[] {
    return DEFAULT_SEGMENTS[this.activeResource].concat(
      this._segmentsFromApi.filter((value) => {
        return value.resource === this.activeResource;
      })
    );
  }

  /** BehaviourSubject that contains the current segment. */
  activeSegment$: BehaviorSubject<Segment> = new BehaviorSubject(
    DEFAULT_LICENSE_SEGMENTS[0]
  );

  /**
   * Checks whether the parameter is a pre-defined readonly segment OR a mutable
   * @param segment Segment object
   */
  isSegmentReadOnly(segment: Segment) {
    return 'immutable' in segment;
  }

  private _activeResource: SegmentResourceName = 'license';
  /**
   * Currently selected resource (based on route)
   */
  get activeResource(): SegmentResourceName {
    return this._activeResource;
  }
  /**
   * Sets the first segment in the list as default.
   */
  set activeResource(resource: SegmentResourceName) {
    this._activeResource = resource;
    this.setActiveSegment(this.segments[0]);
  }

  /**
   * The ID of the currently active segment
   */
  get activeSegmentId(): string {
    return this.activeSegment$.value.id;
  }

  /**
   * Sets the parameter ID to the private _activeSegmentId property. Furthermore, emits the new value using
   * the activeSegment$ BehaviourSubject
   */
  setActiveSegment(segment: Segment) {
    this.activeSegment$.next(segment);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.activeSegment$.complete();
    this.activeSegment$.unsubscribe();
  }
}
