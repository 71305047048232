import { formatNumber } from '@angular/common';

/**
 * TODO test for browser and locale support
 * Plan data represents infinity by 0. This function handles the logic for displaying allowed value in a plan.
 *
 * @returns Infinity when value is 0, ELSE returns value;
 */
export function getPlanAllowedValue(allowed: number | undefined): string {
  if (allowed !== undefined) {
    return allowed === 0 ? '∞' : formatNumber(allowed, 'en-US');
  } else {
    return 'unknown';
  }
}
