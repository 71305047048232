import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  AuthnService,
  CtxForm,
  CtxHtmlRenderComponent,
  DataCacheService,
} from 'ng-ui';

import { ResourceCreateFormData, ResourceUpdateFormData } from 'utils';
import { environment } from 'utils';
import { MatMenuModule } from '@angular/material/menu';
import { CtxButtonComponent } from 'ng-ui';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';
import { AutomatedEmailService } from '../automated-email.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatTableModule } from '@angular/material/table';
import { Subscription } from 'rxjs';

const AUTOMATED_EMAIL_PLACEHOLDERS: string[] = [
  'user.name',
  'user.firstName',
  'user.lastName',
  'user.email',
  'user.company',
  'license.key',
  'license.totalActivations',
  'license.totalDeactivations',
  'license.validity',
  'license.type',
  'license.allowedFloatingClients',
  'license.serverSyncGracePeriod',
  'license.leaseDuration',
  'license.expiresAt',
  'license.maintenanceExpiresAt',
  'license.maxAllowedReleaseVersion',
  'license.allowedIpRanges',
  'product.name',
  'product.displayName',
];
@Component({
  selector: 'ctx-automated-email-form',
  templateUrl: './automated-email-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    CtxStepperComponent,
    CtxStepComponent,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatOptionModule,
    CtxButtonComponent,
    MatMenuModule,
    MatDialogActions,
    MatDialogClose,
    MatProgressSpinnerModule,
    CtxHtmlRenderComponent,
    MatTableModule,
  ],
})
export class AutomatedEmailFormComponent
  extends CtxForm
  implements OnInit, OnDestroy
{
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AutomatedEmailFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    private authnService: AuthnService,
    public emailAutomationService: AutomatedEmailService,
    private dataCacheService: DataCacheService
  ) {
    super();
  }

  /** StepControl for Tabs/Steps */
  detailsTab: FormControl;
  bodyTab: FormControl;
  valueChangesSubscription: Subscription | undefined;

  getEmailPreview(e: StepperSelectionEvent) {
    /** Preview is fetched only when formGroup is valid and relevant fields change */
    if (
      e.selectedIndex === 2 &&
      this.formGroup.valid &&
      (this.formGroup.get('subject')?.dirty ||
        this.formGroup.get('body')?.dirty ||
        this.formGroup.get('event')?.dirty ||
        this.formGroup.get('custom')?.dirty)
    ) {
      /** setting the fields as pristine to stop unnecessary calls to backend */
      this.formGroup.get('body')?.markAsPristine();
      this.formGroup.get('subject')?.markAsPristine();
      this.formGroup.get('event')?.markAsPristine();
      this.formGroup.get('custom')?.markAsPristine();

      this.html = '';
      this.getPreview();
    }
  }
  ngOnInit() {
    this.formGroup = this.fb.group({
      name: [
        this.getInitialControlValue('name', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      fromName: [
        this.getInitialControlValue('fromName', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      fromEmail: [
        this.getInitialControlValue('fromEmail', this.dialogData) ||
          this.DEFAULT_FROM_EMAIL,
        [Validators.required, Validators.email],
      ],
      subject: [
        this.getInitialControlValue('subject', this.dialogData),
        [Validators.required, Validators.maxLength(256)],
      ],
      event: [
        this.getInitialControlValue('event', this.dialogData),
        [Validators.required],
      ],
      body: [
        this.getInitialControlValue('body', this.dialogData),
        [Validators.required, Validators.maxLength(10240)],
      ],
      cc: new FormControl({
        value: this.getInitialControlValue('cc', this.dialogData) || '',
        disabled:
          this.getInitialControlValue('event', this.dialogData) ===
          'user.reset-password-request',
      }),
      bcc: new FormControl({
        value: this.getInitialControlValue('bcc', this.dialogData) || '',
        disabled:
          this.getInitialControlValue('event', this.dialogData) ===
          'user.reset-password-request',
      }),
      replyTo: [
        this.getInitialControlValue('replyTo', this.dialogData),
        Validators.email,
      ],
      enabled: [this.getInitialControlValue('enabled', this.dialogData, true)],
      custom: [this.getInitialControlValue('custom', this.dialogData, false)],
    });
    this.valueChangesSubscription = this.formGroup
      .get('event')
      ?.valueChanges.subscribe((value) => {
        const ccControl = this.formGroup.get('cc');
        const bccControl = this.formGroup.get('bcc');
        if (value === 'user.reset-password-request') {
          ccControl?.disable();
          bccControl?.disable();
        } else {
          ccControl?.enable();
          bccControl?.enable();
        }
      });

    /** Details Tab Control */
    this.detailsTab = this.fb.control([
      this.formGroup.get('name'),
      this.formGroup.get('fromName'),
      this.formGroup.get('fromEmail'),
      this.formGroup.get('subject'),
      this.formGroup.get('event'),
      this.formGroup.get('enabled'),
      this.formGroup.get('cc'),
      this.formGroup.get('bcc'),
      this.formGroup.get('replyTo'),
      this.formGroup.get('custom'),
    ]);

    /** Advanced Tab Control */
    this.bodyTab = this.fb.control([this.formGroup.get('body')]);

    if (this.formGroup.valid) {
      this.getPreview();
    }
  }
  ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  /**
   * The default value of fromEmail field.
   */
  get DEFAULT_FROM_EMAIL() {
    if (environment.get('name') === 'on-premise') {
      return '';
    } else {
      return 'noreply@cryptlex-mail.com';
    }
  }

  DEFAULT_EVENTS = this.dataCacheService.getCachedValues(
    'automated-email-event'
  );

  placeholders() {
    const event = this.formGroup.get('event')?.value;
    if (event) {
      const eventType = event.split('.')[0];
      const filteredEvents = AUTOMATED_EMAIL_PLACEHOLDERS.filter((event) => {
        if (eventType === 'license') {
          return (
            event.startsWith(eventType) ||
            event.startsWith('user') ||
            event.startsWith('product')
          );
        } else {
          return event.startsWith(eventType);
        }
      });
      if (eventType === 'user') {
        filteredEvents.push('resetPasswordRelativeLink', 'resetPasswordLink');
      }
      return filteredEvents;
    } else {
      return AUTOMATED_EMAIL_PLACEHOLDERS;
    }
  }

  appendPlaceholderToBody(placeholder: string) {
    const bodyFormControl = this.formGroup.get('body');
    if (bodyFormControl) {
      if (bodyFormControl.value) {
        bodyFormControl.patchValue(
          `${bodyFormControl.value} {{${placeholder}}}`
        );
      } else {
        bodyFormControl.patchValue(`{{${placeholder}}}`);
      }
      /** changes made programmatically are not marked as dirty by default  */
      bodyFormControl.markAsDirty();
    }
  }

  async getPreview() {
    const response = await this.emailAutomationService.fetchEmailPreview(
      this.formGroup.get('subject')?.value,
      this.formGroup.get('body')?.value,
      this.formGroup.get('event')?.value,
      this.formGroup.get('custom')?.value
    );

    //this is done so that css get applied as well
    this.html = response.body.body;
    this.subject = response.body.subject;
  }

  subject = '';
  html = '';
}
