import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { DataCacheService } from 'ng-ui';
import {
  convertCamelCaseToTitleCase,
  getResourceDisplayName,
  projectEnv,
} from 'utils';
import { MatTableModule } from '@angular/material/table';

type PermissionDataSource =
  | { permission: string; read?: string; write?: string }
  | {
      permission: string;
      create?: string;
      read?: string;
      update?: string;
      delete?: string;
    };

const PERMISSION_FULL_WRITE = 'full:write';
const PERMISSION_FULL_READ = 'full:read';
const PERMISSION_RESELLER = /reseller:.+:.+/;
const PERMISSION_ORGANIZATION = /organization:.+:.+/;
const RESELLER_PORTAL_FULL_PERMISSIONS = 'reseller:*:*';
const CUSTOMER_PORTAL_FULL_PERMISSIONS = 'organization:*:*';

@Component({
  selector: 'ctx-permission-selection',
  templateUrl: './permission-selection.component.html',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatTableModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class CtxPermissionSelectionComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl<any[], any[]> | null;
  @Input() disableCheckbox: boolean;

  /** Local formGroup instance used to track the form's values */
  formGroup: FormGroup;

  /** Cached value for claims */
  adminClaims: Record<string, string[]> =
    this.dataCacheService.getCachedValues('role-claim');
  /** Cached value for organization claims */
  organizationClaims: Record<string, string[]> =
    this.dataCacheService.getCachedValues('organization-claim');
  /** Cached value for reseller claims */
  resellerClaims: Record<string, string[]> =
    this.dataCacheService.getCachedValues('reseller-claim');

  /** Constant data source created from the Claims object. */
  DATA_SOURCE: PermissionDataSource[] = [];

  /** Subscriptions to valueChanges  */
  valueChangeSubscriptions: Subscription[] = [];

  constructor(
    private dataCacheService: DataCacheService,
    private fb: FormBuilder
  ) {}
  /**
   * Determines the display columns based on the current control's value.
   * If the control value matches the pattern for reseller or organization,
   * it returns columns for create, read, update, and delete; otherwise, it returns
   * columns for read and write.
   *
   * @returns An array of column names for display.
   */
  get displayColumns() {
    // Check if the control value matches the pattern for reseller or organization
    if (
      PERMISSION_RESELLER.test(this.control?.value[0]) ||
      PERMISSION_ORGANIZATION.test(this.control?.value[0])
    ) {
      // If true, return columns for create, read, update, and delete
      return ['permission', 'create', 'read', 'update', 'delete'];
    } else {
      // If false, return columns for read and write
      return ['permission', 'read', 'write'];
    }
  }

  ngOnInit() {
    this.initializeDataAndForm();
  }

  ngOnDestroy() {
    for (const _sub of this.valueChangeSubscriptions) {
      _sub.unsubscribe();
    }
  }

  /** Array of permissions generated from this.formGroup */
  get permissions() {
    const _rawValue = this.formGroup.getRawValue();
    return Object.keys(_rawValue).filter((key: string) => {
      return _rawValue[key];
    });
  }
  get claims() {
    const controlValue = this.control?.value[0];

    if (
      PERMISSION_ORGANIZATION.test(controlValue) ||
      PERMISSION_RESELLER.test(controlValue)
    ) {
      const _claims = PERMISSION_ORGANIZATION.test(controlValue)
        ? this.organizationClaims
        : this.resellerClaims;

      delete _claims['*'];
      return _claims;
    } else {
      return this.adminClaims;
    }
  }

  /**
   * Initializes the data and form for the permission selection component.
   */
  private initializeDataAndForm() {
    this.formGroup = this.fb.group({});
    const claimKeys = Object.keys(this.claims);

    for (const key of claimKeys) {
      const createPermission = this.createPermissionControl('create', key);
      const updatePermission = this.createPermissionControl('update', key);
      const deletePermission = this.createPermissionControl('delete', key);
      const readPermission = this.createPermissionControl('read', key);
      const writePermission = this.createPermissionControl('write', key);

      this.DATA_SOURCE.push({
        permission: convertCamelCaseToTitleCase(
          getResourceDisplayName(key, projectEnv.get('projectName'))
        ),
        read: readPermission,
        write: writePermission,
        create: createPermission,
        update: updatePermission,
        delete: deletePermission,
      });
    }

    this.valueChangeSubscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.control?.setValue(this.permissions);
      })
    );
  }

  /**
   * Creates a permission control based on the given permission type and name.
   *
   * @param permissionType - The type of permission (e.g., "read" or "write").
   * @param permissionName - The name of the permission.
   * @returns The created permission control.
   */
  private createPermissionControl(
    permissionType: string,
    permissionName: string
  ) {
    // Find the permission in the claims object an true if it exists
    const permission = this.claims[permissionName].find((role: string) => {
      return new RegExp(`${permissionType}$`).test(role);
    });
    // If the permission exists, create a form control for it
    if (permission) {
      // If it's a 'write' permission, subscribe to valueChanges to handle 'read' checkbox i.e incase write is available make read checkbox true and disable it
      if (permissionType === 'write') {
        const getWriteCheckboxValue = (permissionName: string): boolean => {
          if (this.control?.value.includes(PERMISSION_FULL_WRITE)) {
            return true;
          } else {
            return this.control?.value.find((value) => {
              return value === permissionName;
            });
          }
        };
        const _formControl = new FormControl({
          value: getWriteCheckboxValue(permission),
          disabled:
            !!this.control?.value.includes(PERMISSION_FULL_WRITE) ||
            this.disableCheckbox,
        });

        this.valueChangeSubscriptions.push(
          _formControl.valueChanges.subscribe((value: boolean | null) => {
            const _read = this.formGroup.get(
              permissionType === 'write'
                ? permission.replace('write', 'read')
                : 'null'
            );
            if (value) {
              _read?.setValue(true);
              _read?.disable();
            } else {
              _read?.enable();
            }
          })
        );
        // incase of update form, disbale the read checkbox if write checkbox is checked.
        if (_formControl.value) {
          this.formGroup.get(permission.replace('write', 'read'))?.disable();
        }
        this.formGroup.addControl(permission, _formControl);
      } else {
        const getReadCheckboxValue = (permissionName: string): boolean => {
          if (
            CUSTOMER_PORTAL_FULL_PERMISSIONS === this.control?.value[0] ||
            RESELLER_PORTAL_FULL_PERMISSIONS === this.control?.value[0] ||
            this.control?.value.includes(PERMISSION_FULL_WRITE) ||
            this.control?.value.includes(PERMISSION_FULL_READ)
          ) {
            return true;
          } else {
            return this.control?.value.find((value) => {
              return value === permissionName;
            });
          }
        };
        this.formGroup.addControl(
          permission,
          new FormControl({
            value: getReadCheckboxValue(permission),
            disabled:
              !!CUSTOMER_PORTAL_FULL_PERMISSIONS === this.control?.value[0] ||
              !!RESELLER_PORTAL_FULL_PERMISSIONS === this.control?.value[0] ||
              !!this.control?.value.includes(PERMISSION_FULL_READ) ||
              this.disableCheckbox,
          })
        );
      }
    }
    return permission;
  }
}
