import { Component, Inject } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CtxButtonComponent } from 'ng-ui';
import { DataCacheService, openIntercom } from 'ng-ui';
import { AccountService } from 'admin-portal/pages/account/account.service';

@Component({
  selector: 'ctx-dialog-message',
  standalone: true,
  imports: [CtxButtonComponent, MatDialogModule],
  templateUrl: './dialog-message.component.html',
  styles: [],
})
export class DialogMessageComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    public accountService: AccountService,
    private dataCacheService: DataCacheService
  ) {}
  account = this.dataCacheService.getCachedValues('account');

  openIntercom = openIntercom;

  closeDialog() {
    this.dialogRef.disableClose = false;
    this.dialogRef.close();
  }
}
