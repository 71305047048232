import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { LicenseTemplateFormComponent } from './license-template-form/license-template-form.component';
import { SEARCH_STRINGS } from 'utils';
import { ViewArrayDataComponent } from 'admin-portal/common/view-array-data/view-array-data.component';
import { ViewCountryDataComponent } from 'admin-portal/common/view-country-data/view-country-data.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({ providedIn: 'root' })
export class LicenseTemplateService extends ResourceTableService {
  override resourceApiPath = '/v3/license-policies';
  override resourceName: ResourceName = 'license-template';
  override tableSearchPlaceholder = SEARCH_STRINGS.name;

  override creationComponent = LicenseTemplateFormComponent;
  override updationComponent = LicenseTemplateFormComponent;

  override actions: TableActions = {
    create: true,
    delete: true,
    read: true,
    search: true,
    tag: false,
    update: true,
    export: false,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.writeAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'type', displayType: 'flag' },
    { property: 'validity', displayType: 'licenseValidity' },
    {
      property: 'allowedActivations',
      displayType: 'licenseActivations',
    },
    {
      property: 'allowedDeactivations',
      displayType: 'licenseDeactivations',
    },
    {
      property: 'expirationStrategy',
      displayType: 'flag',
    },
    {
      property: 'fingerprintMatchingStrategy',
      displayType: 'flag',
    },
    {
      property: 'serverSyncGracePeriod',
      displayType: 'seconds',
    },
    {
      property: 'serverSyncInterval',
      displayType: 'seconds',
    },
    {
      property: 'allowedClockOffset',
      displayType: 'seconds',
    },

    {
      property: 'expiringSoonEventOffset',
      displayType: 'seconds',
    },
    {
      property: 'allowedFloatingClients',
      displayType: 'text',
    },
    { property: 'leaseDuration', displayType: 'seconds' },
    {
      property: 'leasingStrategy',
      displayType: 'flag',
    },
    {
      property: 'allowVmActivation',
      displayType: 'flag',
    },
    {
      property: 'allowContainerActivation',
      displayType: 'flag',
    },
    {
      property: 'allowClientLeaseDuration',
      displayType: 'flag',
    },
    { property: 'userLocked', displayType: 'flag' },
    // {
    //   property: 'requireAuthentication',
    //   displayType: 'flag',
    // },
    {
      property: 'keyPattern',
      displayType: 'text',
    },
    {
      property: 'disableGeoLocation',
      displayType: 'flag',
    },
    {
      property: 'allowedCountries',
      displayType: 'arrayData',
      modalComponent: ViewCountryDataComponent,
    },
    {
      property: 'disallowedCountries',
      displayType: 'arrayData',
      modalComponent: ViewCountryDataComponent,
    },
    {
      property: 'allowedIpAddresses',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'disallowedIpAddresses',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'allowedIpRanges',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'requiredMetadataKeys',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'requiredMeterAttributes',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override _columnIdsToDisplay: string[] = [
    'name',
    'type',
    'validity',
    'allowedActivations',
  ];
  override tableEmptyMessage =
    'A license template acts as a blueprint for the licenses you create. While creating a license from any license policy, you can override all the properties of the license policy.';

  override filterableProperties: FilterableProperties = {};

  override selections: SelectionModel<any> = new SelectionModel<any>(true, []);

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
