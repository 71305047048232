const PRIMARY_HUE_PROPERTY = '--primary-hue';

/**
 * @returns the current hue value in the document style
 */
export function getThemeHue() {
  return document.documentElement.style.getPropertyValue(PRIMARY_HUE_PROPERTY);
}

/**
 * @param hue the hue value to set in the document style
 */
export function setThemeHue(hue: number) {
  document.documentElement.style.setProperty(
    PRIMARY_HUE_PROPERTY,
    // In this case, it must be a number.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hue
  );
}
