<h2 mat-dialog-title>Delete</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}
<mat-dialog-content>
  <ctx-stepper linear #stepper>
    <ctx-step label="Declaration">
      <p class="py-2">
        Deletion is a permanent action. Cryptlex is not responsible for any
        damages caused by deletion.
      </p>
    </ctx-step>
    <ctx-step label="Confirmation" [stepControl]="formGroup">
      <p>
        {{ dialogData.deletionMessage }}
      </p>
      <p class="py-4">
        Enter the text <code>I am absolutely sure</code> in the field below to
        proceed.
        <strong>This step cannot be reversed.</strong>
      </p>
      <form [formGroup]="formGroup">
        <mat-form-field class="w-full">
          <mat-label>Confirmation Text</mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="deleteConfirmationText"
            matInput
            required
          />
          <mat-error>{{
            getControlError(formGroup, 'deleteConfirmationText')
          }}</mat-error>
        </mat-form-field>
      </form>
    </ctx-step>
  </ctx-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  @if (stepper.selectedIndex === 0) {
  <button matDialogClose ctx-button--secondary>Close</button>
  <button ctx-button--primary (click)="stepper.next()">Continue</button>
  } @if (stepper.selectedIndex === 1) {
  <button
    ctx-button--primary
    color="warn"
    type="submit"
    [disabled]="!formGroup.valid || this.formStates.disabled"
    [loading]="this.formStates.submit"
    (click)="delete(dialogData, dialogRef)"
  >
    Delete
  </button>
  <button
    matDialogClose
    ctx-button--secondary
    [disabled]="this.formStates['submit']"
  >
    Close
  </button>
  }
</mat-dialog-actions>
