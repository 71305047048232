<form [formGroup]="parentForm.formGroup">
  <!-- Key Value Fields -->
  <section class="flex flex-col gap-4">
    @for ( metadataField of formArray.controls; track metadataField; let index =
    $index; let last = $last) {
    <ng-container formArrayName="metadata">
      <span class="flex flex-col items-stretch gap-2" [formGroupName]="index">
        <mat-form-field>
          <mat-label>Key</mat-label>
          <input
            [readonly]="
              isMetadataFieldOnApi(metadataField) ||
              metadataField.value.required
            "
            autocomplete="off"
            type="text"
            formControlName="key"
            matInput
            required
          />
          <mat-error>{{
            parentForm.getControlError(metadataField, 'key')
          }}</mat-error>
        </mat-form-field>
        <!-- Metadata Value -->
        <mat-form-field class="flex-grow">
          <mat-label>Value</mat-label>
          <input
            autocomplete="off"
            type="text"
            formControlName="value"
            matInput
            required
          />
          <mat-error>{{
            parentForm.getControlError(metadataField, 'value')
          }}</mat-error>
        </mat-form-field>
        <!-- View Permissions -->
        <mat-form-field>
          <mat-label>View Permissions</mat-label>
          <mat-select formControlName="viewPermissions" multiple>
            <mat-option value="activation">activation</mat-option>
            <mat-option value="user">user</mat-option>
            <mat-option value="organization">organization</mat-option>
            <mat-option value="reseller">reseller</mat-option>
          </mat-select>
          <mat-error>{{
            parentForm.getControlError(metadataField, 'viewPermissions')
          }}</mat-error>
        </mat-form-field>

        <!-- Metadata delete -->
        @if (!metadataField.value.required) {
        <button
          ctx-button--secondary
          [disabled]="parentForm.formStates.disabled"
          type="button"
          [color]="isMetadataFieldOnApi(metadataField) ? 'warn' : 'accent'"
          [actionIcon]="
            isMetadataFieldOnApi(metadataField) ? 'DELETE' : 'CLEAR'
          "
          (click)="handleMetadataDeletion(dialogData, formArray, index)"
          [matTooltip]="parentForm.getMetadataDeleteButtonTitle(metadataField)"
        ></button>
        }
      </span>
      @if (!last) {
      <hr />
      }
    </ng-container>
    }
  </section>
  <!-- Add Metadata Button -->
  <hr />
  <span class="flex flex-col mt-2 gap-1">
    <p>
      {{ hintText }}
    </p>
    <button
      ctx-button--secondary
      class="self-center"
      type="button"
      actionIcon="CREATE"
      (click)="
        parentForm.addFormArrayField(formArray, _createNewMetadataField())
      "
    >
      Add Metadata
    </button>
  </span>
</form>
