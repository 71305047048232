import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import { ResourceUpdateFormData } from 'utils';
import { CtxButtonComponent } from 'ng-ui';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-account-details-update-form',
  templateUrl: './account-details-update-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
  ],
})
export class AccountDetailsUpdateFormComponent
  extends CtxForm
  implements OnInit
{
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AccountDetailsUpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      company: [
        this.getInitialControlValue('company', this.dialogData),
        [Validators.required, Validators.maxLength(255)],
      ],
      accountAlias: [
        this.getInitialControlValue('accountAlias', this.dialogData),
        [Validators.required, Validators.maxLength(255)],
      ],
      email: [
        this.getInitialControlValue('email', this.dialogData),
        [Validators.required, Validators.email],
      ],
    });
  }
}
