import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

/** Aliases for the scripts to be loaded in the application */
const SCRIPT_NAMES = [
  'gsi',
  'Paddle',
  'Stripe',
  'Intercom',
  'recaptcha',
  'PaddleBilling',
  'GoogleAnalytics',
] as const;
type ScriptName = (typeof SCRIPT_NAMES)[number];

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  /**
   * Record with SCRIPT_NAMES and the boolean values. The values are true if the script has been loaded already.
   */
  private status: Record<ScriptName, boolean> = {
    gsi: false,
    Paddle: false,
    Stripe: false,
    Intercom: false,
    recaptcha: false,
    PaddleBilling: false,
    GoogleAnalytics: false,
  };

  /** Load Google Identity Services */
  async loadGsi() {
    return this._load('gsi', 'https://accounts.google.com/gsi/client');
  }
  /**  */
  async loadIntercom(id = 'r8pcgmw9') {
    return this._load('Intercom', `https://widget.intercom.io/widget/${id}`);
  }

  /** Load Google ReCAPTCHA  */
  async loadRecaptcha(siteKey: string) {
    return this._load(
      'recaptcha',
      `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    );
  }
  async loadPaddle() {
    return this._load('Paddle', 'https://cdn.paddle.com/paddle/paddle.js');
  }
  async loadPaddleBilling() {
    return this._load(
      'PaddleBilling',
      'https://cdn.paddle.com/paddle/v2/paddle.js'
    );
  }
  async loadStripe() {
    return this._load('Stripe', 'https://js.stripe.com/v3/');
  }
  /**
   * @returns A promise that resolves to true if the script has been loaded, or false if
   * the script loading process led to an error.
   */
  private async _load(name: ScriptName, srcUrl: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      /**
       * IF script is already loaded, resolve
       * ELSE create new script tag in body.
       */
      if (this.status[name]) {
        resolve(true);
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.src = srcUrl;
        script.id = window.btoa(srcUrl);
        script.onload = () => {
          this.status[name] = true;
          resolve(true);
        };
        script.onerror = (error: any) => {
          // eslint-disable-next-line no-console
          console.error(error);
          posthog.capture(`${name} loading failed`);
          reject(false);
        };
        document.body.appendChild(script);
      }
    });
  }

  addGoogleAnalytics(key: string) {
    return new Promise<boolean>((resolve, reject) => {
      const scriptGtag = document.createElement('script');
      scriptGtag.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
      scriptGtag.async = true;
      scriptGtag.onload = () => {
        this.status['GoogleAnalytics'] = true;
        resolve(true);
      };
      scriptGtag.onerror = (error: any) => {
        // eslint-disable-next-line no-console
        console.error(error);
        posthog.capture('Google Analytics loading failed');
        reject(false);
      };
      document.head.appendChild(scriptGtag);

      const scriptInit = document.createElement('script');
      const scriptBody = document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${key}');
      `);

      scriptInit.appendChild(scriptBody);
      document.head.appendChild(scriptInit);
    });
  }
}
