import { Injectable } from '@angular/core';
import {
  CTX_ADMIN_ROUTES,
  DeletionComponentConfiguration,
  ResourceTableService,
} from 'ng-ui';
import { CryptlexApiService } from 'ng-ui';

import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DataCacheService } from 'ng-ui';
import { ResourceName, ResourcePatchFormData } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { FilterableProperties } from 'utils';
import { ProductFormComponent } from './product-form/product-form.component';
import { Router } from '@angular/router';
import { navigateWithFilters } from 'utils';

import { CtxFormMode, ResourceUpdateDynamicFormData } from 'utils';
import { TwoStepDeletionComponent } from 'ng-ui';
import { copyToClipboard } from 'utils';
import { SEARCH_STRINGS } from 'utils';
import { ViewArrayDataComponent } from 'admin-portal/common/view-array-data/view-array-data.component';
import { ProductDatDialogComponent } from './product-dat-dialog/product-dat-dialog.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({ providedIn: 'root' })
export class ProductService extends ResourceTableService {
  override resourceApiPath = '/v3/products';
  override resourceName: ResourceName = 'product';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override tableEmptyMessage =
    "You have not defined any products. Use the 'Create' button to start creating one.";
  override creationComponent = ProductFormComponent;
  override updationComponent = ProductFormComponent;

  override deletionComponent: DeletionComponentConfiguration = {
    singleResource: TwoStepDeletionComponent,
    multipleResource: TwoStepDeletionComponent,
  };
  override selections = new SelectionModel<any>(true, []);
  override filterableProperties: FilterableProperties = {
    'name': 'objectString',
    'metadata.key': 'objectString',
    'metadata.value': 'objectString',
  };

  /**
   * @remarks
   * Note that async operations are not used on mat-menu because the menu gets closed once clicked, and currently Material doesn't provide any method that can be used to stop the menu closure.
   */
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Copy Product ID',
        callback: this.copyProductId.bind(this),
        icon: 'COPY',
      },
      {
        label: 'Copy Public Key',
        callback: this.copyPublicKey.bind(this),
        icon: 'COPY',
      },
      {
        label: 'Copy Product.dat',
        callback: this.launchProductDatDialog.bind(this),
        icon: 'COPY',
      },
      {
        label: 'View Licenses',
        callback: this.navigateToLicenses.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'View Releases',
        callback: this.navigateToReleases.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'View Product Versions',
        callback: this.navigateToProductVersions.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'View Trial Activations',
        callback: this.navigateToTrialActivations.bind(this),
        icon: 'NAVIGATE_TO',
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };

  override get createAllowed() {
    return (
      this.writeAllowed &&
      this.permissionsService.allowedAction('license-template', 'read')
    );
  }
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'displayName', displayType: 'text' },
    { property: 'description', displayType: 'truncateText' },
    {
      property: 'totalLicenses',
      displayType: 'number',
    },
    {
      property: 'totalTrialActivations',
      displayType: 'number',
    },
    {
      property: 'totalReleases',
      displayType: 'number',
    },
    {
      property: 'totalProductVersions',
      displayType: 'number',
    },
    {
      property: 'totalFeatureFlags',
      displayType: 'number',
    },
    {
      property: 'licensePolicy.name',
      displayType: 'text',
    },
    {
      property: 'trialPolicy.name',
      displayType: 'text',
    },
    {
      property: 'automatedEmails',
      displayType: 'arrayData',
      modalComponent: ViewArrayDataComponent,
    },
    { property: 'metadata', displayType: 'metadata' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override _columnIdsToDisplay: string[] = [
    'name',
    'totalLicenses',
    'totalTrialActivations',
    'totalReleases',
    'totalProductVersions',
    'licensePolicy.name',
    'trialPolicy.name',
    'createdAt',
  ];

  override deletionMessage =
    'Deleting this Product will also delete the associated Licenses, Trials, Product Versions, and Releases.';

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }

  override async launchUpdationDialog(resource: any): Promise<void> {
    const _dialog = this.dialog.open<any, ResourceUpdateDynamicFormData>(
      this.updationComponent,
      {
        data: {
          mode: CtxFormMode.Update,
          resource,
          update: this.update.bind(this),
          deleteMetadata: this.deleteMetadata.bind(this),
        },
      }
    );

    await this.handleDialogClose(_dialog);
  }

  async launchProductDatDialog(product: any) {
    const _dialog = this.dialog.open<any, ResourcePatchFormData>(
      ProductDatDialogComponent,
      {
        data: {
          mode: CtxFormMode.Patch,
          patch: this.downloadProductDatFile.bind(this),
          resource: product,
        },
      }
    );

    await this.handleDialogClose(_dialog);
  }

  /*Download Product.DAT */
  async downloadProductDatFile(id: any): Promise<HttpResponse<any>> {
    const headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    return await this.apiService.getRawData(
      this.resourceApiPath.concat(`/${id}/product.dat`),
      undefined,
      headers
    );
  }

  /** Copy Product ID */
  async copyProductId(product: any) {
    await copyToClipboard(product.id);
  }

  /** Copy public key */
  async copyPublicKey(product: any) {
    await copyToClipboard(product.publicKey);
  }

  /** Generic function to navigate to a path with productId filter */
  private async navigateTo(product: any, path: string) {
    await navigateWithFilters(
      [
        {
          property: 'productId',
          operator: 'eq',
          value: product.id,
        },
      ],
      this.router,
      path
    );
  }

  /** Navigate to licenses with filter for product */
  async navigateToLicenses(product: any) {
    await this.navigateTo(product, CTX_ADMIN_ROUTES.getUrl('licenses'));
  }

  /** Navigate to releases with filter for product */
  async navigateToReleases(product: any) {
    await this.navigateTo(product, CTX_ADMIN_ROUTES.getUrl('releases'));
  }

  /** Navigate to product versions with filter for product */
  async navigateToProductVersions(product: any) {
    await this.navigateTo(product, CTX_ADMIN_ROUTES.getUrl('product-versions'));
  }

  /** Navigate to trial activations with filter for product */
  async navigateToTrialActivations(product: any) {
    await this.navigateTo(
      product,
      CTX_ADMIN_ROUTES.getUrl('trial-activations')
    );
  }
}
