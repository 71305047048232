import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { firstValueFrom, timeout } from 'rxjs';
import { ApiQueryParameters, environment } from 'utils';
import { PersistentStore } from './persistent-store.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const DEFAULT_TIMEOUT = 3 * 60 * 1000;

/**
 * This service is a simple wrapper around the HttpClient service. It internally handles which Web API URL (local, dev, or prod) to hit. All methods only require a path to the resource.
 *
 *
 * Since we wish to have UI changes that react to asynchronous activites in the application,
 * all the public methods defined in this class return a `Promise`. This is done by using the
 * rxjs operator `firstValueFrom(Observable)`.
 *
 * Unlike our previous iteration of the ApiService, we do not have a generic `errorHandler()` function
 * as it limits how verbosely and accurately we can display errors. The previous handler had two extremes:
 * 1. Show no error if `ignoreError = true`
 * 2. Show a global message based error.
 *
 * We have done away with the errorHandler and now allow all errors to be handled in the function call itself.
 * This allows errors to be as specific as possible and allows us to delegate the logic to components.
 * Generic error components like Toasts/Snackbars are then used sparsely which is ideal for error statuses.
 *
 * Our current usage of firstValueFrom() ensures that the `Subcription` to the client
 * is unsubscribed. Even though we can count on Angular to internally unsubscribe from any
 * HttpClient service subscriptions to prevent memory leaks, this does not prevent the cases discussed
 * in the following articles:
 *
 * https://stackoverflow.com/a/45986060
 * https://medium.com/angular-in-depth/why-you-have-to-unsubscribe-from-observable-92502d5639d0
 */
@Injectable({
  providedIn: 'root',
})
export class CryptlexApiService {
  /**
   * The baseUrl of the API to use. Depends on the current environment.
   */
  get baseUrl() {
    return environment.get('apiBaseUrl');
  }

  constructor(private http: HttpClient, private jwt: JwtHelperService) {}

  // endpints for which no accessToken is required.

  private readonly publicPaths = [
    'accounts', // for signup
    'accounts/login',
    'accounts/login-google',
    'accounts/login-sso',
    'verify', // for account verification and saml verify
    'accounts/config',
    'accounts/account-aliases',
    'accounts/signup',
    'accounts/reset-password-request',
    'reset-password',
  ] as const;

  private isPathPublic(path: string): boolean {
    return this.publicPaths.some((publicPath) => {
      return path.endsWith(publicPath);
    });
  }
  /**
   * Refreshes the accessToken if  valid refreshToken exists.
   */
  public async refreshTokens(): Promise<void> {
    const refreshToken = PersistentStore.getRefreshToken();
    if (!refreshToken) {
      return this.handleAuthError();
    }
    try {
      const url = `${this.baseUrl}/v3/accounts/login-refresh-token`;
      const response = await firstValueFrom(
        this.http
          .post<any>(url, { refreshToken }, { observe: 'response' })
          .pipe(timeout(DEFAULT_TIMEOUT))
      );

      const { accessToken, refreshToken: newRefreshToken } = response.body;
      PersistentStore.setAccessToken(accessToken);
      PersistentStore.setRefreshToken(newRefreshToken);
    } catch (error) {
      return this.handleAuthError();
    }
  }
  /**
   * Handles error if
   * - accessToken doesnot exist
   * - accessTokem exists, is expired but refreshToken doesnt exist
   *  - refershToken request not successfull
   */
  private handleAuthError() {
    PersistentStore.removeAccessToken();
    PersistentStore.removeRefreshToken();
    window.location.replace('auth/login');
  }
  /**
   * Verfies if the accessToken is valid, if not then refreshes it with refreshToken
   * - Redirects to login if there is any of the following case:
   *   - accessToken does not exist
   *   - refreshToken expired
   * @param fn the request function
   * @param path the endpoint
   * @returns the request response or throws error
   */
  public async tokenValidator(path: string) {
    const accessToken = PersistentStore.getAccessToken();
    if (this.isPathPublic(path)) {
      return;
    }
    if (!accessToken) {
      return this.handleAuthError();
    }
    try {
      if (this.jwt.isTokenExpired() && PersistentStore.getRefreshToken()) {
        await this.refreshTokens();
      }
    } catch (error: any) {
      return this.handleAuthError();
    }
  }

  /**
   * POST request to the Cryptlex Web API
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param body Data to send to the server in the body envelope
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public async post<T>(path: string, body: any): Promise<HttpResponse<any>> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http
      .post<T>(url, body, { observe: 'response' })
      .pipe(timeout(DEFAULT_TIMEOUT));

    return firstValueFrom($request);
  }

  /**
   * GET request to the Cryptlex Web API
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param headers Optional parameter that specifies HttpHeaders
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public async get(
    path: string,
    params?: ApiQueryParameters,
    headers?: HttpHeaders
  ): Promise<HttpResponse<any>> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http.get(url, {
      observe: 'response',
      params,
      headers,
    });
    return firstValueFrom<any>($request);
  }

  /**
   *  GET request that returns raw data/text from the Cryptlex Web API.
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param headers Optional headers object for the request
   * @returns Promise that resolves to give the Cryptlex Server's response in plaintext
   */
  public async getRawData(
    path: string,
    params?: ApiQueryParameters,
    headers?: HttpHeaders
  ): Promise<any> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http.get(url, {
      observe: 'body',
      params,
      headers,
      responseType: 'text',
    });

    return firstValueFrom<any>($request);
  }

  /**
   * GET request for a paginated resource on the Cryptlex Web API.
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param page Pagination Index
   * @param limit Number of items on the page
   * @param query Request query parameters
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public getList(
    path: string,
    page = 1,
    limit = 10,
    query?: ApiQueryParameters
  ): Promise<HttpResponse<any>> {
    const params = {};
    Object.assign(
      params,
      { page: page.toString() },
      { limit: limit.toString() },
      query
    );

    return this.get(path, params);
  }

  /**
   * PATCH request to the Cryptlex Web API
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param item
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public async patch<T>(path: string, item: any): Promise<HttpResponse<any>> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http.patch<T>(url, item, { observe: 'response' });

    return firstValueFrom($request);
  }

  /**
   * PUT request to the Cryptlex Web API.
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @param item
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public async put<T>(path: string, item: any): Promise<HttpResponse<any>> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http.put<T>(url, item, { observe: 'response' });
    return firstValueFrom($request);
  }

  /**
   * DELETE request to the Cryptlex Web API
   *
   * @param path Path to resource on which task is to be executed. This path is concatenated on the Cryptlex Web API base URL.
   * @returns Promise that resolves to give the Cryptlex Server's response
   */
  public async delete<T>(path: string): Promise<HttpResponse<any>> {
    await this.tokenValidator(path);
    const url = this.baseUrl + path;
    const $request = this.http.delete<T>(`${url}`, { observe: 'response' });

    return firstValueFrom($request);
  }
}
