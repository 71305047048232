import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import {
  AppUpdateService,
  CTX_DEFAULT_MODULE_IMPORTS,
  CTX_DEFAULT_PROVIDERS,
} from 'ng-ui';
import { HighlightModule } from 'ngx-highlightjs';
import { routes } from './app.routes';
import { highlightJsDefaults } from './providers.config';
import { Angulartics2Module } from 'angulartics2';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ...CTX_DEFAULT_MODULE_IMPORTS,
      HighlightModule,
      Angulartics2Module.forRoot({
        pageTracking: {
          clearIds: true,
        },
      })
    ),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    ...CTX_DEFAULT_PROVIDERS,
    highlightJsDefaults,
    AppUpdateService,
  ],
};
