import { afterNextRender, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CtxButtonComponent } from 'ng-ui';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'ctx-redirect-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    CtxButtonComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './redirect-dialog.component.html',
  styles: ``,
})
export class RedirectDialogComponent {
  constructor(public dialogRef: MatDialogRef<RedirectDialogComponent>) {
    afterNextRender(() => {
      setTimeout(() => {
        window.location.replace('https://app.cryptlex.com');
      }, 5000);
    });
  }
}
