import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { TagFormComponent } from './tag-form/tag-form.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class TagService extends ResourceTableService {
  override resourceApiPath = '/v3/tags';
  override resourceName: ResourceName = 'tag';

  override selections = new SelectionModel<any>(true, []);
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'tag' },
    { property: 'description', displayType: 'truncateText' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };
  _columnIdsToDisplay: string[] = ['name', 'description', 'updatedAt'];
  override filterableProperties: FilterableProperties = {};
  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override tableEmptyMessage =
    'Tags allow you to manage your licenses and users on the dashboard. Start by creating a tag using the Create button.';
  override creationComponent = TagFormComponent;
  override updationComponent = TagFormComponent;
  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
