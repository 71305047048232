export type CtxEnvironmentName =
  | 'production'
  | 'on-premise'
  | 'development'
  | 'local';

/** Application Names */
export type CtxProjectName =
  | 'admin-portal'
  | 'customer-portal'
  | 'internal-portal'
  | 'reseller-portal';

/**
 * Base schema for environment variables.
 */
type _BaseEnvironmentVariables = {
  /** Build type */
  buildType: 'production' | 'development';
  /** Name of configuration */
  name: CtxEnvironmentName;
  /** Base URL for Web API */
  apiBaseUrl: string;
  /** Allowed Domains in the JWT */
  allowedDomains: string[];

  /** We do not set this key because an invalid API key leads to a 401 at runtime */
  // postHogApiKey: string;
};

/** Type for Admin Portal ENV  */
export type EnvironmentVariables = _BaseEnvironmentVariables;

type _BaseProjectVariables = {
  /** Google Client ID */
  googleClientId: string;
};

/** Type for Project ENV  */
export type ProjectEnvironmentVariables = _BaseProjectVariables & {
  projectName: CtxProjectName;
  /** Stripe API Key */
  stripeApiKey: string;
  /** Recaptcha site key */
  recaptchaSiteKey: string;
  /** Paddle vendorId*/
  paddleVendorId?: number;
  /** API key for PostHog */
  posthogApiKey: string;
  /** API Host for PostHog */
  posthogApiHost: string;
  /** Release Server Base URL */
  releaseServerBaseUrl: string;
  /** Paddle Billing Token */
  paddleToken?: string;
  googleAnalyticsKey?: string;
};

export type LambdaVariables = _BaseProjectVariables & {
  /** URL for favicon */
  faviconUrl: string;
  /** URL for favicon */
  logoUrl: string;
  /** Website URL */
  website: string;
  /** Account.Company */
  company: string;
};
/**
 * Represents the project configuration.
 */
export class Env<T extends object> {
  config: T;
  keys: (keyof T)[];
  constructor(conf: T, keys?: (keyof T)[]) {
    this.config = conf;
    if (keys) {
      this.keys = keys;
    }
  }

  get<K extends keyof T>(key: K): T[K] {
    return this.config[key];
  }

  /**
   * While we could have allowed mutating {config} directly, this method allows controlled access to the object.
   * This can be useful for logging and other tests
   * @param key
   * @param value
   */
  set<K extends keyof T>(key: K, value: T[K]) {
    this.config[key] = value;
  }

  has(key: string) {
    return key in this.config;
  }
}

export const projectKeys: (keyof ProjectEnvironmentVariables)[] = [
  'projectName',
  'stripeApiKey',
  'googleClientId',
  'releaseServerBaseUrl',
  'recaptchaSiteKey',
  'posthogApiHost',
  'posthogApiKey',
  'paddleVendorId',
  'paddleToken',
];
