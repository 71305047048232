import { Component, Inject, OnInit } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ResourceDeleteFormData } from 'utils';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CtxDeleteForm } from '../models/deletion-form.model';
import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxButtonComponent } from '../../_components/button/button.component';

@Component({
  selector: 'ctx-one-step-deletion',
  standalone: true,
  imports: [
    CtxAlertComponent,
    MatDialogModule,
    ReactiveFormsModule,
    CtxButtonComponent,
  ],
  templateUrl: './one-step-deletion.component.html',
})
export class OneStepDeletionComponent extends CtxDeleteForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceDeleteFormData,
    public dialogRef: MatDialogRef<OneStepDeletionComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});
  }
}
