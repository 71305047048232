/* eslint-disable no-console */
import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { GlobalNotificationService } from './global-notification.service';
@Injectable()
export class AppUpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private globalMessageService: GlobalNotificationService,
    private zone: NgZone
  ) {}

  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      // TODO remove console logging
      console.log('Checking for updates...');
      this.swUpdate.checkForUpdate().then((updateAvailable: any) => {
        if (updateAvailable) {
          console.log('Update available.');
          this.zone.run(() => {
            this.globalMessageService
              .notify(
                'A new version of the portal is available! Refresh to update.',
                120000,
                'Refresh'
              )
              .onAction()
              .subscribe(() => {
                console.log('Applying update...');
                this.swUpdate.activateUpdate().then(() => {
                  setTimeout(() => {
                    document.location.reload();
                  }, 1000);
                });
              });
          });
        } else {
          console.log('No update available.');
        }
      });
    } else {
      console.log('Service worker is disabled.');
    }
  }
}
