import { formatDate, formatNumber } from '@angular/common';
import { UNLIMITED_TEXT } from './unlimited-value';

const SECONDS_PER_DAY = 86400;
type Seconds = number;
type Days = number;

/**
 * @returns Value in days
 */
export function secondsToDays(seconds: Seconds): Days {
  if (seconds === -1) {
    return -1;
  }
  return seconds / SECONDS_PER_DAY;
}

/**
 * @returns Value in seconds
 */
export function daysToSeconds(days: Days): Seconds {
  if (days === -1) {
    return -1;
  }
  return days * SECONDS_PER_DAY;
}

type FormatSecondsOptions = {
  /** Show hours if true */
  hours: boolean;
  /** Show minutes if true */
  minutes: boolean;
  /** Show seconds if true */
  seconds: boolean;
};

/**
 * Returns a string depicting time in the '0d 0h 0m 0s' format.
 * @param seconds Seconds integer
 * @param showMinutes IF true, minutes and seconds values are shown.
 */
export function formatSeconds(
  seconds: Seconds,
  options: FormatSecondsOptions = {
    hours: true,
    minutes: true,
    seconds: true,
  }
): string {
  const _days = Math.floor(seconds / (3600 * 24));
  const _hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const _minutes = Math.floor((seconds % 3600) / 60);
  const _remainingSeconds = seconds % 60;

  let formattedTime = '';
  if (_days !== 0) {
    formattedTime += `${formatNumber(_days, 'en-US')}d`;
  }
  if (_hours !== 0 && options.hours) {
    formattedTime += ` ${formatNumber(_hours, 'en-US')}h`;
  }
  if (_minutes !== 0 && options.minutes) {
    formattedTime += ` ${formatNumber(_minutes, 'en-US')}m`;
  }
  if (_remainingSeconds !== 0 && options.seconds) {
    formattedTime += ` ${formatNumber(_remainingSeconds, 'en-US')}s`;
  }

  return formattedTime.trim();
}
/**
 * Returns a string depicting time in the '0d 0h 0m 0s' format.
 * @param seconds Seconds integer
 * @param property  property string.
 */
export function formatSecondsProperty(
  seconds: Seconds,
  property: string
): string {
  switch (property) {
    case 'serverSyncGracePeriod':
      if (seconds === 0 || seconds === -1) {
        return UNLIMITED_TEXT;
      } else {
        return formatSeconds(seconds);
      }
    case 'allowedClockOffset':
      if (seconds === -1) {
        return UNLIMITED_TEXT;
      } else {
        return formatSeconds(seconds);
      }
    case 'leaseDuration':
      if (seconds === -1 || seconds === 0) {
        return UNLIMITED_TEXT;
      } else {
        return formatSeconds(seconds);
      }
    default:
      return formatSeconds(seconds);
  }
}
/**
 * @returns Difference between two dates in seconds
 */
export function dateDifference(
  endDate: Date,
  startDate: Date = new Date()
): Seconds {
  return Math.floor((endDate.valueOf() - startDate.valueOf()) / 1000);
}

/**
 * @returns Date object from `days` ago
 */
export function getDateNDaysAgo(days: number): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - days);
}

/**
 * @returns Date object from `years` ago
 */
export function getDateNYearsAgo(years: number): Date {
  const now = new Date();
  return new Date(now.getFullYear() - years, now.getMonth(), now.getDate());
}
/**
 *
 * @param date date
 * @returns the next day date
 */
export function nextDayDate(date: string, daysToAdd = 1) {
  const tomorrow = new Date(date);
  tomorrow.setTime(tomorrow.getTime() + daysToAdd * SECONDS_PER_DAY * 1000);
  return tomorrow;
}
/** The Unix timestamp */
export type UnixTimestamp = number;

/**
 *
 * @param date
 * @returns midnight date in iso format
 */
export function getIsoMidnightDate(date: Date | string) {
  /**  Date selected in mat-calender is local, when converted to ISO as such, it is incorrect as 
  it accounts for time zone difference. Hence we take the date without time before converting it
  to ISO form.
  */
  return new Date(formatDate(date, 'yyyy-MM-dd', 'en-US')).toISOString();
}

/** Return a promise that resolves after a set time period*/
export function waitForSomeTime(ms = 1000) {
  return new Promise<void>((resolve) => {
    return setTimeout(() => {
      return resolve();
    }, ms);
  });
}
