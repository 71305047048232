<span
  class="ctx-stepper__header__button__text flex flex-row px-2 py-1 rounded-xl items-center justify-center"
>
  <hlm-icon
    class="mr-2"
    [ngClass]="isNavigable && hasError ? 'text-warning' : ''"
    [name]="isNavigable && hasError ? 'WARNING' : icon"
    inline
  ></hlm-icon>
  <span>
    <ng-content></ng-content>
  </span>
</span>
