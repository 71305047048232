<h2 mat-dialog-title>{{ dialogData.mode }} Maintenance Policy</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formValue, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="name"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
      </mat-form-field>

      <!-- Validity -->
      @if (dialogData.mode === 'Create') { @if(validityMethods === 'lifetime'){
      <label>Validity</label>
      }
      <mat-radio-group
        (change)="handleValidityMethodChange($event)"
        [selected]="lifetime"
      >
        <mat-radio-button value="lifetime" #lifetime>Lifetime</mat-radio-button>
        <mat-radio-button value="days">Valid For</mat-radio-button>
        <mat-radio-button value="date">Valid Through</mat-radio-button>
      </mat-radio-group>

      @if(validityMethods === 'lifetime'){
      <input hidden [value]="0" formControlName="validity" />
      } @else {
      <mat-form-field>
        <mat-label>Validity</mat-label>
        <ctx-custom-validity-input
          [mindate]="tommorow"
          [method]="validityMethods"
          formControlName="validity"
        ></ctx-custom-validity-input>
        <mat-hint>
          Maintenance Policy valid
          {{
            formGroup.get('validity')?.value !== '0'
              ? computeValidityControlHint(
                  'create',
                  validityMethods,
                  formGroup.get('validity')?.value
                )
              : 'for lifetime'
          }}
        </mat-hint>
        <mat-error>{{ getControlError(formGroup, 'validity') }}</mat-error>
      </mat-form-field>
      } }

      <!-- Disabled for Update Form -->
      @if (dialogData.mode === 'Update') {
      <mat-form-field>
        <mat-label>Validity</mat-label>
        <input
          autocomplete="off"
          type="text"
          formControlName="validity"
          matInput
          [disabled]="true"
        />
        <mat-hint>Validity cannot be updated.</mat-hint>
      </mat-form-field>
      }

      <!-- Expiration Strategy -->
      <mat-form-field>
        <mat-label>Expiration Strategy</mat-label>
        <mat-select formControlName="expirationStrategy" required>
          <mat-option value="immediate">Immediate</mat-option>
          <mat-option value="delayed">Delayed</mat-option>
        </mat-select>
        @if (formGroup.get('expirationStrategy')?.value === 'immediate') {
        <mat-hint
          >The license maintenance starts expiring right after the license is
          created.</mat-hint
        >
        } @if (formGroup.get('expirationStrategy')?.value === 'delayed') {
        <mat-hint
          >The license maintenance starts expiring after the license is
          activated the first time.</mat-hint
        >
        }
        <mat-error>{{
          getControlError(formGroup, 'expirationStrategy')
        }}</mat-error>
      </mat-form-field>

      <!-- Allow Major Version Updates -->
      <mat-checkbox
        (change)="handleAllowMajorVersionChange($event)"
        formControlName="allowMajorVersionUpdates"
        >Allow Major Version Updates</mat-checkbox
      >
      <!-- Allow Minot Version Updates -->
      <mat-checkbox formControlName="allowMinorVersionUpdates"
        >Allow Minor Version Updates</mat-checkbox
      >
    </section>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
