<h2 mat-dialog-title>{{ dialogData.mode }} Product Version</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef, tab)"
>
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Details" icon="DETAILS" [stepControl]="detailsTab">
        <section class="flex flex-col gap-4">
          <!-- Product -->
          <mat-form-field>
            <mat-label>Product</mat-label>
            <mat-select
              (selectionChange)="setFeatureFlags($event.value)"
              formControlName="productId"
              required
            >
              @for (product of products; track product) {
              <mat-option class="ph-no-capture" [value]="product.id">
                {{ product.name }}
              </mat-option>
              }
            </mat-select>
            @if (dialogData.mode === 'Create') {
            <mat-hint
              >Select the product that this feature flag will be added to. @if
              (formStates['features'] === true) {
              <span>(loading feature flags)</span>
              }</mat-hint
            >
            }
            <mat-error>{{ getControlError(formGroup, 'productId') }}</mat-error>
          </mat-form-field>

          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
          </mat-form-field>

          <!-- Display Name -->
          <mat-form-field>
            <mat-label>Display Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="displayName"
              matInput
              required
            />
            <mat-error>{{
              getControlError(formGroup, 'displayName')
            }}</mat-error>
            <mat-hint
              >This name is displayed to the customers in the Customer
              Portal.</mat-hint
            >
          </mat-form-field>

          <!-- Description -->
          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea
              rows="1"
              matInput
              formControlName="description"
              required
            ></textarea>
            <mat-error>{{
              getControlError(formGroup, 'description')
            }}</mat-error>
          </mat-form-field>
        </section>
      </ctx-tab>
      <ctx-tab label="Flags" icon="FEATURE_FLAGS" [stepControl]="featuresTab">
        <p>
          <!-- Product not selected -->
          @if (!featureFlags && dialogData.mode === 'Create') { Please select a
          product to view the corresponsing feature flags. }

          <!-- Empty array feature flags -->
          @if ( featureFlags && featureFlags.length === 0 && dialogData.mode ===
          'Create' ) { There are no feature flags defined for the selected
          Product. Please go to the Feature Flags page to define the required
          flags. }
        </p>

        <!-- Loading flags -->
        @if (formStates.features) {
        <section class="flex flex-row items-center">
          <p>Loading feature flags.</p>
          <mat-spinner diameter="12"></mat-spinner>
        </section>
        } @else {
        <section class="flex flex-col gap-4">
          @for ( featureFlag of featuresTab.controls; track
          trackByFeatureFlagId(index, featureFlag); let index = $index) {
          <ng-container formArrayName="featureFlags">
            <section
              class="border border-solid rounded px-6 pt-4 pb-6"
              [formGroupName]="index"
            >
              <div
                class="flex flex-row items-center w-full justify-between mb-4"
              >
                <!-- Name -->
                <p [ngClass]="flagToggle.checked ? '' : 'opacity-60'">
                  <strong>{{ featureFlag.getRawValue().name }}</strong>
                  <br />
                  {{ featureFlag.value.description }}
                </p>
                <!-- Enabled -->
                <mat-slide-toggle
                  #flagToggle
                  color="primary"
                  formControlName="enabled"
                ></mat-slide-toggle>
              </div>
              <!-- Optional Data -->
              <mat-form-field class="w-full">
                <mat-label>Data</mat-label>
                <textarea rows="1" formControlName="data" matInput></textarea>
                <mat-error>{{
                  getControlError(featureFlag, 'data')
                }}</mat-error>
              </mat-form-field>
            </section>
          </ng-container>
          }
        </section>
        }
        <!-- Loaded flags -->
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode }}
    </button>
  </mat-dialog-actions>
</form>
