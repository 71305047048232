export const CUSTOM_CONFIG_KEYS = [
  'company',
  'website',
  'logoUrl',
  'faviconUrl',
  'googleClientId',
  'googleAnalyticsKey',
  'webPortalTheme',
] as const;
export type CustomConfigKeys = (typeof CUSTOM_CONFIG_KEYS)[number];

export const API_CONFIG_KEYS = ['apiBaseUrl', 'apiBaseUrlEu'];

export type apiConfigKeys = (typeof API_CONFIG_KEYS)[number];
