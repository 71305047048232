<section class="h-screen">
  <ctx-app-bar-base
    [sidenav]="sidenav"
    [trialExpiredComponent]="trialExpiredComponent"
  >
    <ctx-app-bar-actions></ctx-app-bar-actions>
  </ctx-app-bar-base>
  <mat-sidenav-container class="h-[calc(100vh_-_56px)]" autosize>
    <mat-sidenav
      #sidenav
      position="start"
      [mode]="breakpoints.isBreakpointSmall ? 'over' : 'side'"
      [opened]="opened"
    >
      <!-- NavigationLinks with accordions -->
      <div class="w-60" hlmAccordion type="multiple">
        @for (baseRoute of routes.routes; track baseRoute.label; let index =
        $index;) { @if ( !(baseRoute.hidden && baseRoute.hidden(authn))) { @if
        (baseRoute.children) {
        <div hlmAccordionItem [isOpened]="isCurrentRoute(baseRoute)">
          <button
            class="px-4 opacity-80 hover:no-underline"
            hlmAccordionTrigger
          >
            <span class="flex flex-row items-center gap-2">
              <hlm-icon [name]="baseRoute.icon" aria-hidden="true"></hlm-icon>
              <span>{{ baseRoute.label }}</span>
            </span>
            <hlm-icon hlmAccIcon />
          </button>
          <brn-accordion-content hlm
            ><ctx-secondary-nav-rail-links
              [navLinks]="baseRoute.children"
              [allRoutes]="routes.routes"
            ></ctx-secondary-nav-rail-links
          ></brn-accordion-content>
        </div>
        } @else {
        <ctx-secondary-nav-rail-links
          [navLinks]="[baseRoute]"
          [allRoutes]="routes.routes"
        ></ctx-secondary-nav-rail-links>
        } } }
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <main class="box-border">
        <article class="mx-4 pb-2" id="main-content" tabindex="-1">
          @if(routerEvents.isPageLoading){
          <section
            class="flex flex-col items-center justify-center w-full h-full py-4"
          >
            <mat-spinner></mat-spinner>
          </section>
          } @else{
          <!-- Page -->
          <section class="px-1">
            <!-- Primary outlet -->
            <ng-content></ng-content>
          </section>
          }
        </article>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>
