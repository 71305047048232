export const SEARCH_STRINGS = {
  name: 'Find by name',
  hostname: 'Find by hostname',
  event: 'Find by event',
  email: 'Find by email',
  key: 'Find by license key',
  nameValue: 'Find by name or value',
  hostnameId: 'Find by hostname or ID',
  nameEmail: 'Find by name or email',
  nameVersion: 'Find by name or version',
};
