<h2 mat-dialog-title>
  Update {{ convertCamelCaseToTitleCase(dialogData.resource.portal) }} Portal
  URL
</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <p>
        WARNING: Changing the Portal URL will prevent your users from logging in
        using the currently active URL. It will effect both the customer and
        reseller portals.
      </p>

      <!-- New Account Alias -->
      <mat-form-field>
        <span matTextPrefix>https://</span>
        <span matTextSuffix
          >.{{ dialogData.resource.portal
          }}{{
            environment.get('name') === 'development' ? '.dev' : ''
          }}.cryptlex.com</span
        >
        <mat-label>URL</mat-label>
        <input type="text" formControlName="accountAlias" matInput required />
        <mat-error>{{ getControlError(formGroup, 'accountAlias') }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Update
    </button>
  </mat-dialog-actions>
</form>
