<ctx-layout>
  <section class="w-full">
    <h1>
      {{ errorStatus }}
      <hlm-icon
        name="ERROR"
        inline
        class="opacity-80 align-text-bottom"
      ></hlm-icon>
    </h1>
    <p>
      An error occurred with the following message:
      <strong>{{ errorMessage }}</strong>
    </p>
    <p>Please use the links on the left to continue using the application.</p>
  </section>
</ctx-layout>
