<h2 mat-dialog-title>Disable OIDC SSO</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">
  {{ apiMessage.text }}
</ctx-alert>
}

<mat-dialog-content>
  <p>
    Disabling OIDC SSO will remove the OIDC SSO configuration from the account.
    This will disable your customers from logging in using OIDC SSO.
  </p>
</mat-dialog-content>

<!-- Buttons -->
<mat-dialog-actions>
  <button matDialogClose ctx-button--secondary [disabled]="formStates.disabled">
    Close
  </button>
  <button
    ctx-button--primary
    [loading]="formStates.submit"
    [disabled]="formStates.disabled"
    (click)="delete(dialogData, dialogRef)"
  >
    Disable
  </button>
</mat-dialog-actions>
