<h2 mat-dialog-title>Change Password</h2>
@if (showApiMessage) {
<ctx-alert [alertLevel]="apiMessage.alertLevel">{{
  apiMessage.text
}}</ctx-alert>
}
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef)"
>
  <mat-dialog-content>
    <section class="flex flex-col gap-4">
      <!-- Current Password -->
      <mat-form-field>
        <mat-label>Current Password</mat-label>
        <input
          type="password"
          formControlName="oldPassword"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'oldPassword') }}</mat-error>
      </mat-form-field>

      <!-- New Password -->
      <mat-form-field>
        <mat-label>New Password</mat-label>
        <input
          type="password"
          formControlName="newPassword"
          matInput
          required
        />
        <mat-error>{{ getControlError(formGroup, 'newPassword') }}</mat-error>
      </mat-form-field>

      <!-- Confirm Password -->
      <mat-form-field>
        <mat-label>Confirm New Password</mat-label>
        <input
          type="password"
          formControlName="confirmPassword"
          matInput
          required
        />
        <mat-error>{{
          getControlError(formGroup, 'confirmPassword')
        }}</mat-error>
      </mat-form-field>
    </section>
  </mat-dialog-content>
  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      Change
    </button>
  </mat-dialog-actions>
</form>
