import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SegmentService } from 'ng-ui';
import { Segment, deserializeSegment } from 'utils';

export const resolveSegments: ResolveFn<any> = () => {
  const _segment = inject(SegmentService);
  return _segment
    .list(1, 100, {})
    .then((response: { body: any }) => {
      const segments = response.body ? response.body : [];
      if (segments) {
        segments.forEach((segment: Segment) => {
          try {
            deserializeSegment(segment);
          } catch {
            // we proceed as such
          }
        });
      }
      _segment.segmentsFromApi = segments;
      return response.body;
    })
    .catch(async (error: any) => {
      // eslint-disable-next-line no-console
      console.error(`Error while fetching ${_segment.resourceName}`, error);
    });
};
