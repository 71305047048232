<section class="flex justify-between items-center gap-4">
  <span>
    {{ data.message }}
  </span>
  <button
    ctx-button--primary
    matSnackBarAction
    color="accent"
    (click)="snackbarRef.dismissWithAction()"
  >
    {{ data.action }}
  </button>
</section>
