import { MatDialogRef } from '@angular/material/dialog';
import { ResourceDeleteFormData } from 'utils';
import { CtxForm } from './form.model';

export class CtxDeleteForm extends CtxForm {
  async delete(
    dialogData: ResourceDeleteFormData,
    dialogRef: MatDialogRef<unknown>
  ) {
    // Flag that will be used to determine if error is caught
    let hasError = false;

    this._startFormSubmission(dialogRef);

    for (const resource of dialogData.resources) {
      try {
        await dialogData.delete(resource.id);
      } catch (errorResponse) {
        // IF error is caught, THEN loop should break and show error message
        hasError = true;
        this._handleFormError(errorResponse, dialogRef);
        break;
      }
    }

    // IF no error is caught, THEN show success message and close the dialog
    if (!hasError) {
      this._afterSubmissionSuccess({}, dialogRef);
      this.setCustomApiMessage({
        text: `Deletion of ${dialogData.resources.length} item${
          dialogData.resources.length > 1 ? 's' : ''
        } completed.`,
        alertLevel: 'success',
      });
    }
    this._endFormSubmission();
  }
}
