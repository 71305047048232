import { CdkStepHeader } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

import { NgClass } from '@angular/common';
import { HlmIconComponent } from '../../icon';
import { CtxIcons } from 'utils';

/**
 * This component is only used by the `StepperComponent`
 * It is the button used to navigate to different tabs/steps.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ctx-step-header]',
  templateUrl: './step-header-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HlmIconComponent, NgClass],
})
export class CtxStepHeaderButtonComponent extends CdkStepHeader {
  /** True if the step has an error */
  @Input() hasError: boolean;
  /** True if the step can be accessed. */
  @Input() isNavigable: boolean;
  /** Identifier string for hlm-icon */
  @Input() icon: CtxIcons;

  constructor(_elementRef: ElementRef) {
    super(_elementRef);
  }
}
