import { MatDialogRef } from '@angular/material/dialog';
import {
  CtxFormMode,
  ResourcePatchFormData,
  ResourceUpdateFormData,
} from 'utils';
import { CtxForm } from './form.model';

export class CtxBatchForm extends CtxForm {
  batchSize = 20;
  override async submit(
    formValue: any,
    dialogData: ResourceUpdateFormData | ResourcePatchFormData,
    dialogRef: MatDialogRef<any, any>
  ) {
    if (this.formGroup && this.formGroup.invalid) {
      return;
    }
    this._startFormSubmission(dialogRef);
    try {
      for (
        let counter = 0;
        counter < dialogData.resource.length;
        counter += this.batchSize
      ) {
        const batchPromise = dialogData.resource.slice(
          counter,
          counter + this.batchSize
        );
        await Promise.all(
          batchPromise.map((resource: any) => {
            if (dialogData.mode === CtxFormMode.Update) {
              return dialogData.update(resource.id, formValue);
            } else {
              return dialogData.patch(resource.id);
            }
          })
        );
      }

      await this._afterSubmissionSuccess({ ok: true }, dialogRef);
    } catch (errorResponse: any) {
      this._handleFormError(errorResponse, dialogRef);
    }
    this._endFormSubmission();
  }
}
