/* eslint-disable no-console */
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import {
  ResourceCreateFormData,
  ResourcePatchFormData,
  ResourceUpdateFormData,
  waitForSomeTime,
} from 'utils';
import { getErrorMessageFromResponse } from 'utils';
import { ApiMessage, CtxFormMode, FormErrorCodes, FormErrorData } from 'utils';
import { AbstractControl } from '@angular/forms';
import { CtxStepperComponent } from 'libs/ng-ui/src/lib/_components/stepper/stepper/stepper.component';
import { HttpResponse } from '@angular/common/http';

const customErrorMessages: {
  [key: string]: { [errorCode: FormErrorCodes]: string };
} = {
  deleteConfirmationText: {
    pattern: "Enter the phrase 'I am absolutely sure' to continue.",
  },
  url: {
    pattern: 'Enter a valid URL.',
  },
  twoFactorCode: {
    incorrect: 'The two-factor code entered is incorrect.',
  },
};

export abstract class CtxForm {
  /**
   * Object that defines the several `FormGroup` instances that can exist in a single component.
   * Generally, only one `FormGroup` instance exists in a component, however, many cases like stepper,
   * and tabbed forms require us to make this generalization in all forms.
   *
   * It should be noted that this object should NOT be used to nest two unrelated forms into one component.
   * Please create a separate component for unrelated forms.
   */
  formGroup: FormGroup;

  /**
   * Object that helps manage the multiple loading states in a form. This can be the value of a dynamically
   * generated form field, a button, or even a page of information. It is implemented as an object to support a wide
   * range of use cases.
   */
  formStates = {
    /** By default, all forms have a submit loading state.  */
    submit: false,
    disabled: false,
    features: false,
    gsi: false,
    stripe: false,
    paddle: false,
    sso: false,
  };
  /**
   * A general error message for the form. This is usually the error message returned by the Web API.
   *
   */
  apiMessage: ApiMessage;

  get showApiMessage() {
    return this.apiMessage && this.apiMessage.text;
  }

  /**
   * Sets a boolean value against the key `name` in `formStates`
   *
   * @param name Key name for value in `laodingStates`
   * @param value Value to set against the key `name`
   */
  setState(name: keyof typeof this.formStates, value: boolean) {
    this.formStates[name] = value;
  }

  /**
   * Returns the initial value for the field name if it exists. This is useful in forms where the
   * creation and updation form are the same. The name can be defined in dot notation to access a certain part of an object.
   *
   * @param property Property to access in response object for the value to initialize with
   * @param dialogData The dialogData types narrow down whether the form is new i.e `mode === 'Create'` or requires predefined values i.e. mode === 'Update'
   */
  getInitialControlValue(
    property: string,
    dialogData: ResourceCreateFormData | ResourceUpdateFormData,
    def: any = null
  ) {
    // Since resource will only be present in mode === update, check for mode.
    // Also check the presence of the key in the resource object.
    if (
      dialogData.mode === CtxFormMode.Update &&
      property.split('.')[0] in dialogData.resource
    ) {
      return property.split('.').reduce((prev, curr) => {
        return prev && prev[curr];
      }, dialogData.resource);
    } else {
      return def;
    }
  }
  /**
   * Returns the initial value for the field as a string with comma-separated values.
   * @param property Property to access in response object for the value to initialize with
   * @param dialogData The dialogData types narrow down whether the form is new i.e `mode === 'Create'` or requires predefined values i.e. mode === 'Update'
   */

  getInitialArrayControlValue(
    property: string,
    dialogData: ResourceCreateFormData | ResourceUpdateFormData
  ) {
    if (
      dialogData.mode === CtxFormMode.Update &&
      property.split('.')[0] in dialogData.resource &&
      dialogData.resource[property.split('.')[0]] !== null
    ) {
      return dialogData.resource[property.split('.')[0]].join(',');
    } else {
      return null;
    }
  }

  /**
   * Return the appropriate function based on the form action of the current form.
   *
   * @param formValue The data to be submitted to the Web API
   * @param dialogData Object used to narrow down whether creation or updation is to be performed.
   */
  getSubmissionHandler(
    formValue: any,
    dialogData:
      | ResourceCreateFormData
      | ResourceUpdateFormData
      | ResourcePatchFormData
  ): Promise<HttpResponse<any>> {
    if (dialogData.mode === CtxFormMode.Create) {
      return dialogData.create(formValue);
    } else if (dialogData.mode === CtxFormMode.Update) {
      return dialogData.update(dialogData.resource.id, formValue);
    } else {
      return dialogData.patch(dialogData.resource.id);
    }
  }

  /** Generic finalize handler for form.submit */
  protected _endFormSubmission() {
    this.setState('submit', false);
    this.setState('disabled', false);
  }

  disabledFormFieldKeys: string[] = [];
  /** Generic initialize handler for form.submit */
  protected _startFormSubmission(dialogRef?: MatDialogRef<any>) {
    this.resetApiMessage();
    this.setState('submit', true);
    this.setState('disabled', true);

    /** Store keys of FormFields that are disabled originally */
    for (const key in this.formGroup?.controls) {
      if (this.formGroup.controls[key].status === 'DISABLED') {
        this.disabledFormFieldKeys.push(key);
      }
    }
    this.formGroup?.disable();

    if (dialogRef) {
      dialogRef.disableClose = true;
    }
  }

  /** Generic error handler for form.submit */
  protected _handleFormError(
    errorResponse: any,
    dialogRef?: MatDialogRef<any>
  ) {
    this.formGroup?.enable();

    /** Disable Form Fields that were originally disabled to prevent form from being in invalid state*/
    this.disabledFormFieldKeys.forEach((key: string) => {
      if (this.formGroup.controls?.[key]) {
        this.formGroup.controls[key].disable();
      }
    });
    this.setApiMessage(errorResponse);
    this.setState('submit', false);
    this.setState('disabled', false);
    if (dialogRef) {
      dialogRef.disableClose = false;
    }
  }

  /** Generic success handler for form.submit */
  protected async _afterSubmissionSuccess(
    response: any,
    dialogRef?: MatDialogRef<any, any>
  ) {
    if (dialogRef) {
      dialogRef.disableClose = false;
    }

    this.setState('submit', false);
    this.setApiMessage(response);

    if (dialogRef) {
      await this.closeDialogAfterTimeout(dialogRef, true);
    }
  }

  /**
   * Generic submit handler
   *
   * It is essential to pass in the dialogRef param if the form is being used in a dialog.
   */
  async submit(
    formValue: any,
    dialogData:
      | ResourceCreateFormData
      | ResourceUpdateFormData
      | ResourcePatchFormData,
    dialogRef?: MatDialogRef<any>,
    ctxTab?: CtxStepperComponent
  ): Promise<void> {
    if (this.formGroup.invalid) {
      ctxTab ? ctxTab.handleInvalidForm() : null;
      return;
    } else {
      try {
        this._startFormSubmission(dialogRef);
        const response = await this.getSubmissionHandler(formValue, dialogData);
        await this._afterSubmissionSuccess(response, dialogRef);
      } catch (errorResponse: any) {
        this._handleFormError(errorResponse, dialogRef);
      }
      this._endFormSubmission();
    }
  }

  /**
   * Checks for errors on the `FormControl` specified by the `formControlName`. Returns
   * the first error in order of Validations for the `FormControl`, or null if there
   * is no error or if the control does not exist.
   *
   * @param controlName Name of control for which error is to be retrieved.
   * @param abstractControl AbstractControl instance to keep the function pure
   */
  getControlError(
    abstractControl: AbstractControl,
    controlName: string
  ): string | null {
    const control = abstractControl.get(controlName);
    if (control) {
      return CtxForm.getAbstractControlError(control, controlName);
    } else {
      return null;
    }
  }

  /** A method to get error message from an AbstractControl */
  static getAbstractControlError(
    abstractControl: AbstractControl,
    controlName: string
  ) {
    if (abstractControl && abstractControl.errors) {
      const firstError = Object.entries(abstractControl.errors)[0];
      return CtxForm.getControlErrorMessage(controlName, firstError);
    } else {
      return null;
    }
  }

  /**
   * Returns all errors in `FormControl` error object. This can be useful when Validators.compose()
   * is used.
   *
   * @param controlName Name of control for which errors are to be retrieved.
   */
  getAllControlErrors(controlName: string): string | null {
    try {
      const control = this.formGroup.get(controlName);
      if (control && control.errors) {
        const errorEntries = Object.entries(control.errors);
        const errorMessages = [];
        for (const error of errorEntries) {
          errorMessages.push(
            CtxForm.getControlErrorMessage(controlName, error)
          );
        }
        return errorMessages.join(' ');
      } else {
        return null;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  /**
   * Returns the appropriate error message for a `FormControl`.
   *
   * @param controlName Name of control for which error is to be retrieved.
   * @param controlError Array defined by `ErrorData
   */
  private static getControlErrorMessage(
    controlName: string,
    controlError: FormErrorData
  ): string {
    const showCustomMessage = this.controlHasCustomErrorMessage(
      controlName,
      controlError
    );
    if (showCustomMessage) {
      return this.getCustomControlErrorMessage(controlName, controlError);
    } else {
      return this.getGenericControlErrorMessage(controlError);
    }
  }

  /**
   * Checks if custom message exists on `FormControl`.
   *
   * @param controlName Name of control for which error is to be retrieved.
   * @param controlError Array defined by `ErrorData`
   */
  private static controlHasCustomErrorMessage(
    controlName: string,
    controlError: FormErrorData
  ): boolean {
    return Boolean(
      customErrorMessages[controlName] &&
        customErrorMessages[controlName][controlError[0]]
    );
  }

  /**
   * Returns the custom error message for the error thrown on the `FormControl`
   *
   * @param controlName Name of control for which error is to be retrieved.
   * @param controlError Array defined by `ErrorData`
   */
  private static getCustomControlErrorMessage(
    controlName: string,
    controlError: FormErrorData
  ): string {
    return customErrorMessages[controlName][controlError[0]];
  }

  /**
   * Returns an appropriate error message for the error passed.
   *
   * @param controlError Array defined by `ErrorData`
   */
  private static getGenericControlErrorMessage(
    controlError: FormErrorData
  ): string {
    switch (controlError[0]) {
      case 'required':
        return 'Field is required.';
      case 'email':
        return 'Please enter a valid email address.';
      case 'min':
        return `The value specified must be equal to or greater than ${Number(
          controlError[1]['min']
        )}`;
      case 'max':
        return `The value specified must be equal to or  less than ${Number(
          controlError[1]['max']
        )}`;
      case 'minlength':
        return `Field must be atleast ${controlError[1]['requiredLength']} characters long.`;
      case 'maxlength':
        return `Field must be a maximum of ${controlError[1]['requiredLength']} characters long.`;
      case 'pattern':
        return `Field must follow the ${controlError[1]['requiredPattern']} pattern.`;
      case 'duplicateKeys':
        return `${controlError[1]} already exists. Enter a unique value.`;
      default:
        return controlError[1];
    }
  }

  /**
   * A high-level funciton which determines whether the response is a success, error, other, or undefined and based on these parameters, sets the `apiMessage` property of the form.
   *
   * @param messageObject Response object retrieved after calling the Cryptlex Web API.
   */
  setApiMessage(messageObject: any) {
    // In some cases apiResponse might be undefined
    if (messageObject) {
      if ('ok' in messageObject && messageObject.ok === false) {
        this.apiMessage = this.getApiErrorMessage(messageObject);
      } else if ('ok' in messageObject && messageObject.ok === true) {
        this.apiMessage = this.getApiSuccessMessage();
      } else {
        // eslint-disable-next-line no-console
        console.error(`getApiMessage(): ${messageObject}`);
      }
    }
  }

  /**
   * Set hardcoded API message.
   */
  setCustomApiMessage(apiMessage: ApiMessage) {
    this.apiMessage = apiMessage;
  }

  /**
   * Private function to retrieve error message from ApiResponse
   * @param apiResponse response object retrieved from calling the Cryptlex Web API
   * @returns an `ApiMessage` object
   */
  private getApiErrorMessage(apiResponse: any): ApiMessage {
    return {
      text: getErrorMessageFromResponse(apiResponse),
      alertLevel: 'warning',
    };
  }

  /**
   * Private function to retrieve success message from ApiResponse
   * @param apiResponse response object retrieved from calling the Cryptlex Web API
   * @returns an `ApiMessage` object
   */
  private getApiSuccessMessage(): ApiMessage {
    return { text: 'Task Successful', alertLevel: 'success' };
  }

  resetApiMessage() {
    if (this.showApiMessage) {
      this.apiMessage.text = '';
    }
  }

  /**
   * Closes MatDialog after a defined timeout with the returned boolean defined by `returnValue` parameter.
   *
   * @param dialogRef MatDialogRef object. Allows calling the `close()` function
   * @param returnValue The boolean value that gets returned when closing the dialog
   * @param ms Optional duration of timeout (in milliseconds) before the dialog is closed.
   */
  closeDialogAfterTimeout(
    dialogRef: MatDialogRef<any>,
    returnValue: boolean
  ): Promise<void> {
    return waitForSomeTime().then(() => {
      dialogRef.close(returnValue);
    });
  }

  /**
   * Converts a comma separated string to an array
   * @param value
   * @returns
   */
  csvToArray(value: unknown) {
    const getArrayFromValue = (): string[] => {
      if (Array.isArray(value)) {
        return value;
      } else if (typeof value === 'string') {
        if (value === '') {
          return [];
        } else {
          return value.split(',').map((item) => {
            return item.trim();
          });
        }
      } else {
        return [];
      }
    };

    const resultArray = getArrayFromValue().filter((item) => {
      return item !== '';
    });
    return resultArray;
  }

  /**
   * Computes the validity control hint based on the validity method, value, and start date.
   * @param validityMethod - The validity method ('days' or any other value).
   * @param value - The value used to compute the validity control hint.
   * @param startDate - The start date for computing the validity control hint. Defaults to the current date.
   * @returns The computed validity control hint.
   */
}
