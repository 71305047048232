import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { RoleFormComponent } from './role-form/role-form.component';
import { SEARCH_STRINGS } from 'utils';
import { ViewAllowedPermissionsComponent } from 'admin-portal/common/view-allowed-permissions/view-allowed-permissions.component';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends ResourceTableService {
  override resourceName: ResourceName = 'role';
  override resourceApiPath = '/v3/roles';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;

  override selections = new SelectionModel<any>(true, []);
  override columns: TableColumn[] = [
    { displayType: 'name', property: 'name' },
    { displayType: 'truncateText', property: 'description' },
    {
      displayType: 'allowedPermission',
      property: 'claims',
      modalComponent: ViewAllowedPermissionsComponent,
    },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: (resource: any) => {
          return this.isRowDisabled(resource) || !this.writeAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: (resource: any) => {
          return this.isRowDisabled(resource) || !this.deleteAllowed;
        },
      },
    ],
  };
  override _columnIdsToDisplay: string[] = ['name', 'description', 'claims'];
  override filterableProperties: FilterableProperties = {};
  override tableEmptyMessage =
    'Roles allow you to set fine-grained access for your team to the dashboard. Start by creating a role using the Create button.';

  override creationComponent = RoleFormComponent;
  override updationComponent = RoleFormComponent;

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
  isRowDisabled(row: any) {
    return (
      row.name === 'admin' ||
      row.name === 'super-admin' ||
      row.name === 'user' ||
      row.name === 'reseller-admin' ||
      row.name === 'organization-admin'
    );
  }
}
