import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ResourceDeleteFormData } from 'utils';
import { CtxDeleteForm } from '../models/deletion-form.model';
import { MatInputModule } from '@angular/material/input';

import { CtxAlertComponent } from '../../_components/alert/alert.component';
import { CtxButtonComponent } from '../../_components/button/button.component';
import { CtxStepperComponent } from '../../_components/stepper/stepper/stepper.component';
import { CtxStepComponent } from '../../_components/stepper/step/step.component';
@Component({
  selector: 'ctx-two-step-deletion',
  templateUrl: './two-step-deletion.component.html',
  standalone: true,
  imports: [
    CtxAlertComponent,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    CtxButtonComponent,
    CtxStepperComponent,
    CtxStepComponent,
  ],
})
export class TwoStepDeletionComponent extends CtxDeleteForm implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ResourceDeleteFormData,
    public dialogRef: MatDialogRef<TwoStepDeletionComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      deleteConfirmationText: [
        '',
        [Validators.required, Validators.pattern('I am absolutely sure')],
      ],
    });
  }
}
