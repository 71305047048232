import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { MaintenancePolicyFormComponent } from './maintenance-policy-form/maintenance-policy-form.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenancePolicyService extends ResourceTableService {
  override resourceApiPath = '/v3/maintenance-policies';
  override resourceName: ResourceName = 'maintenance-policy';
  override tableSearchPlaceholder = SEARCH_STRINGS.name;
  override selections = new SelectionModel<any>(true, []);
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'validity', displayType: 'maintenanceValidity' },
    { property: 'expirationStrategy', displayType: 'flag' },
    { property: 'allowMajorVersionUpdates', displayType: 'flag' },
    { property: 'allowMinorVersionUpdates', displayType: 'flag' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];
  override actions: TableActions = {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: false,
    tag: false,
    search: true,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.writeAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };
  override _columnIdsToDisplay: string[] = [
    'name',
    'validity',
    'expirationStrategy',
    'allowMajorVersionUpdates',
    'allowMinorVersionUpdates',
  ];
  override filterableProperties: FilterableProperties = {};
  override tableEmptyMessage =
    'A maintenance policy is used to configure support and updates for a license. Use the Create button to start creating one.';
  override creationComponent = MaintenancePolicyFormComponent;
  override updationComponent = MaintenancePolicyFormComponent;
  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
