<button
  ctx-button--secondary
  font="mono"
  [matMenuTriggerFor]="menu"
  class="mx-2"
>
  {{ buttonLabel }}
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="themePicker">
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="OS_DEFAULT_THEME" inline></hlm-icon>
      <span>Theme</span>
    </span>
  </button>
  <!-- Support -->
  @if(isIntercomVisible){
  <button mat-menu-item (click)="draftIntercomBugReportMessage()">
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="BUG_REPORT" inline></hlm-icon>
      <span>Bug Report</span>
    </span>
  </button>
  <button mat-menu-item (click)="draftIntercomBugReportMessage()">
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="FEEDBACK" inline></hlm-icon>
      <span>Feedback</span>
    </span>
  </button>
  }
  <button mat-menu-item (click)="authnService.logout()">
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="LOGOUT" inline class="align-bottom"></hlm-icon>
      <span>Logout</span>
    </span>
  </button>
</mat-menu>
<!-- Theme Picker -->
<mat-menu #themePicker="matMenu">
  <button
    mat-menu-item
    [disabled]="themeManager.isAutoMode"
    (click)="themeManager.enableAutoThemeMode()"
  >
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="OS_DEFAULT_THEME" inline></hlm-icon>
      <span>Auto</span>
    </span>
  </button>
  <button
    mat-menu-item
    [disabled]="!themeManager.isDarkMode && !themeManager.isAutoMode"
    (click)="themeManager.setTheme('cryptlex-light')"
  >
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="LIGHT_MODE" inline class="base fill-none"></hlm-icon>
      <span>Light</span>
    </span>
  </button>
  <button
    mat-menu-item
    [disabled]="!themeManager.isAutoMode && themeManager.isDarkMode"
    (click)="themeManager.setTheme('cryptlex-dark')"
  >
    <span class="flex flex-row items-center gap-2">
      <hlm-icon name="DARK_MODE" inline class="base"></hlm-icon>
      <span>Dark</span>
    </span>
  </button>
</mat-menu>
