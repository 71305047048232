import { MatTooltip } from '@angular/material/tooltip';

/**
 * Copies the first value parameter to the clipboard.
 * @param tooltip If tooltip is present. It will enable it, and show it for approx 2 seconds.
 * This is done so a tooltip containing confirmation for the action can be shown.
 */
export async function copyToClipboard(value: string, tooltip?: MatTooltip) {
  await navigator.clipboard.writeText(value);

  if (tooltip) {
    tooltip.disabled = false;
    tooltip.show();
    setTimeout(() => {
      tooltip.disabled = true;
    }, 2000);
  }
}
